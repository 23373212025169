import { useState, useEffect, useRef } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Paper, TablePagination, Typography, IconButton, LinearProgress, Box } from '@mui/material';
import { Search as DetailIcon, SearchOffOutlined as SearchOffIcon } from '@mui/icons-material';
import OrderDetailDialog from './orderDetailDialog';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
interface TableDataProps {
	rownum?: boolean;
	checkbox?: boolean;
	flattened?: boolean;
	showOrderDetail?: boolean;
	showTotal?: boolean;
	pagination?: boolean;
	setCheckedValues?: (val: any[]) => void;
	TableData: any[];
	TableColumn: TableColumnDef[];
	loading?: boolean;
	height?: number;
	refetch?: () => void;
	rowClick?: boolean;
	editDetailInfo?: boolean;
}

export interface TableColumnDef {
	name: string;
	datafield: string;
	customValue?: (params: any) => string | JSX.Element;
	align?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
	headerAlign?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
	width?: number;
	style?: (params: any) => object;
	hideMobile?: boolean;
}

const CustomOrderTable: React.FC<TableDataProps> = ({
	TableData,
	TableColumn,
	rownum = false,
	flattened = false,
	checkbox = false,
	showOrderDetail = false,
	showTotal = false,
	pagination = false,
	loading = false,
	height,
	setCheckedValues,
	refetch,
	rowClick = false,
	editDetailInfo = false,
}) => {
	let newTableData: any[] = [];
	//const [ newTableData, setNewTableData ] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [selected, setSelected] = useState<readonly string[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [orderId, setOrderId] = useState(0);
	const scrollRef = useRef<HTMLElement>();
	const { t } = useTranslation();

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		if (scrollRef.current) scrollRef.current.scrollTop = 0;
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = newTableData.map((n, idx) => (n.id ? n.id : idx));
			setSelected(newSelected);
			if (setCheckedValues) setCheckedValues(newSelected);
			return;
		}
		setSelected([]);
		if (setCheckedValues) setCheckedValues([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}
		setSelected(newSelected);
		if (setCheckedValues) setCheckedValues(newSelected as any[]);
	};

	TableData.forEach((data: any, idx: number) => {
		let tempData: any = { editMode: false };
		Object.keys(data).forEach((key) => {
			tempData[key] = data[key];
			if (Array.isArray(data[key])) {
				const arr = data[key];
				tempData = { ...tempData, ...arr[0] };
			}
		});
		newTableData.push({ ...tempData });

		if (flattened) {
			Object.keys(data).forEach((key) => {
				// console.log(key, data[key]);
				if (Array.isArray(data[key])) {
					const arr = data[key];
					//console.log(arr);
					for (let i = 1; i < arr.length; i++) {
						newTableData.push({ ...arr[i] });
					}
				}
			});
		}
	});

	const handleClickDetail = (event: React.MouseEvent<unknown>, data: any) => {
		console.log(data);
		setOrderId(data.id);
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTimeout(() => {
			setOrderId(0);
		}, 500);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	useEffect(() => {
		setPage(0);
		setSelected([]);
	}, [TableData]);

	return (
		<>
			{showTotal && (
				<Typography variant='subtitle2'>
					Total: {TableData.length}
					{checkbox && selected.length > 0 && `, ${selected.length} ${t('selected')}`}
				</Typography>
			)}
			<Paper sx={{ width: '100%' }}>
				<TableContainer sx={{ height }}>
					<PerfectScrollbar containerRef={(el) => (scrollRef.current = el)}>
						<Table stickyHeader size='small'>
							<TableHead>
								<TableRow>
									{rownum && <TableCell align='center'>No.</TableCell>}
									{checkbox && (
										<TableCell padding='checkbox'>
											<Checkbox
												color='primary'
												indeterminate={selected.length > 0 && selected.length < newTableData.length}
												checked={newTableData.length > 0 && selected.length === newTableData.length}
												onChange={handleSelectAllClick}
												inputProps={{
													'aria-label': 'select all',
												}}
											/>
										</TableCell>
									)}
									{TableColumn.map((column, idx) => {
										let hideMobile = {};
										if (column.hideMobile) hideMobile = { display: { xs: 'none', sm: 'table-cell' } };
										return (
											<TableCell key={idx} align={column.headerAlign} sx={{ maxWidth: column.width, ...hideMobile }}>
												{column.name}
											</TableCell>
										);
									})}
									{showOrderDetail && <TableCell>{t('Detail')}</TableCell>}
								</TableRow>
							</TableHead>
							<TableBody>
								{loading && (
									<TableRow>
										<TableCell colSpan={100} sx={{ padding: 0 }}>
											<LinearProgress />
										</TableCell>
									</TableRow>
								)}
								{!loading && newTableData.length === 0 && (
									<TableRow sx={{ height: '300px' }}>
										<TableCell colSpan={100}>
											<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
												<SearchOffIcon sx={{ width: 120, height: 120, opacity: '0.6' }} />
												<Box sx={{ mt: 1 }}>{t('No Data')}</Box>
											</Box>
										</TableCell>
									</TableRow>
								)}
								{!pagination &&
									newTableData.map((data, idx) => {
										const isItemSelected = isSelected(data.id ? data.id : idx);
										const labelId = `enhanced-table-checkbox-${idx}`;

										return (
											<TableRow
												key={idx}
												hover
												role='checkbox'
												aria-checked={isItemSelected}
												tabIndex={-1}
												selected={isItemSelected}
												onClick={rowClick ? (event) => handleClick(event, data.id ? data.id : idx) : undefined}>
												{rownum && <TableCell align='center'>{idx + 1}</TableCell>}
												{checkbox && (
													<TableCell padding='checkbox'>
														<Checkbox
															color='primary'
															checked={isItemSelected}
															onClick={(event) => handleClick(event, data.id ? data.id : idx)}
															inputProps={{
																'aria-labelledby': labelId,
															}}
														/>
													</TableCell>
												)}
												{TableColumn.map((column, idx2) => {
													let widthStyle = {};
													let hideMobile = {};
													if (column.hideMobile) hideMobile = { display: { xs: 'none', sm: 'table-cell' } };
													//if(column.width) widthStyle = {maxWidth: column.width, overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"};
													if (column.customValue)
														if (column.style)
															return (
																<TableCell key={idx2} width={column.width} sx={{ ...column.style(data), ...widthStyle, ...hideMobile }} align={column.align}>
																	{column.customValue(data)}
																</TableCell>
															);
														else
															return (
																<TableCell key={idx2} width={column.width} sx={{ ...widthStyle, ...hideMobile }} align={column.align}>
																	{column.customValue(data)}
																</TableCell>
															);
													else if (column.style)
														return (
															<TableCell key={idx2} width={column.width} sx={{ ...column.style(data), ...widthStyle, ...hideMobile }} align={column.align}>
																{data[column.datafield]}
															</TableCell>
														);
													else
														return (
															<TableCell key={idx2} width={column.width} sx={{ ...widthStyle, ...hideMobile }} align={column.align}>
																{data[column.datafield]}
															</TableCell>
														);
												})}
												{showOrderDetail && (
													<TableCell key={idx}>
														<IconButton aria-label='detail' onClick={(event) => handleClickDetail(event, data)}>
															<DetailIcon />
														</IconButton>
													</TableCell>
												)}
											</TableRow>
										);
									})}
								{pagination &&
									newTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, idx) => {
										const isItemSelected = isSelected(data.id ? data.id : idx);
										const labelId = `enhanced-table-checkbox-${idx}`;

										return (
											<TableRow
												key={idx}
												hover
												role='checkbox'
												aria-checked={isItemSelected}
												tabIndex={-1}
												selected={isItemSelected}
												onClick={rowClick ? (event) => handleClick(event, data.id ? data.id : idx) : undefined}>
												{rownum && <TableCell align='center'>{data.id ? data.id : idx}</TableCell>}
												{checkbox && (
													<TableCell padding='checkbox'>
														<Checkbox
															color='primary'
															checked={isItemSelected}
															onClick={(event) => handleClick(event, data.id ? data.id : idx)}
															inputProps={{
																'aria-labelledby': labelId,
															}}
														/>
													</TableCell>
												)}
												{TableColumn.map((column, idx2) => {
													let widthStyle = {};
													let hideMobile = {};
													if (column.hideMobile) hideMobile = { display: { xs: 'none', sm: 'table-cell' } };
													//if(column.width) widthStyle = {maxWidth: column.width, overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"};
													if (column.customValue)
														if (column.style)
															return (
																<TableCell key={idx2} width={column.width} sx={{ ...column.style(data), ...widthStyle, ...hideMobile }} align={column.align}>
																	{column.customValue(data)}
																</TableCell>
															);
														else
															return (
																<TableCell key={idx2} width={column.width} sx={{ ...widthStyle, ...hideMobile }} align={column.align}>
																	{column.customValue(data)}
																</TableCell>
															);
													else if (column.style)
														return (
															<TableCell key={idx2} width={column.width} sx={{ ...column.style(data), ...widthStyle, ...hideMobile }} align={column.align}>
																{data[column.datafield]}
															</TableCell>
														);
													else
														return (
															<TableCell key={idx2} width={column.width} sx={{ ...widthStyle, ...hideMobile }} align={column.align}>
																{data[column.datafield]}
															</TableCell>
														);
												})}
												{showOrderDetail && (
													<TableCell key={idx}>
														<IconButton aria-label='detail' onClick={(event) => handleClickDetail(event, data)}>
															<DetailIcon />
														</IconButton>
													</TableCell>
												)}
											</TableRow>
										);
									})}
								{/* {emptyRows > 0 && (
						Array.from({length:emptyRows},()=>'').map(data => {
							return (
								<TableRow>
									<TableCell colSpan={6} />
								</TableRow>
							);
						})
					)} */}
							</TableBody>
						</Table>
					</PerfectScrollbar>
				</TableContainer>
				{pagination && (
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component='div'
						count={newTableData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						showFirstButton
						showLastButton
					/>
				)}
			</Paper>
			{showOrderDetail && <OrderDetailDialog open={openDialog} close={handleCloseDialog} orderId={orderId} refetch={refetch} editDetailInfo={editDetailInfo} />}
		</>
	);
};

export default CustomOrderTable;
