import { useState } from 'react';
import { Box, IconButton, Card, CardContent, TextField, InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { SearchProductsInput } from '../graphql_types/globalTypes';
import { useTranslation } from 'react-i18next';
interface SearchBarProp {
	onSearch: (data: SearchProductsInput) => (e: React.FormEvent<HTMLFormElement>) => void;
}

export const SimpleSearchBar: React.FC<SearchBarProp> = ({ onSearch, ...rest }) => {
	const [searchData, setSearchData] = useState<SearchProductsInput>({});
	const { t } = useTranslation();

	return (
		<Box {...rest}>
			<Box>
				<Card>
					<CardContent component='form' sx={{ padding: '24px' }} onSubmit={onSearch({ ...searchData })}>
						<TextField
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton sx={{ p: '10px' }} aria-label='search' type='submit'>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
							value={searchData?.name}
							onChange={(e: any) => {
								const {
									target: { value: newValue },
								} = e;
								setSearchData({ ...searchData, name: newValue });
							}}
							placeholder={t('Search')}
							variant='outlined'
						/>
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
};
