/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum OrderCategory {
	B2B = 'B2B',
	OpenMarket = 'OpenMarket',
}

export enum OrderState {
	Canceled = 'Canceled',
	OrderConfirm = 'OrderConfirm',
	Sent = 'Sent',
	ShipReady = 'ShipReady',
	Shipped = 'Shipped',
}

export enum PccType {
	PCC = 'PCC',
	RRN = 'RRN',
}

export enum UserRole {
	Admin = 'Admin',
	Manager = 'Manager',
	Staff = 'Staff',
	StockManager = 'StockManager',
}

export interface CheckAndSavePCCInput {
	id: number;
	errId: number;
	name: string;
	pcc: string;
	phoneNo: string;
}

export interface CourierInputType {
	name: string;
	seqForOrder?: string | null;
	isActive: boolean;
}

export interface CreateAccountInput {
	firstName?: string | null;
	lastName?: string | null;
	role?: UserRole | null;
	email?: string | null;
	password?: string | null;
}

export interface CustomerInputType {
	name: string;
	representative?: string | null;
	delimiter: string;
	email: string;
	zipcode?: string | null;
	address?: string | null;
	detailAddress?: string | null;
	contact?: string | null;
	isActive: boolean;
}

export interface EditProfileInput {
	id?: number | null;
	firstName?: string | null;
	lastName?: string | null;
	role?: UserRole | null;
	email?: string | null;
	password?: string | null;
	isActive?: boolean | null;
}

export interface FindPCCErrorInput {
	name?: string | null;
}

export interface LoginInput {
	email: string;
	password: string;
}

export interface OrderInputType {
	orderNo?: string | null;
	sortOrder: number;
	category?: OrderCategory | null;
	from?: string | null;
	website?: string | null;
	referenceNo?: string | null;
	orderedAt?: any | null;
	paidAt?: any | null;
	recieverPhone?: string | null;
	buyerPhone?: string | null;
	buyerName: string;
	recieverName: string;
	postal: string;
	addr: string;
	addrDetail?: string | null;
	PCC: string;
	pccType?: PccType | null;
	pccName?: string | null;
	pccPhone?: string | null;
	pccResult?: boolean | null;
	orderProducts: OrderProductInputType[];
	msg?: string | null;
	comment?: string | null;
	orderState?: OrderState | null;
	customer?: CustomerInputType | null;
	courier?: CourierInputType | null;
	shippingNo?: string | null;
	shippingFee?: number | null;
	orderAmount?: number | null;
	netIncome?: number | null;
	currency?: string | null;
	sentDate?: any | null;
	delimiter?: string | null;
}

export interface ExpiredInputType {
	productName: string;
	brand: string;
	referenceCode: string;
	barcode: string;
	quantity: number;
	expiryDate: Date;
	comment?: string | null;
}

export interface OrderProductInputType {
	productName: string;
	alias?: string | null;
	brand?: string | null;
	option?: string | null;
	qty?: number | null;
	productQty?: number | null;
	barcode?: string | null;
	unitPrice?: number | null;
	reportableUnitPrice?: number | null;
	productWeight?: number | null;
}

export interface PccInfoType {
	id: number;
	name: string;
	pccType?: PccType | null;
	pcc: string;
	phoneNo: string;
}

export interface SaveCourierInput {
	id?: number | null;
	name?: string | null;
	seqForOrder?: string | null;
	isActive?: boolean | null;
}

export interface SaveCustomerInput {
	id?: number | null;
	name?: string | null;
	representative?: string | null;
	delimiter?: string | null;
	email?: string | null;
	zipcode?: string | null;
	address?: string | null;
	detailAddress?: string | null;
	contact?: string | null;
	isActive?: boolean | null;
}

export interface SaveProductInput {
	id?: number | null;
	productName?: string | null;
	alias?: string | null;
	brand?: string | null;
	barcode?: string | null;
	unitPrice?: number | null;
	reportableUnitPrice?: number | null;
	cost?: number | null;
	productWeight?: number | null;
	productQty?: number | null;
}

export interface SaveShippingNumberInputType {
	id: number;
	shippingNo: string;
}

export interface SearchCouriersInput {
	name?: string | null;
}

export interface SearchCustomersInput {
	name?: string | null;
}

export interface SearchOrderDetailInput {
	orderId: number;
}

export interface SearchOrdersInput {
	category?: OrderCategory | null;
	orderState?: OrderState | null;
	courier?: number | null;
	customer?: number | null;
	orderFrom?: any | null;
	orderTo?: any | null;
	sentFrom?: any | null;
	sentTo?: any | null;
	name?: string | null;
	isPccError?: boolean | null;
}

export interface SearchProductsInput {
	name?: string | null;
}

export interface SearchShippingOrdersInput {
	shippingNo?: string | null;
}

export interface SearchUsersInput {
	name?: string | null;
}

export interface UpdateOrderInputType {
	id?: number | null;
	orderNo?: string | null;
	sortOrder?: number | null;
	category?: OrderCategory | null;
	from?: string | null;
	website?: string | null;
	referenceNo?: string | null;
	orderedAt?: any | null;
	paidAt?: any | null;
	recieverPhone?: string | null;
	buyerPhone?: string | null;
	buyerName?: string | null;
	recieverName?: string | null;
	postal?: string | null;
	addr?: string | null;
	addrDetail?: string | null;
	PCC?: string | null;
	pccType?: PccType | null;
	pccName?: string | null;
	pccPhone?: string | null;
	pccResult?: boolean | null;
	orderProducts?: OrderProductInputType[] | null;
	msg?: string | null;
	comment?: string | null;
	orderState?: OrderState | null;
	customer?: CustomerInputType | null;
	shippingNo?: string | null;
	shippingFee?: number | null;
	orderAmount?: number | null;
	netIncome?: number | null;
	currency?: string | null;
	sentDate?: any | null;
	courierId?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
