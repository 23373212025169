import { Paper, Grid } from '@mui/material';
import { CourierUsageCard } from '../../components/courierUsageCard';
import { MonthlySalesB2BCard } from '../../components/monthlySalesB2BCard';
import { MonthlySalesOpenMarketCard } from '../../components/monthlySalesOpenMarketCard';
import { OrderCountByDateCard } from '../../components/orderCountByDateCard';
import { BestSalesProductCard } from '../../components/bestSalesProductCard';

export const Dashboard = () => {
	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<CourierUsageCard />
				</Grid>
				<Grid item xs={12} md={8}>
					<OrderCountByDateCard />
				</Grid>
				<Grid item xs={12} md={6}>
					<BestSalesProductCard />
				</Grid>
				<Grid item xs={12} md={6}>
					<MonthlySalesOpenMarketCard />
				</Grid>
			</Grid>
		</Paper>
	);
};
