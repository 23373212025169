import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { SAVECUSTOMER_MUTATION } from '../graphql/mutations';
import { useTranslation } from 'react-i18next';

interface AddCustomerDialogProps {
	open: boolean;
	close: () => void;
	refetch: () => void;
	editData?: CustomerData;
}

export interface CustomerData {
	id?: number;
	name: string;
	representative: string;
	email: string;
	contact: string;
	delimiter: string;
	zipcode: string;
	address: string;
	detailAddress: string;
	isActive: boolean;
}

export const CustomerDialog: React.FC<AddCustomerDialogProps> = ({ open = false, close, refetch, editData }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [customerData, setCustomerData] = useState<CustomerData>({
		name: '',
		representative: '',
		email: '',
		contact: '',
		delimiter: '',
		zipcode: '',
		address: '',
		detailAddress: '',
		isActive: true,
	});
	const { t } = useTranslation();

	const onCompleted = (data: any) => {
		const {
			saveCustomer: { success, error },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Customer information was saved!`), { variant: 'success', autoHideDuration: 3000 });
			refetch();
			close();
		} else {
			enqueueSnackbar(`${t(`Cannot save Customer...😥`)} : ${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [saveCustomerMutation, { loading }] = useMutation(SAVECUSTOMER_MUTATION, { variables: { saveCustomerInput: { ...customerData } }, onCompleted });

	const onSave = () => {
		//console.log(customerData);
		if (!customerData.name || customerData.name === '') {
			enqueueSnackbar(t(`Customer Name is required`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (!customerData.delimiter || customerData.delimiter === '') {
			enqueueSnackbar(t(`Customer delimiter is required`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (!customerData.email || customerData.email === '') {
			enqueueSnackbar(t(`Customer E-mail is required`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!emailPattern.test(customerData.email)) {
			enqueueSnackbar(t(`Please check email again`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		saveCustomerMutation();
	};

	useEffect(() => {
		if (editData) {
			setCustomerData(editData);
		}
	}, [editData]);

	return (
		<>
			<Dialog open={open} onClose={close} scroll='paper' maxWidth='md' aria-labelledby='customer-detail-dialog-title' aria-describedby='customer-detail-dialog-description'>
				<DialogTitle id='customer-detail-dialog-title'>{t('Customer Information')}</DialogTitle>
				<PerfectScrollbar>
					<DialogContent dividers>
						<PerfectScrollbar>
							<Grid container spacing={2} sx={{ marginTop: 1 }}>
								<Grid item xs={12} md={4}>
									<TextField
										label={t('Name')}
										fullWidth
										value={customerData.name}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, name: newValue });
										}}
										required
									/>
								</Grid>
								<Grid item xs={8} md={4}>
									<TextField
										label={t('Delimiter(3 Charactor)')}
										fullWidth
										inputProps={{ maxLength: 3 }}
										value={customerData.delimiter}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, delimiter: newValue.toUpperCase() });
										}}
										required
									/>
								</Grid>
								<Grid item xs={4} md={4}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox checked={customerData.isActive} />}
											label={t('Active')}
											onChange={(e: any) => {
												setCustomerData({ ...customerData, isActive: !customerData.isActive });
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField
										label={t('Representative')}
										fullWidth
										value={customerData.representative}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, representative: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={6} md={4}>
									<TextField
										label={t('E-mail')}
										fullWidth
										value={customerData.email}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, email: newValue });
										}}
										required
									/>
								</Grid>
								<Grid item xs={6} md={4}>
									<TextField
										label={t('Contact')}
										fullWidth
										value={customerData.contact}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, contact: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={4} md={2}>
									<TextField
										label={t('Zip code')}
										fullWidth
										value={customerData.zipcode}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, zipcode: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={12} md={10}>
									<TextField
										label={t('Address')}
										fullWidth
										value={customerData.address}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, address: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label={t('Detail Address')}
										fullWidth
										value={customerData.detailAddress}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCustomerData({ ...customerData, detailAddress: newValue });
										}}
									/>
								</Grid>
							</Grid>
						</PerfectScrollbar>
					</DialogContent>
				</PerfectScrollbar>
				<DialogActions>
					<LoadingButton loading={loading} onClick={onSave} color='primary'>
						{t('Save')}
					</LoadingButton>
					<Button onClick={close} color='error'>
						{t('Close')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
