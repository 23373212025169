import { Dashboard } from '../pages/Dashboard/dashboard';
import {
	DashboardOutlined as DashboardIcon,
	AssignmentTurnedIn as AssignmentTurnedInIcon,
	Person as UserIcon,
	PersonOutline as CustomerIcon,
	LocalMall as OrderIcon,
	LocalMallOutlined as OrderBulkIcon,
	CategoryOutlined as CategoryIcon,
	ShoppingBagOutlined as ShoppingBagIcon,
	FlightTakeoffOutlined as ShippingModeIcon,
	FileUpload as ExportIcon,
	SupportAgentOutlined as CSIcon,
	ErrorOutlineOutlined as PccErrorIcon,
	LocalShippingOutlined as CourierIcon,
	ReceiptLongOutlined as ReceiptLongIcon,
	ReceiptOutlined as ReceiptIcon,
	RequestQuoteOutlined as RequestQuoteIcon,
} from '@mui/icons-material';
import { OrderPage } from '../pages/Orders/order';
import { ManageOrder } from '../pages/Orders/manage';
import { ManageProducts } from '../pages/Products/manage';
import { ShippingMode } from '../pages/Orders/shipping-mode';
import CreateOrderPage from '../pages/Orders/create-order';
import { ManageCustomers } from '../pages/Customers/manage';
import { PCCErrorPage } from '../pages/CustomerService/pcc-error';
import { PccVerification } from '../pages/CustomerService/pcc-verification';
import { UserRole } from '../graphql_types/globalTypes';
import { ManageUsers } from '../pages/User/manage';
import { CreateAccount } from '../pages/User/create-account';
import { SentOrder } from '../pages/Orders/sent-orders';
import { ManageCouriers } from '../pages/Couriers/manage';
import { CustomerInvoice } from '../pages/Invoices/CustomerInvoice';
import { PeriodInvoice } from '../pages/Invoices/PeriodInvoice';
import { Stock } from '../pages/Stock/stock';
import expiredDate from '../pages/Expired/expiredDate';

export interface MenuRoutes {
	path: string;
	name: string;
	icon?: JSX.Element;
	component?: React.FC;
	views?: MenuRoutes[];
	layout: string;
	hide?: boolean;
}

const DashRoutes: any = {
	[UserRole.Admin]: [
		{
			path: 'dashboard',
			name: 'dashboard',
			icon: <DashboardIcon />,
			component: Dashboard,
			layout: '/main/',
		},
		{
			path: 'users',
			name: 'users',
			icon: <UserIcon />,
			component: ManageUsers,
			layout: '/users/',
		},
		{
			path: 'create-account',
			name: 'Create Account',
			icon: <UserIcon />,
			component: CreateAccount,
			layout: '/users/',
			hide: true,
		},
		{
			path: 'customers',
			name: 'customers',
			icon: <CustomerIcon />,
			component: ManageCustomers,
			layout: '/customers/',
		},
		{
			path: 'products',
			name: 'products',
			icon: <CategoryIcon />,
			component: ManageProducts,
			layout: '/products/',
		},
		{
			path: 'couriers',
			name: 'couriers',
			icon: <CourierIcon />,
			component: ManageCouriers,
			layout: '/couriers/',
		},
		{
			path: 'orders',
			name: 'orders',
			icon: <ShoppingBagIcon />,
			views: [
				{
					path: 'manage',
					name: 'order-manage',
					icon: <ExportIcon />,
					component: ManageOrder,
					layout: '/orders/',
				},
				{
					path: 'create-order',
					name: 'create-order',
					icon: <OrderIcon />,
					component: CreateOrderPage,
					layout: '/orders/',
				},
				{
					path: 'order',
					name: 'bulk-order',
					icon: <OrderBulkIcon />,
					component: OrderPage,
					layout: '/orders/',
				},
				{
					path: 'shipping-mode',
					name: 'shipping-mode',
					icon: <ShippingModeIcon />,
					component: ShippingMode,
					layout: '/orders/',
					hide: true,
				},
				{
					path: 'sent-orders',
					name: 'sent-orders',
					icon: <ShippingModeIcon />,
					component: SentOrder,
					layout: '/orders/',
				},
			],
			layout: '/',
		},
		{
			path: 'invoices',
			name: 'Manage Invoice',
			icon: <ReceiptLongIcon />,
			views: [
				{
					path: 'customer-invoice',
					name: 'Customer Invoice',
					icon: <ReceiptIcon />,
					component: CustomerInvoice,
					layout: '/invoices/',
				},
				{
					path: 'period-invoice',
					name: 'Period Invoice',
					icon: <RequestQuoteIcon />,
					component: PeriodInvoice,
					layout: '/invoices/',
				},
			],
			layout: '/',
		},
		{
			path: 'cs',
			name: 'customer-service',
			icon: <CSIcon />,
			views: [
				{
					path: 'pcc-error',
					name: 'pcc-error',
					icon: <PccErrorIcon />,
					component: PCCErrorPage,
					layout: '/cs/',
				},
				{
					path: 'pcc-verification',
					name: 'pcc-verification',
					icon: <AssignmentTurnedInIcon />,
					component: PccVerification,
					layout: '/cs/',
				},
			],
			layout: '/',
		},
		{
			path: 'stock',
			name: 'Stock',
			icon: <CategoryIcon />,
			views: [
				{
					path: 'stock',
					name: 'Stock',
					icon: <ReceiptIcon />,
					component: Stock,
					layout: '/stock/',
				},
				{
					path: 'expiredDate',
					name: 'Expired Products',
					icon: <ExportIcon />,
					component: expiredDate,
					layout: '/stock/',
				},
			],
			component: Stock,
			layout: '/stock/',
		},
	],
	[UserRole.Manager]: [
		{
			path: 'dashboard',
			name: 'dashboard',
			icon: <DashboardIcon />,
			component: Dashboard,
			layout: '/main/',
		},
		{
			path: 'products',
			name: 'products',
			icon: <CategoryIcon />,
			component: ManageProducts,
			layout: '/products/',
		},
		{
			path: 'orders',
			name: 'orders',
			icon: <ShoppingBagIcon />,
			views: [
				{
					path: 'manage',
					name: 'order-manage',
					icon: <ExportIcon />,
					component: ManageOrder,
					layout: '/orders/',
				},
				{
					path: 'create-order',
					name: 'create-order',
					icon: <OrderIcon />,
					component: CreateOrderPage,
					layout: '/orders/',
				},
				{
					path: 'order',
					name: 'bulk-order',
					icon: <OrderBulkIcon />,
					component: OrderPage,
					layout: '/orders/',
				},
				{
					path: 'shipping-mode',
					name: 'shipping-mode',
					icon: <ShippingModeIcon />,
					component: ShippingMode,
					layout: '/orders/',
					hide: true,
				},
				{
					path: 'sent-orders',
					name: 'sent-orders',
					icon: <ShippingModeIcon />,
					component: SentOrder,
					layout: '/orders/',
				},
			],
			layout: '/',
		},
		{
			path: 'cs',
			name: 'customer-service',
			icon: <CSIcon />,
			views: [
				{
					path: 'pcc-error',
					name: 'pcc-error',
					icon: <PccErrorIcon />,
					component: PCCErrorPage,
					layout: '/cs/',
				},
				{
					path: 'pcc-verification',
					name: 'pcc-verification',
					icon: <AssignmentTurnedInIcon />,
					component: PccVerification,
					layout: '/cs/',
				},
			],
			layout: '/',
		},
		{
			path: 'stock',
			name: 'stock-manage',
			icon: <CategoryIcon />,
			component: Stock,
			layout: '/stock/',
		},
	],
	[UserRole.Staff]: [
		{
			path: 'dashboard',
			name: 'dashboard',
			icon: <DashboardIcon />,
			component: Dashboard,
			layout: '/main/',
		},
		{
			path: 'orders',
			name: 'orders',
			icon: <ShoppingBagIcon />,
			views: [
				{
					path: 'manage',
					name: 'order-manage',
					icon: <ExportIcon />,
					component: ManageOrder,
					layout: '/orders/',
				},
				{
					path: 'create-order',
					name: 'create-order',
					icon: <OrderIcon />,
					component: CreateOrderPage,
					layout: '/orders/',
				},
				{
					path: 'order',
					name: 'bulk-order',
					icon: <OrderBulkIcon />,
					component: OrderPage,
					layout: '/orders/',
				},
				{
					path: 'shipping-mode',
					name: 'shipping-mode',
					icon: <ShippingModeIcon />,
					component: ShippingMode,
					layout: '/orders/',
					hide: true,
				},
				{
					path: 'sent-orders',
					name: 'sent-orders',
					icon: <ShippingModeIcon />,
					component: SentOrder,
					layout: '/orders/',
				},
			],
			layout: '/',
		},
		{
			path: 'cs',
			name: 'customer-service',
			icon: <CSIcon />,
			views: [
				{
					path: 'pcc-error',
					name: 'pcc-error',
					icon: <PccErrorIcon />,
					component: PCCErrorPage,
					layout: '/cs/',
				},
				{
					path: 'pcc-verification',
					name: 'pcc-verification',
					icon: <AssignmentTurnedInIcon />,
					component: PccVerification,
					layout: '/cs/',
				},
			],
			layout: '/',
		},
	],
	[UserRole.StockManager]: [
		{
			path: 'dashboard',
			name: 'dashboard',
			icon: <DashboardIcon />,
			component: Dashboard,
			layout: '/main/',
		},
		{
			path: 'stock',
			name: 'Stock',
			icon: <CategoryIcon />,
			views: [
				{
					path: 'stock',
					name: 'Stock',
					icon: <ReceiptIcon />,
					component: Stock,
					layout: '/stock/',
				},
				{
					path: 'expiredDate',
					name: 'Expired Products',
					icon: <ExportIcon />,
					component: expiredDate,
					layout: '/stock/',
				},
			],
			component: Stock,
			layout: '/stock/',
		},
	],
};

export default DashRoutes;
