import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Container, Box, Button, Checkbox } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { SEARCHUSERS_QUERY } from '../../graphql/queries';
import { DELETEUSERS_MUTATION } from '../../graphql/mutations';
import { SimpleSearchBar } from '../../components/simpleSearchBar';
import CustomTable, { TableColumnDef } from '../../components/customTable';
import { UserData, UserDialog } from '../../components/userDialog';
import { SearchUsersInput, UserRole } from '../../graphql_types/globalTypes';
import { searchUsersQuery, searchUsersQueryVariables } from '../../graphql_types/searchUsersQuery';
import { deleteUsersMutation, deleteUsersMutationVariables } from '../../graphql_types/deleteUsersMutation';
import { useTranslation } from 'react-i18next';

export const ManageUsers = () => {
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [userDetail, setUserDetail] = useState<UserData>({
		email: '',
		firstName: '',
		lastName: '',
		role: UserRole.Staff,
		isActive: true,
	});
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('E-mail'),
			datafield: 'email',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Name'),
			datafield: 'name',
			headerAlign: 'center',
			align: 'center',
			customValue: (row: any) => {
				return `${row.firstName} ${row.lastName}`;
			},
		},
		{
			name: t('Role'),
			datafield: 'role',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('isActive'),
			datafield: 'isActvie',
			customValue: (row: any) => {
				return <Checkbox checked={row.isActive} />;
			},
		},
	];

	const onCompleted = (data: searchUsersQuery) => {
		const {
			searchUsers: { success, users },
		} = data;

		if (success) {
			setTableRowData(users as any);
		} else {
			enqueueSnackbar(t(`Cannot search users...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onDeleteCompleted = (data: deleteUsersMutation) => {
		const {
			deleteUsers: { success },
		} = data;

		if (success) {
			enqueueSnackbar(t(`User Deleted`), { variant: 'error', autoHideDuration: 3000 });
			refetch();
		} else {
			enqueueSnackbar(t(`Cannot delete users...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [searchUsersQuery, { loading, refetch }] = useLazyQuery<searchUsersQuery, searchUsersQueryVariables>(SEARCHUSERS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [deleteUsersMutation] = useMutation<deleteUsersMutation, deleteUsersMutationVariables>(DELETEUSERS_MUTATION, { onCompleted: onDeleteCompleted });

	const _onSearch = (searchData: SearchUsersInput) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: searchUsersQueryVariables = {
			searchUsersInput: { ...searchData },
		};
		searchUsersQuery({ variables });
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		//console.log(checkedData);
		setCheckedVal(checkedData);
	};

	const addUser = () => {
		history.push('/users/create-account');
	};

	const removeUser = () => {
		const checkedIds = checkedVal.map((value) => value.id);
		const variables: any = {
			deleteUsersInput: checkedIds,
		};
		deleteUsersMutation({ variables });
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTimeout(() => {
			setUserDetail({ email: '', firstName: '', lastName: '', role: UserRole.Staff, isActive: true });
		}, 500);
	};

	const getDetailData = (value: any) => {
		setUserDetail(value);
	};

	useEffect(() => {
		const tempVariables: any = {
			searchUsersInput: {
				name: '',
			},
		};
		searchUsersQuery({ variables: tempVariables });
	}, [searchUsersQuery]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('User Management')} | PMSK</title>
			</Helmet>
			<SimpleSearchBar onSearch={_onSearch} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
						<Button size='small' variant='outlined' color='error' onClick={removeUser}>
							<RemoveIcon fontSize='small' />
							{t('Remove')}
						</Button>
						<Button size='small' variant='outlined' color='primary' onClick={addUser}>
							<AddIcon fontSize='small' />
							{t('Add')}
						</Button>
					</Box>
					<CustomTable
						TableData={tableRowData}
						TableColumn={columnDef}
						loading={loading}
						checkbox
						showTotal
						showDetail
						pagination
						height={550}
						setCheckedValues={getCheckedVal}
						detailDialog={{ setDetailData: getDetailData, openDialog: () => setOpenDialog(true) }}
					/>
					<UserDialog open={openDialog} close={handleCloseDialog} refetch={refetch} editData={userDetail} />
				</Box>
			</Container>
		</Paper>
	);
};
