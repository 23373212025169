import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from '@apollo/client';
import { SAVEPRODUCT_MUTATION } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface AddProductDialogProps {
	open: boolean;
	close: () => void;
	refetch: () => void;
	editData?: ProductData;
}

export interface ProductData {
	id?: number;
	productName: string;
	alias: string;
	brand: string;
	barcode: string;
	unitPrice: number;
	reportableUnitPrice: number;
	cost: number;
	productWeight: number;
	productQty: number;
}

export const ProductDialog: React.FC<AddProductDialogProps> = ({ open = false, close, refetch, editData }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [productData, setProductData] = useState<ProductData>({
		productName: '',
		alias: '',
		brand: '',
		barcode: '',
		unitPrice: 0,
		reportableUnitPrice: 0,
		cost: 0,
		productWeight: 0,
		productQty: 0,
	});
	const { t } = useTranslation();

	const onCompleted = (data: any) => {
		const {
			saveProduct: { success, error },
		} = data;

		if (success) {
			enqueueSnackbar(`Product was saved!`, { variant: 'success', autoHideDuration: 3000 });
			refetch();
			close();
		} else {
			enqueueSnackbar(`Cannot save Product...😥 : ${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [saveProductMutation] = useMutation(SAVEPRODUCT_MUTATION, { variables: { saveProductInput: { ...productData } }, onCompleted });

	const onSave = () => {
		saveProductMutation();
	};

	useEffect(() => {
		if (editData) {
			setProductData(editData);
		}
	}, [editData]);

	return (
		<>
			<Dialog open={open} onClose={close} scroll='paper' maxWidth='md' aria-labelledby='product-detail-dialog-title' aria-describedby='product-detail-dialog-description'>
				<DialogTitle id='product-detail-dialog-title'>{t('Product Information')}</DialogTitle>
				<PerfectScrollbar>
					<DialogContent dividers>
						<PerfectScrollbar>
							<Grid container spacing={2} sx={{ marginTop: 1 }}>
								<Grid item xs={12} md={6}>
									<TextField
										label={t('Product Name')}
										fullWidth
										value={productData.productName}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, productName: newValue, alias: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										label={t('Product Alias')}
										fullWidth
										value={productData.alias}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, alias: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={6} md={4}>
									<TextField
										label={t('Brand')}
										fullWidth
										value={productData.brand}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, brand: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={6} md={4}>
									<TextField
										label={t('Barcode')}
										fullWidth
										value={productData.barcode}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, barcode: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={5} md={2}>
									<TextField
										label={t('Product Qty')}
										fullWidth
										value={productData.productQty}
										type='number'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, productQty: +newValue });
										}}
									/>
								</Grid>
								<Grid item xs={5} md={2}>
									<TextField
										label={t('Product Weight')}
										fullWidth
										value={productData.productWeight}
										type='number'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, productWeight: +newValue });
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('Unit Price')}
										fullWidth
										value={productData.unitPrice}
										type='number'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, unitPrice: +newValue });
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('Reportabl Unit Price')}
										fullWidth
										value={productData.reportableUnitPrice}
										type='number'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, reportableUnitPrice: +newValue });
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('Cost')}
										fullWidth
										value={productData.cost}
										type='number'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setProductData({ ...productData, cost: +newValue });
										}}
									/>
								</Grid>
							</Grid>
						</PerfectScrollbar>
					</DialogContent>
				</PerfectScrollbar>
				<DialogActions>
					<Button onClick={onSave} color='primary'>
						Save
					</Button>
					<Button onClick={close} color='error'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
