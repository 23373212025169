import { useState, useEffect } from 'react';
import { Paper, Container, Box, Button, Checkbox } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { SEARCHCOURIERS_QUERY } from '../../graphql/queries';
import { DELETECOURIERS_MUTATION } from '../../graphql/mutations';
import { SimpleSearchBar } from '../../components/simpleSearchBar';
import CustomTable, { TableColumnDef } from '../../components/customTable';
import { searchCouriersQuery, searchCouriersQueryVariables } from '../../graphql_types/searchCouriersQuery';
import { SearchCouriersInput } from '../../graphql_types/globalTypes';
import { CourierData, CourierDialog } from '../../components/courierDialog';
import { deleteCouriersMutation, deleteCouriersMutationVariables } from '../../graphql_types/deleteCouriersMutation';
import { useTranslation } from 'react-i18next';

export const ManageCouriers = () => {
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [courierDetail, setCourierDetail] = useState<CourierData>({
		name: '',
		trackUrl: '',
		isActive: true,
	});
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('Courier Name'),
			datafield: 'name',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Sequence for Order'),
			datafield: 'seqForOrder',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('isActive'),
			datafield: 'isActvie',
			customValue: (row: any) => {
				return <Checkbox checked={row.isActive} />;
			},
		},
	];

	const onCompleted = (data: searchCouriersQuery) => {
		const {
			searchCouriers: { success, couriers },
		} = data;

		if (success) {
			setTableRowData(couriers as any);
		} else {
			enqueueSnackbar(t(`Cannot search couriers...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onDeleteCompleted = (data: deleteCouriersMutation) => {
		const {
			deleteCouriers: { success },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Courier Deleted`), { variant: 'error', autoHideDuration: 3000 });
			refetch();
		} else {
			enqueueSnackbar(t(`Cannot delete couriers...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [searchCouriersQuery, { loading, refetch }] = useLazyQuery<searchCouriersQuery, searchCouriersQueryVariables>(SEARCHCOURIERS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [deleteCouriersMutation] = useMutation<deleteCouriersMutation, deleteCouriersMutationVariables>(DELETECOURIERS_MUTATION, { onCompleted: onDeleteCompleted });

	const _onSearch = (searchData: SearchCouriersInput) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: searchCouriersQueryVariables = {
			searchCouriersInput: { ...searchData },
		};
		searchCouriersQuery({ variables });
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		//console.log(checkedData);
		setCheckedVal(checkedData);
	};

	const addCourier = () => {
		setOpenDialog(true);
	};

	const removeCourier = () => {
		const checkedIds = checkedVal.map((value) => value.id);
		const variables: deleteCouriersMutationVariables = {
			deleteCourierInput: checkedIds,
		};
		deleteCouriersMutation({ variables });
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTimeout(() => {
			setCourierDetail({ name: '', trackUrl: '', isActive: true });
		}, 500);
	};

	const getDetailData = (value: any) => {
		setCourierDetail(value);
	};

	useEffect(() => {
		const tempVariables: searchCouriersQueryVariables = {
			searchCouriersInput: {
				name: '',
			},
		};
		searchCouriersQuery({ variables: tempVariables });
	}, [searchCouriersQuery]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Courier Management')} | PMSK</title>
			</Helmet>
			<SimpleSearchBar onSearch={_onSearch} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
						<Button size='small' variant='outlined' color='error' onClick={removeCourier}>
							<RemoveIcon fontSize='small' />
							{t('Remove')}
						</Button>
						<Button size='small' variant='outlined' color='primary' onClick={addCourier}>
							<AddIcon fontSize='small' />
							{t('Add')}
						</Button>
					</Box>
					<CustomTable
						TableData={tableRowData}
						TableColumn={columnDef}
						loading={loading}
						checkbox
						showTotal
						showDetail
						pagination
						height={550}
						setCheckedValues={getCheckedVal}
						detailDialog={{ setDetailData: getDetailData, openDialog: () => setOpenDialog(true) }}
					/>
					<CourierDialog open={openDialog} close={handleCloseDialog} refetch={refetch} editData={courierDetail} />
				</Box>
			</Container>
		</Paper>
	);
};
