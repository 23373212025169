import { useEffect, useState } from 'react';
import { Card, Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useQuery } from '@apollo/client';
import { ORDER_COUNT_BY_DATE_QUERY } from '../graphql/queries';
import { useTranslation } from 'react-i18next';

export const OrderCountByDateCard = () => {
	const { data, loading } = useQuery(ORDER_COUNT_BY_DATE_QUERY, { fetchPolicy: 'no-cache' });
	const [slice, setSlice] = useState(-7);
	const { t } = useTranslation();

	const [options, setOptions] = useState<ApexOptions>({
		chart: {
			id: 'order-count-bar',
			toolbar: {
				show: false,
			},
		},
		markers: {
			size: 4,
		},
		stroke: {
			curve: 'smooth',
		},
		xaxis: {
			type: 'datetime',
			labels: {
				format: `MMM-dd(ddd)`,
			},
		},
	});

	const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: number | null) => {
		if (newValue !== null) setSlice(newValue);
	};

	useEffect(() => {}, [slice]);

	return (
		<>
			{!loading && (
				<Card sx={{ margin: '8px' }}>
					<Box sx={{ margin: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<Typography variant='h6'>{t(`Order Count(by Date)`)}</Typography>
						<ToggleButtonGroup size='small' color='primary' value={slice} exclusive onChange={handleChange}>
							<ToggleButton value={-7}>{t(`Week`)}</ToggleButton>
							<ToggleButton value={-14}>{t(`By-Week`)}</ToggleButton>
							<ToggleButton value={-30}>{t(`Month`)}</ToggleButton>
						</ToggleButtonGroup>
					</Box>
					<Chart
						options={options}
						series={[
							{
								name: 'Open Market',
								data: data?.orderCountByDate?.output?.map((result: any) => ({ x: new Date(result.orderDate), y: result.openMarketCount })).slice(slice),
							},
							{
								name: 'B2B',
								data: data?.orderCountByDate?.output?.map((result: any) => ({ x: new Date(result.orderDate), y: result.B2BCount })).slice(slice),
							},
						]}
						type='line'
						width='100%'
						height='205'
					/>
				</Card>
			)}
		</>
	);
};
