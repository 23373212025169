export const LS_TOKEN = 'PMSK-token';
export const LS_LANGUAGE = 'PMSK-lang';
export const APOLLO_URI = process.env.NODE_ENV === 'production' ? 'https://innovic.net/pmsk/backend/graphql' : 'http://localhost:4000/graphql';
//export const APOLLO_URI = 'https://localhost:4000';
export const LANGUAGES = [
	{
		name: 'English',
		code: 'en',
		countryCode: 'CA',
	},
	{
		name: 'Korean',
		code: 'ko',
		countryCode: 'KR',
	},
];
