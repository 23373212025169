import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/styles.css';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme } from './theme';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import './lang/i18n';

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={3}>
					<HelmetProvider>
						<Router>
							<App />
						</Router>
					</HelmetProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
