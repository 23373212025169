import { useMutation } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { createAccountMutation, createAccountMutationVariables } from '../../graphql_types/createAccountMutation';
import { UserRole } from '../../graphql_types/globalTypes';
import { Paper, Container, Typography, TextField, InputLabel, FormControl, Select, MenuItem, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { CREATE_ACCOUNT_MUTATION } from '../../graphql/mutations';
import { useTranslation } from 'react-i18next';

interface ICreateAccountForm {
	email: string;
	password: string;
	confirm_password: string;
	firstName: string;
	lastName: string;
	role: UserRole;
}

export const CreateAccount = () => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		getValues,
		watch,
		formState: { errors },
	} = useForm<ICreateAccountForm>({
		mode: 'onChange',
		defaultValues: {
			role: UserRole.Admin,
		},
	});

	const onCompleted = (data: createAccountMutation) => {
		const {
			createAccount: { success },
		} = data;

		if (success) {
			enqueueSnackbar(`Account Created!`, { variant: 'success', autoHideDuration: 3000 });
			history.push('/users/users');
		}
	};
	const { email, password, role, firstName, lastName } = watch();
	const variables = {
		createAccountInput: { email, password, role, firstName, lastName },
	};

	const [createAccountMutation, { data: createAccountMutationResult, loading }] = useMutation<createAccountMutation, createAccountMutationVariables>(CREATE_ACCOUNT_MUTATION, {
		onCompleted,
		variables,
	});

	const _onSubmit = () => {
		if (!loading) createAccountMutation();
	};

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Create Account')} | PMSK</title>
			</Helmet>
			<Container sx={{ p: 3 }} maxWidth='md'>
				<Typography variant='h3' sx={{ textAlign: 'center', mb: 3 }}>
					{t('Create Account')}
				</Typography>
				<Grid component='form' onSubmit={handleSubmit(_onSubmit)} container spacing={2}>
					<Grid item xs={12}>
						<TextField
							{...register('email', {
								required: 'Email is required',
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: 'Email address invalid',
								},
							})}
							required
							fullWidth
							type='email'
							placeholder={t('Email')}
						/>
						{errors.email && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: 'error.main' }}>
								{errors.email.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField {...register('password', { required: 'Password is required' })} required type='password' placeholder={t('Password')} fullWidth />
						{errors.password && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: 'error.main' }}>
								{errors.password.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField
							{...register('confirm_password', { required: 'Password is required', validate: (value) => value === getValues().password })}
							required
							type='password'
							fullWidth
							placeholder={t('Confirm Password')}
						/>
						{errors.confirm_password && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: 'error.main' }}>
								{errors.confirm_password.message}
							</Typography>
						)}
						{errors.confirm_password && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: 'error.main' }}>
								Password not matched
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField {...register('firstName', { required: 'First Name is required' })} required type='text' placeholder={t('First Name')} fullWidth />
						{errors.firstName && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: 'error.main' }}>
								{errors.firstName.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField {...register('lastName', { required: 'Last Name is required' })} required type='text' placeholder={t('Last Name')} fullWidth />
						{errors.lastName && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: 'error.main' }}>
								{errors.lastName.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<InputLabel id='roleLabel'>Role</InputLabel>
							<Select {...register('role')} labelId='roleLabel' label={t('Role')} defaultValue={UserRole.Admin}>
								{Object.keys(UserRole).map((role, idx) => (
									<MenuItem value={role} key={idx}>
										{role}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<LoadingButton variant='contained' loading={loading} fullWidth type='submit'>
							Sign Up
						</LoadingButton>
						{createAccountMutationResult?.createAccount.error && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: 'error.main' }}>
								{createAccountMutationResult.createAccount.error}
							</Typography>
						)}
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
};
