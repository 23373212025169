import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomOrderTable, { TableColumnDef } from '../../components/customOrderTable';
import { Paper, Container, Box, Button, Chip, Menu, MenuItem, Stack, Tooltip,Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
	AutorenewOutlined as AutorenewIcon,
	LocalShippingOutlined as ShippingIcon,
	CallMerge as MergeIcon,
	CallSplitOutlined as SplitIcon,
	FlightTakeoffOutlined as ShippingModeIcon,
	FileDownloadOutlined as DownloadIcon,
} from '@mui/icons-material';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { OrderSearchBar } from '../../components/ordersearchbar';
import { searchOrdersQuery, searchOrdersQueryVariables } from '../../graphql_types/searchOrdersQuery';
import { SearchOrdersInput, OrderState, UserRole } from '../../graphql_types/globalTypes';
import { Helmet } from 'react-helmet-async';
import { SEARCHORDERS_QUERY } from '../../graphql/queries';
import { CHANGESTATE_MUTATION, MERGEORDER_MUTATION, SAVEORDERS_MUTATION } from '../../graphql/mutations';
import { DownloadExcelButton } from '../../components/downloadExcelButton';
import { padStart } from '../../utils';
import { ShippingUploadDialog } from '../../components/shippingUploadDialog';
import React from 'react';
import * as XLSX from 'xlsx';
import { userInfoVar } from '../../apollo';
import { OrderStateChip } from '../../components/orderStateChip';
import { useTranslation } from 'react-i18next';

export const ManageOrder = () => {
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
	const [openShipDialog, setOpenShipDialog] = useState(false);
	const userInfo = useReactiveVar(userInfoVar);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('Category'),
			datafield: 'category',
			align: 'center',
			headerAlign: 'center',
			hideMobile: true,
		},
		{
			name: t('From'),
			datafield: 'from',
			align: 'center',
			headerAlign: 'center',
			hideMobile: true,
		},
		{
			name: t('Name'),
			datafield: 'pccName',
			align: 'center',
			headerAlign: 'center',
			width: 130,
		},
		{
			name: t('Address'),
			datafield: 'addr',
			width: 450,
			hideMobile: true,
		},
		{
			name: t('PCC'),
			datafield: 'PCC',
			style: (row: any) => {
				if (!row.pccResult) return { color: 'warning.main' };
				return {};
			},
			customValue: (row: any) => {
				if(row.pccResultMsg){
					return <Tooltip title={row.pccResultMsg}><span>{row.PCC}</span></Tooltip>;
				}
				return `${row.PCC}`;
			}
		},
		{
			name: t('Order'),
			datafield: 'orderList',
			width: 500,
			customValue: (row: any) => {
				let itemCnt = 0;
				for (let i = 0; i < row.orderProducts.length; i++) {
					itemCnt += row.orderProducts[i].qty * row.orderProducts[i].productQty;
				}
				if (row.orderProducts.length > 1) return `(${itemCnt} item)${row.orderProducts[0].productName} 외 ${row.orderProducts.length - 1}`;
				return `(${itemCnt} item)${row.orderProducts[0]?.productName}`;
			},
			style: (row: any) => {
				let itemCnt = 0;
				for (let i = 0; i < row.orderProducts.length; i++) {
					itemCnt += row.orderProducts[i].qty * row.orderProducts[i].productQty;
				}
				if (itemCnt > 6) return { color: 'warning.main' };
				return {};
			},
			hideMobile: true,
		},
		{
			name: t('Order Status'),
			datafield: 'orderState',
			customValue: (row: any) => {
				return <OrderStateChip key={row.id} state={row.orderState} size='small' />;
			},
		},
		{
			name: t('Courier'),
			datafield: 'courier.name',
			align: 'center',
			headerAlign: 'center',
			hideMobile: true,
			customValue: (row: any) => {
				if (!row.courier) return '';
				return `${row.courier?.name}`;
			},
		},
		{
			name: t('Order Date'),
			datafield: 'orderedAt',
			customValue: (row: any) => {
				const date = new Date(row.orderedAt);
				return `${padStart(date.getMonth() + 1, 2, '0')}-${padStart(date.getDate(), 2, '0')}`;
			},
			hideMobile: true,
		},
	];

	const onCompleted = (data: searchOrdersQuery) => {
		const {
			searchOrder: { success, orders },
		} = data;

		if (success) {
			//console.log(orders);
			//enqueueSnackbar(`Orders are saved!`, { variant: "success", autoHideDuration: 3000 });
			setTableRowData(orders as any);
		} else {
			enqueueSnackbar(t(`Cannot search orders...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onChangeStateCompleted = (data: any) => {
		const {
			changeState: { success, error },
		} = data;

		if (success) {
			refetch(); //searchOrdersQuery
			enqueueSnackbar(t(`Status has been changed`), { variant: 'success', autoHideDuration: 3000 });
		} else {
			enqueueSnackbar(`${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onMergeOrderCompleted = (data: any) => {
		const {
			mergeOrder: { success, error },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Order merged successfully`), { variant: 'success', autoHideDuration: 3000 });
			refetch(); //searchOrdersQuery
		} else {
			enqueueSnackbar(`${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onSplitOrderCompleted = (data: any) => {
		const {
			saveOrders: { success, error },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Order split successfully`), { variant: 'success', autoHideDuration: 3000 });
			refetch(); //searchOrdersQuery
		} else {
			enqueueSnackbar(`${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [searchOrdersQuery, { loading, refetch }] = useLazyQuery(SEARCHORDERS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [changeStateMutation] = useMutation(CHANGESTATE_MUTATION, {
		onCompleted: onChangeStateCompleted,
	});

	const [mergeOrderMuation, { loading: mergeOrderLoading }] = useMutation(MERGEORDER_MUTATION, {
		onCompleted: onMergeOrderCompleted,
	});

	const [splitOrderMuation, { loading: splitOrderLoading }] = useMutation(SAVEORDERS_MUTATION, {
		onCompleted: onSplitOrderCompleted,
	});

	const _onSearch = (searchData: SearchOrdersInput) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: searchOrdersQueryVariables = {
			searchOrderInput: { ...searchData },
		};
		searchOrdersQuery({ variables });
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setMenuEl(event.currentTarget);
	};

	const closeMenu = () => {
		setMenuEl(null);
	};

	const handleChangeStatus = (to: OrderState) => (event: React.MouseEvent<HTMLElement>) => {
		let error = { isError: false, errorMsg: '' };
		const ids = checkedVal.map((data) => {
			if (data.orderState === OrderState.OrderConfirm && to !== OrderState.ShipReady && to !== OrderState.Canceled) {
				error.isError = true;
				error.errorMsg = t(`It cannot change status '${to}' directly from 'OrderConfirm'`);
			}
			if (to === OrderState.ShipReady && !data.shippingNo) {
				error.isError = true;
				error.errorMsg = t(`It cannot change status 'ShipReady' without Shipping No.`);
			}
			return data.id;
		});
		if (error.isError) {
			enqueueSnackbar(error.errorMsg, { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (!ids.length) {
			enqueueSnackbar(t(`Check order data to change status!`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		changeStateMutation({ variables: { changeStateIds: ids, changeStateOrderState: to } });
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		setCheckedVal(checkedData);
	};

	const _onAddShippingNo = () => {
		setOpenShipDialog(true);
	};

	const _onMergeOrder = () => {
		//console.log(checkedVal);
		if (checkedVal.length !== 2) {
			enqueueSnackbar(t(`Pleas select only 2 orders`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (checkedVal[0].PCC !== checkedVal[1].PCC) {
			enqueueSnackbar(t(`Cannot merge with different PCC`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		mergeOrderMuation({ variables: { mergeOrder1Id: checkedVal[0].id, mergeOrder2Id: checkedVal[1].id } });
	};

	const _onSplitOrder = () => {
		//console.log(checkedVal);
		if (checkedVal.length !== 1) {
			enqueueSnackbar(t(`Pleas select only 1 order`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}

		//trim unnecessary data
		let splitOrder: any = { ...checkedVal[0], sortOrder: 1, orderState: OrderState.OrderConfirm, orderAmount: 0, netIncome: 0, orderedAt: new Date() };
		delete splitOrder['customer'];
		delete splitOrder['courier'];
		delete splitOrder['id'];

		splitOrderMuation({ variables: { orderInput: [splitOrder] } });
		refetch({ searchOrderInput: { name: splitOrder.PCC } });
	};

	const goShippingMode = () => {
		history.push('/orders/shipping-mode');
	};

	const _download = (data: any) => (e: React.MouseEvent<HTMLElement>) => {
		const exportData: any[] = [];
		const countData: any[] = [];
		const summaryData: any[] = [];
		
		data.forEach((d: any) => {
			for (let i = 0; i < d.orderProducts.length; i++) {
				if(d.orderState!==OrderState.Canceled){
					const productInfo = d.orderProducts[i];
					exportData.push({ from: d.from, productName: productInfo.productName, option: productInfo.option, qty: productInfo.qty, alias: productInfo.alias });
					countData.push({ productName: productInfo.productName, qty: productInfo.qty * productInfo.productQty, alias: productInfo.alias, upc: productInfo.barcode });
					summaryData.push({ from: d.from, productName: productInfo.productName, qty: productInfo.qty * productInfo.productQty, alias: productInfo.alias, upc: productInfo.barcode });
				}
			}
		});

		if(exportData.length == 0){
			enqueueSnackbar(t(`Cannot download summary file... Data on canceled orders is excluded automatically.`), { variant: 'error', autoHideDuration: 3000 });
			return;
		}

		const countResult: any[] = [];
		const summaryResult: any[] = [];
		countData.reduce((res: any, value: any) => {
			if (!res[value.alias]) {
				res[value.alias] = { productName: value.alias, qty: 0, upc: value.upc ? value.upc.split('-')[0] : '' };
				countResult.push(res[value.alias]);
			}
			res[value.alias].qty += value.qty;
			if (!res[value.alias].upc && value.upc) res[value.alias].upc = value.upc.split('-')[0];
			return res;
		}, {});

		summaryData.reduce((res: any, value: any) => {
			const key = `${value.from}|${value.alias}`;
			if(!res[key]){
				res[key] = { channel: value.from, productName: value.alias, qty: 0, upc: value.upc ? value.upc.split('-')[0] : '' };
				summaryResult.push(res[key]);
			}
			res[key].qty += value.qty;
			
			if (!res[key].upc && value.upc) res[key].upc = value.upc.split('-')[0];
			return res;
		}, {});

		countResult.sort((a, b) => {
			return a.productName < b.productName ? -1 : a.productName > b.productName ? 1 : 0;
		});

		summaryResult.sort((a, b) => {
			// compare channel
			if (a.channel < b.channel) return -1;
			if (a.channel > b.channel) return 1;
	
			// if channel is same, compare productName
			if (a.productName < b.productName) return -1;
			if (a.productName > b.productName) return 1;
	
			return 0;
		});

		enqueueSnackbar(t(`Download summary file. Data on canceled orders is excluded automatically.`), { variant: "success", autoHideDuration: 3000 });
		const FILE_NAME = `Selling-products.xlsx`;
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(exportData);
		const countSheet = XLSX.utils.json_to_sheet(countResult);
		const summarySheet = XLSX.utils.json_to_sheet(summaryResult);

		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.utils.book_append_sheet(wb, countSheet, 'Count');
		XLSX.utils.book_append_sheet(wb, summarySheet, 'Summary');
		XLSX.writeFile(wb, FILE_NAME);
	};

	useEffect(() => {
		const toDay = new Date();
		const tempVariables: searchOrdersQueryVariables = {
			searchOrderInput: {
				orderFrom: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, 0),
				orderTo: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59),
			},
		};
		searchOrdersQuery({ variables: tempVariables });
	}, [searchOrdersQuery]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Order Management')} | PMSK</title>
			</Helmet>
			<OrderSearchBar onSearch={_onSearch} isSearching={loading} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					{userInfo.role !== UserRole.Staff && (
						<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
							<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
								<Stack direction='row' spacing={1}>
									<Button size='small' onClick={_onAddShippingNo}>
										<ShippingIcon fontSize='small' />
										{t('Shipping')}
									</Button>
									{(userInfo.role === UserRole.Admin || userInfo.role === UserRole.Manager) && (
										<Button size='small' onClick={_download(checkedVal)}>
											<DownloadIcon fontSize='small' />
											{t('Download')}
										</Button>
									)}
								</Stack>
								<Stack direction='row' spacing={1}>
									<DownloadExcelButton data={checkedVal} />
									<Button size='small' onClick={handleMenu} variant='outlined'>
										<AutorenewIcon fontSize='small' />
										{t('Status')}
									</Button>
									<Menu
										sx={{ mt: '35px' }}
										id='menu-status'
										anchorEl={menuEl}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(menuEl)}
										onClose={closeMenu}>
										{Object.keys(OrderState).map((state: any) => (
											<MenuItem onClick={handleChangeStatus(state)} key={state}>
												<OrderStateChip state={state} size='small' />
											</MenuItem>
										))}
									</Menu>
								</Stack>
								<Stack direction='row' spacing={1}>
									<LoadingButton loading={mergeOrderLoading} size='small' loadingPosition='start' onClick={_onMergeOrder} startIcon={<MergeIcon fontSize='small' />} variant='contained'>
										{t('Merge')}
									</LoadingButton>
									<LoadingButton loading={splitOrderLoading} size='small' loadingPosition='start' onClick={_onSplitOrder} startIcon={<SplitIcon fontSize='small' />} variant='contained'>
										{t('Copy')}
									</LoadingButton>
								</Stack>
							</Stack>
						</Box>
					)}
					<ShippingUploadDialog open={openShipDialog} close={() => setOpenShipDialog(false)} refetch={refetch} />
					<CustomOrderTable
						TableData={tableRowData}
						TableColumn={columnDef}
						loading={loading}
						checkbox
						showOrderDetail
						showTotal
						pagination
						height={450}
						setCheckedValues={getCheckedVal}
						refetch={refetch}
						editDetailInfo
					/>
				</Box>
				{/* <div style={{ display: "none" }} id='printArea'>
					<CustomOrderTable TableData={tableRowData} TableColumn={columnDef} showTotal />
				</div> */}
				<Chip icon={<ShippingModeIcon />} label={t('Shipping Mode')} onClick={goShippingMode} color='info' sx={{ position: 'fixed', top: '80px', right: '20px', fontWeight: 600 }}></Chip>
			</Container>
		</Paper>
	);
};
