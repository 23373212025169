import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from '@apollo/client';
import { SAVECOURIER_MUTATION } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { saveCourierMutation, saveCourierMutationVariables } from '../graphql_types/saveCourierMutation';
import { useTranslation } from 'react-i18next';

interface AddCourierDialogProps {
	open: boolean;
	close: () => void;
	refetch: () => void;
	editData?: CourierData;
}

export interface CourierData {
	id?: number;
	name: string;
	trackUrl: string;
	seqForOrder?: string;
	isActive: boolean;
}

export const CourierDialog: React.FC<AddCourierDialogProps> = ({ open = false, close, refetch, editData }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [courierData, setCourierData] = useState<CourierData>({
		name: '',
		trackUrl: '',
		isActive: true,
	});
	const { t } = useTranslation();

	const onCompleted = (data: saveCourierMutation) => {
		const {
			saveCourier: { success, error },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Courier was saved!`), { variant: 'success', autoHideDuration: 3000 });
			refetch();
			close();
		} else {
			enqueueSnackbar(`${t('Cannot save Courier...😥')} : ${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [saveCourierMutation] = useMutation<saveCourierMutation, saveCourierMutationVariables>(SAVECOURIER_MUTATION, { variables: { saveCourierInput: { ...courierData } }, onCompleted });

	const onSave = () => {
		//console.log(courierData);
		if (!courierData.name || courierData.name === '') {
			enqueueSnackbar(t(`Courier Name is required`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		saveCourierMutation();
	};

	useEffect(() => {
		if (editData) {
			setCourierData(editData);
		}
	}, [editData]);

	return (
		<>
			<Dialog open={open} onClose={close} scroll='paper' maxWidth='lg' aria-labelledby='courier-detail-dialog-title' aria-describedby='courier-detail-dialog-description'>
				<DialogTitle id='courier-detail-dialog-title'>{t('Courier Information')}</DialogTitle>
				<PerfectScrollbar>
					<DialogContent dividers>
						<PerfectScrollbar>
							<Grid container spacing={2} sx={{ marginTop: 1 }}>
								<Grid item xs={4}>
									<TextField
										label={t('Couriere Name')}
										fullWidth
										value={courierData.name}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCourierData({ ...courierData, name: newValue });
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('Seq for Order')}
										fullWidth
										value={courierData.seqForOrder}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCourierData({ ...courierData, seqForOrder: newValue });
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox checked={courierData.isActive} />}
											label={t('Active')}
											onChange={(e: any) => {
												setCourierData({ ...courierData, isActive: !courierData.isActive });
											}}
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label={t('Tracking URL')}
										fullWidth
										value={courierData.trackUrl}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setCourierData({ ...courierData, trackUrl: newValue });
										}}
									/>
								</Grid>
							</Grid>
						</PerfectScrollbar>
					</DialogContent>
				</PerfectScrollbar>
				<DialogActions>
					<Button onClick={onSave} color='primary'>
						Save
					</Button>
					<Button onClick={close} color='error'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
