import { Route, Switch, Redirect } from "react-router-dom";
import { CreateAccount } from "../pages/User/create-account";
import { Login } from "../pages/login";

export const LoggedOutRouter = () => {
	return (
		<Switch>
			<Route path='/login' exact>
				<Login />
			</Route>
			<Route path='/create-account'>
				<CreateAccount />
			</Route>
			<Redirect to='/login' />
		</Switch>
	);
};
