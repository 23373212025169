import { useState } from 'react';
import {
	Box,
	IconButton,
	Card,
	CardContent,
	FormControl,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
	TextField,
	InputAdornment,
	Stack,
	Checkbox,
	Select,
	MenuItem,
	CircularProgress,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { OrderCategory, SearchOrdersInput, OrderState } from '../graphql_types/globalTypes';
import { useQuery } from '@apollo/client';
import { customersInfoQuery } from '../graphql_types/customersInfoQuery';
import { couriersInfoQuery } from '../graphql_types/couriersInfoQuery';
import { COURIERS_INFO_QUERY, CUSTOMERS_INFO_QUERY } from '../graphql/queries';
import { useTranslation } from 'react-i18next';

interface OrderSearchBarProp {
	onSearch: (data: SearchOrdersInput) => (e: React.FormEvent<HTMLFormElement>) => void;
	isSearching: boolean;
}

export interface SearchDataProp {
	category?: OrderCategory | 'All';
	orderState: OrderState | 'All';
	courier?: number | 'All';
	customer?: number | 'All';
	orderFrom: Date | null;
	orderTo: Date | null;
	isPccError: boolean;
	name: string;
}

export const OrderSearchBar: React.FC<OrderSearchBarProp> = ({ onSearch, isSearching = false, ...rest }) => {
	const toDay = new Date();
	const [searchData, setSearchData] = useState<SearchDataProp>({
		category: 'All',
		orderState: 'All',
		courier: 'All',
		customer: 'All',
		orderFrom: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, 0),
		orderTo: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59),
		isPccError: false,
		name: '',
	});
	const { data } = useQuery<couriersInfoQuery>(COURIERS_INFO_QUERY);
	const { data:fromData } = useQuery<customersInfoQuery>(CUSTOMERS_INFO_QUERY);
	const { t } = useTranslation();

	return (
		<Box {...rest}>
			<Box>
				<Card>
					<CardContent
						component='form'
						sx={{ padding: '24px', paddingBottom: '24px' }}
						onSubmit={onSearch({
							...searchData,
							category: searchData.category === 'All' ? null : searchData.category,
							orderState: searchData.orderState === 'All' ? null : searchData.orderState,
							courier: searchData.courier === 'All' ? null : searchData.courier,
							customer: searchData.customer === 'All' ? null : searchData.customer
						})}>
						<Box>
							<FormControl component='fieldset' sx={{ marginRight: 5 }}>
								<FormLabel component='legend'>{t('Category')}</FormLabel>
								<RadioGroup
									sx={{ mt: 2 }}
									row
									aria-label='Category'
									name='row-radio-buttons-group'
									value={searchData.category}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, category: newValue });
									}}>
									<FormControlLabel value='All' control={<Radio />} label={t('All')} />
									<FormControlLabel value={OrderCategory.OpenMarket} control={<Radio />} label={t('Open Market')} />
									<FormControlLabel value={OrderCategory.B2B} control={<Radio />} label={t('B2B')} />
								</RadioGroup>
							</FormControl>
							<FormControl sx={{ width: '120px'}}>
								<FormLabel component='legend'>{t('From')}</FormLabel>
								<Select
									sx={{ marginTop: 1 }}
									id='order-from-select'
									value={searchData.customer}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, customer: newValue });
									}}>
									<MenuItem value='All'>{t('All')}</MenuItem>
									{fromData?.customersInfo?.customers?.map((customer: any) => {
										if(customer.isActive){
											return (
												<MenuItem value={customer.id} key={customer.id}>
													{customer.name}
												</MenuItem>
											);
										}
									})}
								</Select>
							</FormControl>
							<FormControl sx={{ width: '120px', marginLeft: '10px' }}>
								<FormLabel component='legend'>{t('Status')}</FormLabel>
								<Select
									sx={{ marginTop: 1 }}
									id='order-state-select'
									value={searchData.orderState}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, orderState: newValue });
									}}>
									<MenuItem value='All'>{t('All')}</MenuItem>
									{Object.keys(OrderState).map((state) => {
										if (state === OrderState.Sent) return null;
										return (
											<MenuItem value={state} key={state}>
												{t(state)}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControl sx={{ width: '120px', marginLeft: '10px' }}>
								<FormLabel component='legend'>{t('Courier')}</FormLabel>
								<Select
									sx={{ marginTop: 1 }}
									id='order-state-select'
									value={searchData.courier}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, courier: newValue });
									}}>
									<MenuItem value='All'>{t('All')}</MenuItem>
									{data?.couriersInfo?.couriers?.map((courier: any) => {
										if(courier.isActive){
											return (
												<MenuItem value={courier.id} key={courier.id}>
													{courier.name}
												</MenuItem>
											);
										}
									})}
								</Select>
							</FormControl>
							<FormControl component='fieldset' sx={{ marginLeft: 3 }}>
								<FormLabel component='legend'>{t('PCC')}</FormLabel>
								<FormControlLabel
									control={
										<Checkbox
											checked={searchData.isPccError}
											onChange={(e: any) => {
												const {
													target: { checked: newValue },
												} = e;
												//console.log(newValue);
												setSearchData({ ...searchData, isPccError: newValue });
											}}
										/>
									}
									label={t('PCC Error')}
									labelPlacement='end'
									sx={{ mt: 2 }}
								/>
							</FormControl>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<FormLabel component='legend'>{t('Date')}</FormLabel>
								<Stack spacing={3} direction='row' sx={{ marginTop: 1, marginBottom: 1 }}>
									<DatePicker
										label='From'
										openTo='day'
										views={['year', 'month', 'day']}
										value={searchData.orderFrom}
										onChange={(newValue: Date | null) => {
											newValue?.setHours(0, 0, 0);
											setSearchData({ ...searchData, orderFrom: newValue });
										}}
										renderInput={(params: any) => <TextField {...params} />}
									/>
									<DatePicker
										label='To'
										openTo='day'
										views={['year', 'month', 'day']}
										value={searchData.orderTo}
										onChange={(newValue: Date | null) => {
											newValue?.setHours(23, 59, 59);
											setSearchData({ ...searchData, orderTo: newValue });
										}}
										renderInput={(params: any) => <TextField {...params} />}
									/>
								</Stack>
							</LocalizationProvider>
							<TextField
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											{!isSearching && (
												<IconButton sx={{ p: '10px' }} aria-label='search' type='submit'>
													<SearchIcon />
												</IconButton>
											)}
											{isSearching && <CircularProgress size={24} color='inherit' />}
										</InputAdornment>
									),
								}}
								value={searchData.name}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setSearchData({ ...searchData, name: newValue });
								}}
								placeholder={t('Search')}
								variant='outlined'
							/>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
};
