import { MenuRoutes } from '../routers/menu';
import logo from '../logo.png';
import { Drawer, List, Divider, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { MenuListItem } from './menuItem';

interface RouteMenuProps {
	routes: MenuRoutes[];
	sidebarWidth: number;
	open: boolean;
	toggle?: any;
}

export const Sidebar: React.FC<RouteMenuProps> = ({ routes, sidebarWidth, open, toggle, ...rest }) => {
	const drawer = (
		<>
			<Box component='nav' sx={{ display: 'flex', alignItems: 'center', p: 1, width: sidebarWidth }}>
				<Link to='/orders/manage'>
					<img src={logo} alt='purecell-logo' className='w-12' />
				</Link>
				<Link to='/orders/manage'>
					<Typography variant='h4' sx={{ color: 'success.main', ml: 1 }}>
						PMSK
					</Typography>
				</Link>
			</Box>
			<Divider />
			<List>
				{routes.map((prop, key) => {
					if (prop.hide) return null;
					return <MenuListItem key={key} menuProps={prop}></MenuListItem>;
				})}
			</List>
		</>
	);

	return (
		<Box component='nav' sx={{ width: { lg: sidebarWidth }, flexShrink: { lg: 0 } }}>
			<Drawer
				variant='temporary'
				open={open}
				onClose={toggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{ display: { xs: 'block', lg: 'none' } }}>
				{drawer}
			</Drawer>
			<Drawer variant='permanent' open sx={{ display: { xs: 'none', lg: 'block' } }}>
				{drawer}
			</Drawer>
		</Box>
	);
};
