import { useState, useEffect } from 'react';
import CustomOrderTable, { TableColumnDef } from '../../components/customOrderTable';
import { Paper, Container, Box, Stack, TextField, IconButton, CircularProgress, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { EditOutlined as EditIcon, CheckOutlined as CheckIcon } from '@mui/icons-material';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { FINDPCCERROR_QUERY } from '../../graphql/queries';
import { padStart } from '../../utils';
import { ShippingUploadDialog } from '../../components/shippingUploadDialog';
import { SimpleSearchBar } from '../../components/simpleSearchBar';
import { findPccErrorQuery, findPccErrorQueryVariables } from '../../graphql_types/findPccErrorQuery';
import { CHECKANDSAVEPCC_MUTATION, CONFIRMPCCCHECK_MUTATION } from '../../graphql/mutations';
import { checkAndSavePCCMutation, checkAndSavePCCMutationVariables } from '../../graphql_types/checkAndSavePCCMutation';
import { confirmPccCheckMutation, confirmPccCheckMutationVariables } from '../../graphql_types/confirmPccCheckMutation';
import { userInfoVar } from '../../apollo';
import { UserRole } from '../../graphql_types/globalTypes';
import { useTranslation } from 'react-i18next';

export const PCCErrorPage = () => {
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [openShipDialog, setOpenShipDialog] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const userInfo = useReactiveVar(userInfoVar);
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('From'),
			datafield: 'from',
			align: 'center',
			headerAlign: 'center',
		},
		{
			name: t('Name'),
			datafield: 'name',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Buyer Phone'),
			datafield: 'buyerPhone',
			align: 'center',
			headerAlign: 'center',
		},
		{
			name: t('Reciever Phone'),
			datafield: 'recieverPhone',
			align: 'center',
			headerAlign: 'center',
		},
		{
			name: t('PCC'),
			datafield: 'beforePCC',
			style: (row: any) => {
				return { color: 'warning.main' };
			},
		},
		{
			name: t('Modified Name'),
			datafield: 'modifiedName',
			headerAlign: 'center',
			align: 'center',
			style: (row: any) => {
				if (!row.modifyChecked) {
					return { color: 'error.main', fontWeight: 600 };
				}
				return { color: 'primary.main', fontWeight: 600 };
			},
			customValue: (row: any) => {
				//console.log(row.modifiedName);
				return row.editMode ? (
					<TextField
						size='small'
						sx={{ width: '6rem' }}
						value={row.modifiedName ? row.modifiedName : row.name}
						onChange={(e: any) => {
							const {
								target: { value: newValue },
							} = e;
							const tempRow = tableRowData.map((rowData) => {
								if (rowData.id === row.id) return { ...rowData, modifiedName: newValue };
								else return rowData;
							});
							setTableRowData(tempRow);
						}}
					/>
				) : row.modifiedName ? (
					row.modifiedName
				) : (
					row.name
				);
			},
		},
		{
			name: t('Modified PCC Phone'),
			datafield: 'pccPhone',
			headerAlign: 'center',
			align: 'center',
			style: (row: any) => {
				if (!row.modifyChecked) {
					return { color: 'error.main', fontWeight: 600, width: '11rem' };
				}
				return { color: 'primary.main', fontWeight: 600, width: '11rem' };
			},
			customValue: (row: any) => {
				return row.editMode ? (
					<TextField
						size='small'
						sx={{ width: '9.5rem' }}
						value={row.pccPhone}
						onChange={(e: any) => {
							const {
								target: { value: newValue },
							} = e;
							const tempRow = tableRowData.map((rowData) => {
								if (rowData.id === row.id) return { ...rowData, pccPhone: newValue };
								else return rowData;
							});
							setTableRowData(tempRow);
						}}
					/>
				) : (
					row.pccPhone
				);
			},
		},
		{
			name: t('Modified PCC'),
			datafield: 'modifiedPCC',
			align: 'center',
			headerAlign: 'center',
			style: (row: any) => {
				if (!row.modifyChecked) {
					return { color: 'error.main', fontWeight: 600 };
				}
				return { color: 'primary.main', fontWeight: 600 };
			},
			customValue: (row: any) => {
				return (
					<div style={{ width: '12rem' }}>
						{row.editMode ? (
							<TextField
								size='small'
								value={row.modifiedPCC || ''}
								sx={{ width: '9rem' }}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									const tempRow = tableRowData.map((rowData) => {
										if (rowData.id === row.id) return { ...rowData, modifiedPCC: newValue };
										else return rowData;
									});
									setTableRowData(tempRow);
								}}
							/>
						) : (
							row.modifiedPCC
						)}
						<IconButton
							aria-label='check pcc'
							size='small'
							sx={{ mt: 0.5 }}
							onClick={() => {
								if (row.editMode && (row.modifiedName || row.name) && row.pccPhone && row.modifiedPCC) {
									const variables = {
										pccInfo: { id: row.id, errId: row.errId, name: row.modifiedName ? row.modifiedName.trim() : row.name.trim(), pcc: row.modifiedPCC.trim(), phoneNo: row.pccPhone.trim() },
									};
									checkAndSavePccMutation({ variables, onCompleted: onCheckPCCCompleted });
								} else {
									const tempRow = tableRowData.map((rowData) => {
										if (rowData.id === row.id) return { ...rowData, editMode: !rowData.editMode };
										else return rowData;
									});
									setTableRowData(tempRow);
								}
							}}>
							{row.editMode ? pccCheckLoading ? <CircularProgress size={18} color='inherit' /> : <CheckIcon fontSize='inherit' /> : <EditIcon fontSize='inherit' />}
						</IconButton>
					</div>
				);
			},
		},
		{
			name: t('PCC Result'),
			datafield: 'checkMsg',
			style: (row: any) => {
				if (!row.modifyChecked) {
					return { color: 'error.main', fontWeight: 600, fontSize: '0.65em' };
				}
				return { color: 'primary.main', fontWeight: 600, fontSize: '0.65em' };
			},
		},
		{
			name: t('Product'),
			datafield: 'briefOrder',
		},
		{
			name: t('Order Date'),
			datafield: 'orderDate',
			headerAlign: 'center',
			align: 'center',
			customValue: (row: any) => {
				const date = new Date(row.orderDate);
				//console.log(date);
				return `${padStart(date.getMonth() + 1, 2, '0')}-${padStart(date.getDate(), 2, '0')}`;
			},
		},
	];

	const onCompleted = (data: findPccErrorQuery) => {
		const {
			findPccError: { success, errorList },
		} = data;

		if (success) {
			if (errorList) {
				setTableRowData(errorList);
			}
		} else {
			enqueueSnackbar(t(`Cannot search orders...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onCheckPCCCompleted = (data: checkAndSavePCCMutation) => {
		const {
			checkAndSavePCC: { success, checkResult },
		} = data;

		if (success && checkResult) {
			console.log(checkResult, tableRowData);
			const tempRow = tableRowData.map((rowData) => {
				//console.log(rowData);
				if (rowData.id === checkResult.id) return { ...rowData, modifyChecked: checkResult.resultCode === 1, editMode: !rowData.editMode, checkMsg:checkResult.errorMsg };
				else return rowData;
			});
			//console.log(tempRow);
			setTableRowData(tempRow);
		} else {
		}
	};

	const onCompletedConfirm = (data: confirmPccCheckMutation) => {
		const {
			confirmPCCCheck: { success },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Confirmed`), { variant: 'success', autoHideDuration: 3000 });
			refetch();
		} else {
			enqueueSnackbar(t(`Cannot search orders...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [findPccErrorQuery, { loading, refetch }] = useLazyQuery<findPccErrorQuery, findPccErrorQueryVariables>(FINDPCCERROR_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});
	const [checkAndSavePccMutation, { loading: pccCheckLoading }] = useMutation<checkAndSavePCCMutation, checkAndSavePCCMutationVariables>(CHECKANDSAVEPCC_MUTATION, {
		notifyOnNetworkStatusChange: true,
	});
	const [confirmMutation, { loading: confirmLoading }] = useMutation<confirmPccCheckMutation, confirmPccCheckMutationVariables>(CONFIRMPCCCHECK_MUTATION);

	const _onSearch = (searchData: any) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables = {
			findPccErrorInput: { ...searchData },
		};
		findPccErrorQuery({ variables });
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		setCheckedVal(checkedData);
	};

	const _onConfirmCheck = () => {
		const errIds = checkedVal.map((row) => row.errId);
		confirmMutation({ variables: { errIds }, onCompleted: onCompletedConfirm });
	};

	useEffect(() => {
		const tempVariables = {
			findPccErrorInput: {},
		};
		findPccErrorQuery({ variables: tempVariables });
	}, [findPccErrorQuery]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('PCC Error')} | PMSK</title>
			</Helmet>
			<SimpleSearchBar onSearch={_onSearch} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
						<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
							{userInfo.role !== UserRole.Staff && (
								<LoadingButton loading={confirmLoading} size='small' loadingPosition='start' onClick={_onConfirmCheck} startIcon={<CheckIcon fontSize='small' />} variant='contained'>
									{t('Confirm')}
								</LoadingButton>
							)}
						</Stack>
					</Box>
					<ShippingUploadDialog open={openShipDialog} close={() => setOpenShipDialog(false)} refetch={refetch} />
					<CustomOrderTable TableData={tableRowData} TableColumn={columnDef} loading={loading} checkbox showOrderDetail showTotal setCheckedValues={getCheckedVal} refetch={refetch} />
				</Box>
			</Container>
		</Paper>
	);
};
