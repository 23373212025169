import { Card, CardContent, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { STATUS_COUNT_BY_COURIER_QUERY } from '../graphql/queries';
import { getStateColor } from './orderStateChip';
import { useTranslation } from 'react-i18next';

export const CourierUsageCard = () => {
	const { data, loading } = useQuery(STATUS_COUNT_BY_COURIER_QUERY, { fetchPolicy: 'no-cache' });
	const { t } = useTranslation();

	return (
		<>
			{!loading &&
				data?.statusCountByCourier?.output.map((courier: any, idx: number) => {
					return (
						<Card key={idx} sx={{ margin: 1, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<CardContent>
								<Typography variant='h4' component='div'>
									{courier.name}
								</Typography>
							</CardContent>
							{courier.status.map((status: any, idx2: number) => (
								<CardContent key={idx2} sx={{ color: `${getStateColor(status.shipState)}.main` }}>
									<Typography variant='h6' component='div'>
										{t(status.shipState)}
									</Typography>
									<Typography variant='h4' component='div' sx={{ textAlign: 'center' }}>
										{status.count}
									</Typography>
								</CardContent>
							))}
						</Card>
					);
				})}
		</>
	);
};
