import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

interface BarcodeScannerProps {
	scanning: () => void;
	setBarcode: (val: string) => void;
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({ scanning: setScanning, setBarcode }) => {
	const [localStream, setLocalStream] = useState<MediaStream>();
	const Camera = useRef<HTMLVideoElement>(null);
	const Canvas = useRef<HTMLCanvasElement>(null);
	const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
	const hints = new Map();
	const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.UPC_A, BarcodeFormat.EAN_13];
	hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
	const Scan = new BrowserMultiFormatReader(hints, 500);

	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia({
				//video: { facingMode: 'user' }, //전면
				video: { facingMode: 'environment' }, //후면
				//video: true,
				audio: false,
			})
			.then((stream) => {
				setLocalStream(stream);
			});

		if (Canvas.current && Camera.current) {
			Canvas.current.style.position = 'absolute';
			Canvas.current.style.top = '0';
			Canvas.current.width = 640;
			Canvas.current.height = 480;
			const context = Canvas.current.getContext('2d');
			if (context) {
				setCtx(context);
			}
		}

		return () => {
			Stop();
		};
	}, []);
	useEffect(() => {
		if (!Camera.current) return;
		if (localStream && Camera.current) {
			Scanning();
		}
		return () => {
			Stop();
		};
	}, [localStream]);

	const Scanning = async () => {
		if (localStream && Camera.current) {
			try {
				await Scan.decodeFromStream(localStream, Camera.current, (data, err) => {
					if (data) {
						setBarcode(data.getText());

						console.log(data, ctx);
						if (ctx) {
							ctx.clearRect(0, 0, 640, 480);
							ctx.strokeStyle = 'red';
							ctx.lineWidth = 2.5;
							const x = data.getResultPoints()[0].getX();
							const y = data.getResultPoints()[0].getY();
							const w = data.getResultPoints()[1].getX() - data.getResultPoints()[0].getX();
							const h = data.getResultPoints()[1].getY() - data.getResultPoints()[0].getY();
							ctx.strokeRect(x, y, w, h);
						}
						setTimeout(() => {
							setScanning();
							Scan.reset();
						}, 500);
					} else {
						//setText('');
					}
				});
			} catch (error) {
				console.log(error);
			}
		}
	};
	const Stop = () => {
		if (localStream) {
			const vidTrack = localStream.getVideoTracks();
			vidTrack.forEach((track) => {
				localStream.removeTrack(track);
			});
		}
	};
	return (
		<Box sx={{ position: 'relative' }}>
			<video ref={Camera} id='video' />
			<canvas ref={Canvas} />
		</Box>
	);
};
export default BarcodeScanner;
