import { useState, useEffect } from 'react';
import { Paper, LinearProgress, Typography, Container, Box, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, IconButton } from '@mui/material';
import { CleaningServicesOutlined as CleaningServicesIcon, CheckOutlined as CheckIcon, LightbulbOutlined as LightbulbIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useLazyQuery } from '@apollo/client';
import { checkPccQuery, checkPccQueryVariables, checkPccQuery_checkPCC_checkResults } from '../../graphql_types/checkPccQuery';
import { PccInfoType } from '../../graphql_types/globalTypes';
import { CHECKPCC_QUERY } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';

interface rowInfo {
	id: number;
	name: string;
	pcc: string;
	phoneNo: string;
	result: string;
	errorMsg: string | null;
	resultCode?: number;
	isSelected: boolean;
	isLoading: boolean;
}

export const PccVerification = () => {
	const defaultRow = 10;

	const [rowData, setRowData] = useState<rowInfo[]>(
		Array.from({ length: defaultRow }, (_, id) => ({ id: id + 1, name: '', pcc: '', phoneNo: '', result: '', errorMsg: '', isSelected: false, isLoading: false }))
	);
	const [count, setCount] = useState(0);
	const [errCnt, setErrCnt] = useState(0);
	const { t } = useTranslation();

	const onCompleted = (data: checkPccQuery) => {
		console.log(data);
		const {
			checkPCC: { success, error: checkPccError, checkResults },
		} = data;

		if (success && !checkPccError) {
			const result: rowInfo[] = rowData.map((row) => {
				return { ...row, ...checkResults?.find((checkResult: checkPccQuery_checkPCC_checkResults) => checkResult.id === row.id), isLoading: false };
			});
			console.log(result);
			countErr(result);
			setRowData(result);
		}
	};

	const [checkPCC, { loading }] = useLazyQuery<checkPccQuery, checkPccQueryVariables>(CHECKPCC_QUERY, { onCompleted, fetchPolicy: 'no-cache' });

	// PASTE FROM EXCEL
	const _handlePaste = (flag: any, id: number) => (e: any) => {
		//console.log('_handlePase', id);
		const focusObj: any = document.activeElement;
		if (focusObj.tagName === 'INPUT') {
			let clipboardData, pastedData;

			// Get pasted data via clipboard API
			clipboardData = e.clipboardData;
			//console.log(clipboardData);
			pastedData = clipboardData.getData('Text');

			if (pastedData.indexOf('\r\n') < 0 && pastedData.indexOf('\t') < 0) {
				return;
			}

			// Stop data actually being pasted
			e.stopPropagation();
			e.preventDefault();

			// EXCEL DATA
			const splitArray = pastedData.split(/\r\n|\r|\n/).map((data: any) => data.split('\t'));

			if (!splitArray[0][0]) return;

			//console.log(splitArray);

			let tempRows: rowInfo[] = [];
			let templateData: rowInfo = {
				id: 0,
				name: '',
				pcc: '',
				phoneNo: '',
				result: '',
				errorMsg: '',
				isSelected: false,
				isLoading: false,
			};
			for (let j = 1; j < id; j++) {
				tempRows.push({ ...templateData, ...rowData.find((element) => element.id === j) });
			}
			for (let i = 0; i < splitArray.length; i++) {
				const idx = i + id;

				if (flag === 'name') {
					//console.log({id:idx,...templateData,...rowData.find(element => element.id===idx), name:splitArray[i][0], pcc:splitArray[i][1]});
					tempRows.push({ ...templateData, ...rowData.find((element) => element.id === idx), name: splitArray[i][0], phoneNo: splitArray[i][1], pcc: splitArray[i][2], id: idx });
				} else if (flag === 'phoneNo') {
					tempRows.push({ ...templateData, ...rowData.find((element) => element.id === idx), phoneNo: splitArray[i][0], pcc: splitArray[i][1], id: idx });
				} else if (flag === 'pcc') {
					tempRows.push({ ...templateData, ...rowData.find((element) => element.id === idx), pcc: splitArray[i][0], id: idx });
				}
			}
			if (id + splitArray.length < defaultRow) {
				const startRemainRowNum = +(id + splitArray.length);
				for (let j = startRemainRowNum; j < defaultRow + 1; j++) {
					tempRows.push({ ...templateData, ...rowData.find((element) => element.id === j), id: j });
				}
			}
			// const elements = document.querySelectorAll("input");
			// console.log(tempRows, elements.length);
			// elements.forEach((elm, idx) => {
			// 	if (elm.name) {
			// 		if (elm.name === "pcc-name" && !tempRows[Math.floor(idx / 2)].name) elm.value = tempRows[Math.floor(idx / 2)].name;
			// 		else if (elm.name === "pcc" && !tempRows[Math.floor(idx / 2)].pcc) elm.value = tempRows[Math.floor(idx / 2)].pcc;
			// 	}
			// });
			setRowData(tempRows);
		}
	};

	const countRows = () => {
		let cnt = 0;
		for (let i = 0; i < rowData.length; i++) {
			if (rowData[i].name || rowData[i].pcc) cnt++;
		}
		setCount(cnt);
	};

	const _onClickRow = (id: number) => (e: any) => {
		const tempRows = rowData.map((data) => {
			if (data.id === id) data.isSelected = !data.isSelected;
			else data.isSelected = false;
			return data;
		});
		setRowData(tempRows);
	};

	const _onClearRows = (e: any) => {
		//console.log("onClear")
		e.preventDefault();
		const tempRows = [];
		for (let i = 0; i < defaultRow; i++) {
			tempRows.push({ id: i + 1, name: '', pcc: '', phoneNo: '', result: '', errorMsg: '', isSelected: false, isLoading: false });
		}
		//const tempRows = rowData.map((info) => ({ ...info, name: "", pcc: "", result: "", errorMsg: "" }));

		setRowData(tempRows);
		setErrCnt(0);
	};

	const _onChangeText =
		(flag = 'pcc', id: any) =>
		(e: any) => {
			const {
				target: { value },
			} = e;

			const tempRows = rowData.map((row: rowInfo) => {
				if (row.id === id) {
					if (flag === 'pcc') row.pcc = value;
					else if (flag === 'phoneNo') row.phoneNo = value;
					else if (flag === 'name') row.name = value;
				}
				return row;
			});

			setRowData(tempRows);
		};

	const _checkInfo = (e: any) => {
		const initRows: rowInfo[] = rowData.map((data) => ({ ...data, result: '', errorMsg: '' }));
		setRowData(initRows);

		let pccInfo: PccInfoType[] = [];
		rowData.forEach((data) => {
			if (data.name && data.pcc && data.phoneNo) {
				pccInfo.push({ id: data.id, name: data.name, pcc: data.pcc, phoneNo: data.phoneNo });
			}
		});

		//console.log(pccInfo);

		checkPCC({ variables: { pccInfo } });
	};

	const _checkSingleInfo = (singleData: rowInfo) => (e: any) => {
		if (!singleData.name || !singleData.pcc || !singleData.phoneNo) return;
		const initRows = rowData.map((data) => {
			if (data.id !== singleData.id) return data;
			data.result = '';
			data.resultCode = undefined;
			data.isLoading = true;
			return data;
		});
		setRowData(initRows);

		const params: PccInfoType = {
			id: singleData.id,
			name: singleData.name.toUpperCase().trim(),
			pcc: singleData.pcc.toUpperCase().trim(),
			phoneNo: singleData.phoneNo.trim(),
		};

		checkPCC({ variables: { pccInfo: [params] } });
	};

	const _guessId = (singleData: rowInfo) => (e: any) => {
		e.preventDefault();
		//if (!rowData.persEcm || rowData.persEcm.indexOf("-") > -1){
		if (singleData.resultCode === 2) return;
		let guessId = singleData.pcc;
		if (singleData.pcc.indexOf('-') < 0) {
			const checkSum = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
			let frontId: string = singleData.pcc.toString().substring(1, 7);
			let rearId: string = singleData.pcc.toString().substring(7, 13);
			let idNum: string = frontId.toString() + rearId.toString();
			let sum = 0;
			let pin = 11;

			for (let i = 0; i < 12; i++) {
				sum += checkSum[i] * +idNum.charAt(i);
			}
			sum = sum % 11;
			if (sum.toString().length > 1) sum = sum % 11;
			pin = pin - sum;

			const guessNum = pin % 10;
			guessId = `${frontId}-${rearId}${guessNum}`;
		}
		const resultRows = rowData.map((data) => {
			if (data.id !== singleData.id) return data;

			data.pcc = guessId;
			data.result = t('Guessed RRN.');
			data.resultCode = 2;
			data.isLoading = false;

			return data;
		});

		setRowData(resultRows);
		countErr(resultRows);
	};

	const countErr = (results: rowInfo[]) => {
		let cntErr = 0;
		console.log(results);
		results.forEach((result: rowInfo) => {
			if (result.errorMsg !== null && result.errorMsg !== '') {
				console.log(result, 'count');
				cntErr++;
			}
		});
		setErrCnt(cntErr);
	};

	useEffect(() => {
		countRows();
	}, [rowData]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Container sx={{ marginTop: 1 }} maxWidth='lg'>
				<Box sx={{ display: 'flex' }}>
					<Box>
						{errCnt !== 0 && (
							<Typography variant='subtitle2' sx={{ color: 'error.main' }}>
								{t('Error Count')} : {errCnt}
							</Typography>
						)}
						<Typography variant='subtitle2'>
							{t('Rows')} : {count} / {rowData.length}
						</Typography>
					</Box>
					<Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
						<LoadingButton onClick={_checkInfo} loading={loading} size='small' loadingPosition='start' startIcon={<CheckIcon fontSize='small' />} variant='contained'>
							{t('Check All')}
						</LoadingButton>
						<Button size='small' onClick={_onClearRows} disabled={loading}>
							<CleaningServicesIcon fontSize='small' /> {t('Clear')}
						</Button>
					</Box>
				</Box>
				<Box component='div' style={{ maxHeight: 'calc(100vh-100px)', width: '100%', display: 'flex', borderRadius: '8px' }}>
					<Box component='div' sx={{ flexGrow: 1 }}>
						<TableContainer component={Paper} elevation={0} sx={{ marginTop: 1, maxHeight: 650 }}>
							<Table size='small' stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell align='center'>{t('No.')}</TableCell>
										<TableCell align='center'>{t('Name')}</TableCell>
										<TableCell align='center'>{t('Phone')}</TableCell>
										<TableCell align='center'>{t('PCC')}</TableCell>
										<TableCell align='center'>{t('Status')}</TableCell>
										<TableCell align='center'>{t('Action')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{loading && (
										<TableRow>
											<TableCell colSpan={6} sx={{ padding: 0 }}>
												<LinearProgress />
											</TableCell>
										</TableRow>
									)}
									{rowData.map((data) => {
										return (
											<>
												<TableRow
													hover
													selected={data.isSelected}
													key={data.id}
													onClick={_onClickRow(data.id)}
													sx={
														data.resultCode === 0
															? {
																	backgroundColor: 'warning.main',
																	'&.Mui-selected': {
																		backgroundColor: 'warning.main',
																	},
																	'&.MuiTableRow-hover:hover': {
																		backgroundColor: 'warning.light',
																	},
															  }
															: {}
													}>
													<TableCell align='center'>{data.id}</TableCell>
													<TableCell padding='none'>
														<TextField
															type='text'
															fullWidth
															size='small'
															sx={{
																'& .MuiOutlinedInput-root': {
																	'& fieldset': {
																		borderRadius: 0,
																		outline: 'none',
																		border: 0,
																	},
																},
															}}
															name='pcc-name'
															value={data.name}
															onChange={_onChangeText('name', data.id)}
															onPaste={_handlePaste('name', data.id)}
														/>
													</TableCell>
													<TableCell padding='none'>
														<TextField
															type='text'
															fullWidth
															size='small'
															sx={{
																'& .MuiOutlinedInput-root': {
																	'& fieldset': {
																		borderRadius: 0,
																		outline: 'none',
																		border: 0,
																	},
																},
															}}
															name='pcc-phone'
															value={data.phoneNo}
															onChange={_onChangeText('phoneNo', data.id)}
															onPaste={_handlePaste('phoneNo', data.id)}
														/>
													</TableCell>
													<TableCell>
														<TextField
															type='text'
															fullWidth
															size='small'
															sx={{
																'& .MuiOutlinedInput-root': {
																	'& fieldset': {
																		borderRadius: 0,
																		outline: 'none',
																		border: 0,
																	},
																},
															}}
															name='pcc'
															value={data.pcc}
															onChange={_onChangeText('pcc', data.id)}
															onPaste={_handlePaste('pcc', data.id)}
														/>
													</TableCell>
													<TableCell align='center'>{data.result}</TableCell>
													<TableCell align='center'>
														<IconButton aria-label='Check PCC for single line' onClick={_checkSingleInfo(data)}>
															<CheckIcon />
														</IconButton>
														<IconButton aria-label='Guess Resident number' onClick={_guessId(data)}>
															<LightbulbIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											</>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Container>
		</Paper>
	);
};
