import { useState, useEffect } from 'react';
import { Paper, Container, Box, Button, Checkbox } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { SearchCustomersInput } from '../../graphql_types/globalTypes';
import { Helmet } from 'react-helmet-async';
import { SEARCHCUSTOMERS_QUERY } from '../../graphql/queries';
import { SimpleSearchBar } from '../../components/simpleSearchBar';
import CustomTable, { TableColumnDef } from '../../components/customTable';
import { searchCustomersQuery, searchCustomersQueryVariables } from '../../graphql_types/searchCustomersQuery';
import { CustomerData, CustomerDialog } from '../../components/customerDialog';
import { DELETECUSTOMERS_MUTATION } from '../../graphql/mutations';
import { deleteCustomersMutation, deleteCustomersMutationVariables } from '../../graphql_types/deleteCustomersMutation';
import { useTranslation } from 'react-i18next';

export const ManageCustomers = () => {
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [customerDetail, setCustomerDetail] = useState<CustomerData>({
		name: '',
		representative: '',
		email: '',
		contact: '',
		delimiter: '',
		zipcode: '',
		address: '',
		detailAddress: '',
		isActive: true,
	});
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('Name'),
			datafield: 'name',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('E-mail'),
			datafield: 'email',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Rep.'),
			datafield: 'representative',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Contact'),
			datafield: 'contact',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('isActive'),
			datafield: 'isActvie',
			customValue: (row: any) => {
				return <Checkbox checked={row.isActive} />;
			},
		},
	];

	const onCompleted = (data: searchCustomersQuery) => {
		const {
			searchCustomers: { success, customers },
		} = data;

		if (success) {
			//console.log(customers);
			setTableRowData(customers as any);
		} else {
			enqueueSnackbar(t(`Cannot search customer...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onDeleteCompleted = (data: deleteCustomersMutation) => {
		const {
			deleteCustomers: { success },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Customer Deleted`), { variant: 'error', autoHideDuration: 3000 });
			refetch();
		} else {
			enqueueSnackbar(t(`Cannot delete Customers...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [searchCustomersQuery, { loading, refetch }] = useLazyQuery<searchCustomersQuery, searchCustomersQueryVariables>(SEARCHCUSTOMERS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [deleteCustomersMutation] = useMutation<deleteCustomersMutation, deleteCustomersMutationVariables>(DELETECUSTOMERS_MUTATION, { onCompleted: onDeleteCompleted });

	const _onSearch = (searchData: SearchCustomersInput) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: searchCustomersQueryVariables = {
			searchCustomersInput: { ...searchData },
		};
		searchCustomersQuery({ variables });
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		setCheckedVal(checkedData);
	};

	useEffect(() => {
		const tempVariables: searchCustomersQueryVariables = {
			searchCustomersInput: {
				name: '',
			},
		};
		searchCustomersQuery({ variables: tempVariables });
	}, [searchCustomersQuery]);

	const addCustomer = () => {
		setOpenDialog(true);
	};

	const removeCustomer = () => {
		const checkedIds = checkedVal.map((value) => value.id);
		const variables: deleteCustomersMutationVariables = {
			deleteCustomersInput: checkedIds,
		};
		deleteCustomersMutation({ variables });
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTimeout(() => {
			setCustomerDetail({
				name: '',
				representative: '',
				email: '',
				contact: '',
				delimiter: '',
				zipcode: '',
				address: '',
				detailAddress: '',
				isActive: true,
			});
		}, 500);
	};

	const getDetailData = (value: any) => {
		//console.log(value);
		setCustomerDetail(value);
	};

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Customer Management')} | PMSK</title>
			</Helmet>
			<SimpleSearchBar onSearch={_onSearch} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
						<Button size='small' variant='outlined' color='error' onClick={removeCustomer}>
							<RemoveIcon fontSize='small' />
							{t('Remove')}
						</Button>
						<Button size='small' variant='outlined' color='primary' onClick={addCustomer}>
							<AddIcon fontSize='small' />
							{t('Add')}
						</Button>
					</Box>
					<CustomTable
						TableData={tableRowData}
						TableColumn={columnDef}
						loading={loading}
						checkbox
						showTotal
						showDetail
						pagination
						height={550}
						setCheckedValues={getCheckedVal}
						detailDialog={{ setDetailData: getDetailData, openDialog: () => setOpenDialog(true) }}
					/>
					<CustomerDialog open={openDialog} close={handleCloseDialog} refetch={refetch} editData={customerDetail} />
				</Box>
			</Container>
		</Paper>
	);
};
