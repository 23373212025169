import { useState } from 'react';
import { Box, IconButton, Card, CardContent, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, TextField, InputAdornment, Stack, Select, MenuItem } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { OrderCategory } from '../graphql_types/globalTypes';
import { COURIERS_INFO_QUERY, CUSTOMERS_INFO_QUERY } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { couriersInfoQuery } from '../graphql_types/couriersInfoQuery';
import { useTranslation } from 'react-i18next';
import { customersInfoQuery } from '../graphql_types/customersInfoQuery';

interface OrderSearchBarProp {
	onSearch: (data: any) => (e: React.FormEvent<HTMLFormElement>) => void;
}

export interface SentOrderSearchDataProp {
	category?: OrderCategory | 'All';
	courier?: number | 'All';
	customer?: number | 'All';
	sentFrom: Date | null;
	sentTo: Date | null;
	isPccError: boolean;
	name: string;
}

export const SentOrderSearchBar: React.FC<OrderSearchBarProp> = ({ onSearch, ...rest }) => {
	const toDay = new Date();
	const fromDay = new Date();
	fromDay.setDate(toDay.getDate() - 7);
	const [searchData, setSearchData] = useState<SentOrderSearchDataProp>({
		category: 'All',
		courier: 'All',
		customer: 'All',
		sentFrom: new Date(fromDay.getFullYear(), fromDay.getMonth(), fromDay.getDate(), 0, 0, 0),
		sentTo: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59),
		isPccError: false,
		name: '',
	});
	const { t } = useTranslation();

	const { data } = useQuery<couriersInfoQuery>(COURIERS_INFO_QUERY);
	const { data:fromData } = useQuery<customersInfoQuery>(CUSTOMERS_INFO_QUERY);

	return (
		<Box {...rest}>
			<Box>
				<Card>
				<CardContent
						component='form'
						sx={{ padding: '24px', paddingBottom: '24px' }}
						onSubmit={onSearch({ ...searchData, 
							category: searchData.category === 'All' ? null : searchData.category, 
							courier: searchData.courier === 'All' ? null : searchData.courier,
							customer: searchData.customer === 'All' ? null : searchData.customer 
							})}>
								<Box>
							<FormControl component='fieldset' sx={{ marginRight: 5 }}>
								<FormLabel component='legend'>{t('Category')}</FormLabel>
								<RadioGroup
									sx={{ mt: 2 }}
									row
									aria-label='Category'
									name='row-radio-buttons-group'
									value={searchData.category}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, category: newValue });
									}}>
									<FormControlLabel value='All' control={<Radio />} label={t('All')} />
									<FormControlLabel value={OrderCategory.OpenMarket} control={<Radio />} label={t('Open Market')} />
									<FormControlLabel value={OrderCategory.B2B} control={<Radio />} label={t('B2B')} />
								</RadioGroup>
							</FormControl>
							<FormControl sx={{ width: '120px'}}>
								<FormLabel component='legend'>{t('From')}</FormLabel>
								<Select
									sx={{ marginTop: 1 }}
									id='order-from-select'
									value={searchData.customer}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, customer: newValue });
									}}>
									<MenuItem value='All'>{t('All')}</MenuItem>
									{fromData?.customersInfo?.customers?.map((customer: any) => {
										if(customer.isActive){
											return (
												<MenuItem value={customer.id} key={customer.id}>
													{customer.name}
												</MenuItem>
											);
										}
									})}
								</Select>
							</FormControl>
							<FormControl sx={{ width: '120px', marginLeft: '10px' }}>
								<FormLabel component='legend'>{t('Courier')}</FormLabel>
								<Select
									sx={{ marginTop: 1 }}
									id='order-state-select'
									value={searchData.courier}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, courier: newValue });
									}}>
									<MenuItem value='All'>{t('All')}</MenuItem>
									{data?.couriersInfo?.couriers?.map((courier: any) => {
											return (
												<MenuItem value={courier.id} key={courier.id}>
													{courier.name}
												</MenuItem>
											);
									})}
								</Select>
							</FormControl>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<FormLabel component='legend'>{t('Sent Date')}</FormLabel>
								<Stack spacing={3} direction='row' sx={{ marginTop: 1, marginBottom: 1 }}>
									<DatePicker
										label='From'
										openTo='day'
										views={['year', 'month', 'day']}
										value={searchData.sentFrom}
										onChange={(newValue: Date | null) => {
											newValue?.setHours(0, 0, 0);
											setSearchData({ ...searchData, sentFrom: newValue });
										}}
										renderInput={(params: any) => <TextField {...params} />}
									/>
									<DatePicker
										label='To'
										openTo='day'
										views={['year', 'month', 'day']}
										value={searchData.sentTo}
										onChange={(newValue: Date | null) => {
											newValue?.setHours(23, 59, 59);
											setSearchData({ ...searchData, sentTo: newValue });
										}}
										renderInput={(params: any) => <TextField {...params} />}
									/>
								</Stack>
							</LocalizationProvider>
							<TextField
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton sx={{ p: '10px' }} aria-label='search' type='submit'>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
								value={searchData.name}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setSearchData({ ...searchData, name: newValue });
								}}
								placeholder={t('Search')}
								variant='outlined'
							/>
						</Box>
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
};
