import { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	TextField,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
	CircularProgress,
	Link,
	FormLabel,
	Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CheckOutlined as CheckIcon, CloseOutlined as FailIcon, QuestionMarkOutlined as QuestionIcon } from '@mui/icons-material';
import { Add as AddIcon, Remove as RemoveIcon, EditOutlined as EditIcon } from '@mui/icons-material';
import { OrderCategory, OrderProductInputType, OrderState, PccType, UserRole } from '../graphql_types/globalTypes';
import { useMutation, useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CHECKPCC_QUERY, COURIERS_INFO_QUERY, SEARCHORDERDETAIL_QUERY } from '../graphql/queries';
import { checkPccQuery, checkPccQueryVariables } from '../graphql_types/checkPccQuery';
import CustomOrderTable, { TableColumnDef } from './customOrderTable';
import AddOrderProductDialog from './addOrderProductDialog';
import { useSnackbar } from 'notistack';
import { UPDATEORDERS_MUTATION } from '../graphql/mutations';
import { userInfoVar } from '../apollo';
import { OrderStateChip } from './orderStateChip';
import { couriersInfoQuery } from '../graphql_types/couriersInfoQuery';
import { PhoneTextField } from './phoneTextfield';
import { useTranslation } from 'react-i18next';

interface OrderDetailDialogProps {
	open: boolean;
	close: () => void;
	//orderData: OrderData;
	orderId: number;
	refetch?: () => void;
	editDetailInfo?: boolean;
}

export interface OrderData {
	id?: number;
	orderNo?: string;
	referenceNo?: string;
	category?: string;
	from?: string;
	recieverName?: string;
	recieverPhone?: string;
	buyerName?: string;
	buyerPhone?: string;
	PCC?: string;
	pccName?: string;
	pccPhone?: string;
	pccResult?: boolean;
	pccType?: PccType;
	postal?: string;
	addr?: string;
	addrDetail?: string;
	shippingNo?: string;
	msg?: string;
	comment?: string;
	orderProducts?: OrderProductData[];
	orderState?: OrderState;
	customer?: {
		name: string;
		delimiter: string;
	};
	orderedAt?: Date;
}

export interface OrderProductData {
	productName?: string;
	alias?: string;
	barcode?: string;
	reportableUnitPrice?: number;
	brand?: string;
	productWeight?: number;
	option?: string;
	qty?: number;
	productQty?: number;
	unitPrice?: number;
}

const initOrderData = {
	id: 0,
	orderNo: '',
	category: '',
	from: '',
	referenceNo: '',
	recieverName: '',
	recieverPhone: '',
	buyerName: '',
	buyerPhone: '',
	PCC: '',
	pccName: '',
	pccResult: false,
	pccType: PccType.PCC,
	pccPhone: '',
	postal: '',
	addr: '',
	addrDetail: '',
	shippingNo: '',
	msg: '',
	comment: '',
	courierId: 0,
	orderProducts: [],
};

const OrderDetailDialog: React.FC<OrderDetailDialogProps> = ({ open = false, close, orderId, refetch, editDetailInfo = false }) => {
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [afterOrder, setAfterOrder] = useState<any>(initOrderData);
	const [checkMsg, setCheckMsg] = useState<string | null>(null);
	const [editProduct, setEditProduct] = useState<OrderProductData | undefined>(undefined);
	const userInfo = useReactiveVar(userInfoVar);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const onCompleted = (data: any) => {
		const {
			searchOrderDetail: { success, order },
		} = data;

		//console.log(order);

		if (success) {
			setAfterOrder({ ...order, courierId: order.courier?.id || 0 });
			setCheckMsg(null);
		} else {
		}
	};

	const onUpdateCompleted = (data: any) => {
		const {
			updateOrders: { success },
		} = data;

		if (success) {
			enqueueSnackbar('Order Updated', { variant: 'success', autoHideDuration: 3000 });
			if (refetch) refetch();
			setAfterOrder(initOrderData);
			close();
		} else {
		}
	};

	const onQueryCompleted = (data: checkPccQuery) => {
		const {
			checkPCC: { success, checkResults },
		} = data;

		if (success && checkResults) {
			const tmp = { ...afterOrder };
			if (afterOrder.pccType === PccType.RRN) {
				tmp.PCC = checkResults[0].result;
			}
			setAfterOrder({ ...tmp, pccResult: checkResults[0].resultCode === 1, pccResultMsg:checkResults[0].errorMsg });
			setCheckMsg(checkResults[0].errorMsg);
		} else {
		}
	};

	const { loading } = useQuery(SEARCHORDERDETAIL_QUERY, { variables: { searchOrderDetailInput: { orderId } }, onCompleted, fetchPolicy: 'no-cache' });
	const [updateOrdersMutation] = useMutation(UPDATEORDERS_MUTATION, { onCompleted: onUpdateCompleted });
	const { data } = useQuery<couriersInfoQuery>(COURIERS_INFO_QUERY);
	const [checkPccQuery, { loading: pccCheckLoading }] = useLazyQuery<checkPccQuery, checkPccQueryVariables>(CHECKPCC_QUERY, {
		onCompleted: onQueryCompleted,
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
	});

	const pccCheck = () => {
		const variables: checkPccQueryVariables = { pccInfo: [{ id: afterOrder.id, name: afterOrder.pccName, pccType: afterOrder.pccType, pcc: afterOrder.PCC, phoneNo: afterOrder.pccPhone }] };
		checkPccQuery({ variables });
	};

	const columnDef: TableColumnDef[] = [
		{
			name: t('Product Name'),
			datafield: 'productName',
		},
		{
			name: t('Option'),
			datafield: 'option',
		},
		{
			name: t('Qty'),
			datafield: 'qty',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Subtotal Qty'),
			datafield: 'subTotalQty',
			customValue: (row: any) => {
				return `${row.qty * row.productQty}`;
			},
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Unit Price'),
			datafield: 'reportableUnitPrice',
			headerAlign: 'center',
			align: 'center',
		},
		// {
		// 	name: "Weight",
		// 	datafield: "productWeight",
		// },
	];

	const getCheckedVal = (values: any[]) => {
		setCheckedVal(values);
	};

	const getOrderProduct = (values: any) => {
		let orderProducts: any = [];
		if (afterOrder.orderProducts) {
			orderProducts = [...afterOrder.orderProducts];
		}
		if (orderProducts) {
			if (editProduct) orderProducts.splice(checkedVal[0], 1, values);
			else orderProducts.push(values);
			setAfterOrder({ ...afterOrder, orderProducts });
		}
	};

	const addOrderProduct = () => {
		setEditProduct(undefined);
		setOpenDialog(true);
	};

	const editOrderProduct = () => {
		if (checkedVal.length !== 1) {
			enqueueSnackbar(t('Edit Product must check 1 item'), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		setEditProduct(afterOrder.orderProducts?.find((val: any, id: any) => id === checkedVal[0]));
		setOpenDialog(true);
	};

	const removeOrderProduct = () => {
		if (!checkedVal.length) {
			enqueueSnackbar(t('Please check the product to remove...'), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		const removedProducts = afterOrder.orderProducts?.filter((val: any, id: any) => !checkedVal.includes(id));
		setAfterOrder({ ...afterOrder, orderProducts: removedProducts });
		setCheckedVal([]);
	};

	const onSave = () => {
		if (afterOrder.orderState === OrderState.Sent) {
			enqueueSnackbar(t('Cannot change any information which the order was sent'), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}

		if (afterOrder.shippingNo && afterOrder.courierId === 0) {
			enqueueSnackbar(t('Please set a courier'), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}

		if (afterOrder.shippingNo === '' && afterOrder.courierId !== 0) {
			enqueueSnackbar(t('Please set a shipping No.'), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		let withoutCustomer = afterOrder;
		delete withoutCustomer['customer'];
		delete withoutCustomer['courier'];

		const variables = { updateOrdersInput: [withoutCustomer] };
		updateOrdersMutation({ variables });
	};

	const onClose = () => {
		setAfterOrder(initOrderData);
		close();
	};

	return (
		<Dialog open={open} onClose={close} scroll='paper' maxWidth='md' aria-labelledby='order-detail-dialog-title' aria-describedby='order-detail-dialog-description'>
			{loading && (
				<DialogContent>
					<CircularProgress />
				</DialogContent>
			)}
			{!loading && (
				<>
					<DialogTitle id='order-detail-dialog-title'>
						{t('Order Detail')} {afterOrder.orderNo && `(Order No. : ${afterOrder.orderNo})`}
						<Box sx={{ float: 'right' }}>
							<OrderStateChip state={afterOrder.orderState as OrderState} size='small' />
						</Box>
					</DialogTitle>
					<PerfectScrollbar>
						<DialogContent dividers>
							<PerfectScrollbar>
								<Grid container spacing={2} sx={{ marginTop: 1 }}>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<InputLabel id='select-label-category'>{t('Category')}</InputLabel>
											<Select value={afterOrder.category} label={t('Category')} labelId='select-label-category' inputProps={{ readOnly: true }}>
												{Object.keys(OrderCategory).map((category, idx) => (
													<MenuItem key={idx} value={category}>
														{category}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2}>
										<FormControl fullWidth>
											<InputLabel id='select-label-courier'>{t('Courier')}</InputLabel>
											<Select
												id='courier-select'
												labelId='select-label-courier'
												label={t('Courier')}
												value={afterOrder.courierId || 0}
												onChange={(e: any) => {
													const {
														target: { value: newValue },
													} = e;
													setAfterOrder({ ...afterOrder, courierId: +newValue });
												}}>
												<MenuItem value={0}>- Select -</MenuItem>
												{data?.couriersInfo?.couriers?.map((courier: any) => {
														return (
															<MenuItem value={courier.id} key={courier.id}>
																{courier.name}
															</MenuItem>
														);
												})}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										{afterOrder.orderState === OrderState.Sent && (
											<FormControl sx={{ padding: '15px 14px', position: 'relative', border: '1px solid #E6E8F0', borderRadius: '8px' }} variant='standard' fullWidth>
												<FormLabel
													sx={{ position: 'absolute', top: 0, left: 0, color: '#65748B', backgroundColor: '#FFFFFF', padding: '0 5px', fontSize: '0.73rem', transform: 'translate(10px, -9px)' }}>
													Shipping No.
												</FormLabel>
												<Link target='_blank' href={`${data?.couriersInfo?.couriers?.filter((courier) => courier.id === afterOrder.courierId)[0].trackUrl}${afterOrder.shippingNo}`}>
													{afterOrder.shippingNo}
												</Link>
											</FormControl>
										)}

										{afterOrder.orderState !== OrderState.Sent && (
											<TextField
												label={t('Shipping No.')}
												value={afterOrder.shippingNo}
												color='primary'
												fullWidth
												onChange={(e: any) => {
													const {
														target: { value: newValue },
													} = e;
													setAfterOrder({ ...afterOrder, shippingNo: newValue });
												}}
											/>
										)}
									</Grid>
									<Grid item xs={6}>
										<TextField label={t('Order From')} value={afterOrder.from} color='primary' inputProps={{ readOnly: true }} />
									</Grid>
									<Grid item xs={6}>
										<TextField
											label={t('Reference No.')}
											value={afterOrder.referenceNo}
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, referenceNo: newValue });
											}}
											fullWidth
											color='primary'
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											label={t('Buyer Name')}
											value={afterOrder.buyerName}
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, buyerName: newValue });
											}}
											fullWidth
											color='primary'
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											label={t('Reciever Name')}
											value={afterOrder.recieverName}
											fullWidth
											color='primary'
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, recieverName: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<PhoneTextField
											label={t('Buyer Phone')}
											value={afterOrder.buyerPhone}
											fullWidth
											color='primary'
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, buyerPhone: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<PhoneTextField
											label={t('Reciever Phone')}
											value={afterOrder.recieverPhone}
											fullWidth
											color='primary'
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, recieverPhone: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={6} md={3}>
										<FormControl fullWidth focused>
											<InputLabel id='select-label-pcctype'>{t('PCC Type')}</InputLabel>
											<Select
												value={afterOrder.pccType}
												label={t('PCC Type')}
												color={afterOrder.pccResult ? 'primary' : 'warning'}
												labelId='select-label-pcctype'
												onChange={(e: any) => {
													const {
														target: { value: newValue },
													} = e;
													//setPccInfo({ ...pccInfo, pccType: newValue });
													setAfterOrder({ ...afterOrder, pccType: newValue });
												}}>
												{Object.keys(PccType).map((pccType, idx) => (
													<MenuItem key={idx} value={pccType}>
														{t(pccType)}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={3}>
										<LoadingButton
											sx={{ mt: 0.5 }}
											loading={pccCheckLoading}
											loadingPosition='start'
											variant='outlined'
											onClick={pccCheck}
											color={afterOrder.pccResult === null ? undefined : afterOrder.pccResult ? 'success' : 'error'}
											startIcon={
												afterOrder.pccResult === null ? (
													<QuestionIcon fontSize='small' />
												) : afterOrder.pccResult ? (
													<CheckIcon color='success' fontSize='small' />
												) : (
													<FailIcon color='error' fontSize='small' />
												)
											}>
											{t('Check')}
										</LoadingButton>
										{checkMsg && (
											<Typography sx={{ display: 'block', fontSize: '0.65rem' }} color='error' variant='caption'>
												{checkMsg}
											</Typography>
										)}
									</Grid>
									<Grid item xs={3} md={6}></Grid>
									<Grid item xs={4}>
										<TextField
											label={t('PCC Name')}
											value={afterOrder.pccName}
											fullWidth
											color={afterOrder.pccResult ? 'primary' : 'warning'}
											focused
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												// setPccInfo({ ...pccInfo, name: newValue });
												setAfterOrder({ ...afterOrder, pccName: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<PhoneTextField
											label={t('PCC Phone')}
											value={afterOrder.pccPhone}
											fullWidth
											color={afterOrder.pccResult ? 'primary' : 'warning'}
											focused
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												// setPccInfo({ ...pccInfo, name: newValue });
												setAfterOrder({ ...afterOrder, pccPhone: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											label={t('PCC')}
											value={afterOrder.PCC}
											fullWidth
											color={afterOrder.pccResult ? 'primary' : 'warning'}
											focused
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												// setPccInfo({ ...pccInfo, pcc: newValue });
												setAfterOrder({ ...afterOrder, PCC: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={4} md={3}>
										<TextField
											label={t('Postal Code')}
											value={afterOrder.postal}
											color='primary'
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, postal: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={8} md={9}>
										<TextField
											label={t('Address')}
											value={afterOrder.addr}
											fullWidth
											color='primary'
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, addr: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label={t('Address Detail')}
											value={afterOrder.addrDetail}
											fullWidth
											color='primary'
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, addrDetail: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											label={t('Memo')}
											fullWidth
											value={afterOrder.msg}
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setAfterOrder({ ...afterOrder, msg: newValue });
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1}>
											<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography variant='h6' component='div'>
													{t('Order Items')}
												</Typography>
											</Grid>
											<Grid item xs={6} sx={{ textAlign: 'right' }}>
												<IconButton aria-label='Add Product' color='primary' onClick={addOrderProduct}>
													<AddIcon />
												</IconButton>
												<IconButton aria-label='Remove Product' color='error' onClick={removeOrderProduct}>
													<RemoveIcon />
												</IconButton>
												<IconButton aria-label='Edit Product' color='secondary' onClick={editOrderProduct}>
													<EditIcon />
												</IconButton>
											</Grid>
											<Grid item xs={12}>
												<CustomOrderTable TableData={afterOrder.orderProducts ? afterOrder.orderProducts : []} TableColumn={columnDef} checkbox setCheckedValues={getCheckedVal} rowClick />
											</Grid>
											<Grid item xs={12}>
												<TextField
													label={t('Comment(Not print on shipping Label)')}
													fullWidth
													value={afterOrder.comment}
													onChange={(e: any) => {
														const {
															target: { value: newValue },
														} = e;
														setAfterOrder({ ...afterOrder, comment: newValue });
													}}
												/>
											</Grid>
										</Grid>
										<AddOrderProductDialog open={openDialog} close={() => setOpenDialog(false)} setOrderProduct={getOrderProduct} orderProduct={editProduct as OrderProductInputType} />
									</Grid>
								</Grid>
							</PerfectScrollbar>
						</DialogContent>
					</PerfectScrollbar>
					<DialogActions>
						{editDetailInfo && afterOrder.orderState !== OrderState.Sent && userInfo.role !== UserRole.Staff && <Button onClick={onSave}>{t('Save')}</Button>}
						<Button onClick={onClose} color='error'>
							{t('Close')}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

export default OrderDetailDialog;
