import { ApolloClient, createHttpLink, InMemoryCache, makeVar, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { APOLLO_URI, LS_LANGUAGE, LS_TOKEN, LANGUAGES } from './constant';
import { onError } from '@apollo/client/link/error';
import { UserRole } from './graphql_types/globalTypes';

const token = localStorage.getItem(LS_TOKEN);
const lang = localStorage.getItem(LS_LANGUAGE) || 'en';

export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);
export const languageVar = makeVar(lang);
export const userInfoVar = makeVar({ email: '', firstName: '', lastName: '', role: UserRole.Staff });

const httpLink = createHttpLink({
	uri: APOLLO_URI,
});

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			Authorization: authTokenVar() ? `Bearer ${authTokenVar()}` : '',
		},
	};
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
			if (message === 'Token Expired') {
				localStorage.removeItem(LS_TOKEN);
				authTokenVar('');
				isLoggedInVar(false);
			}
		});
	}

	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cleanTypeName = new ApolloLink((operation, forward) => {
	if (operation.variables) {
		const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
		operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
	}
	return forward(operation).map((data) => {
		return data;
	});
});

export let client = new ApolloClient({
	//link: authLink.concat(httpLink),
	link: from([cleanTypeName, errorLink, authLink, httpLink]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					isLoggedIn: {
						read() {
							return isLoggedInVar();
						},
					},
					token: {
						read() {
							return authTokenVar();
						},
					},
					me: {
						read() {
							return userInfoVar();
						},
					},
				},
			},
		},
	}),
});
