import { Paper, Grid, TextField, Typography, Card, CardContent, Button, LinearProgress, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EXPIRYDATE_BARCODE_QUERY } from '../../graphql/queries';
import { ADD_EXPIREDITEM } from '../../graphql/mutations';
import BarcodeScanner from '../../components/barcodeScanner';
import { SearchOffOutlined as SearchOffIcon } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export interface ExpiredInputType {
	productName: string;
	brand: string;
	referenceCode: string;
	barcode: string;
	quantity: number;
	expiryDate: Date;
	comment?: string | null;
}

interface ExpiredScanProps {
	expiredProducts: ExpiredInputType[];
	setexpiredProducts: React.Dispatch<React.SetStateAction<ExpiredInputType[]>>;
	refetchQueryTable: () => void;
}

export function ExpiredScan({expiredProducts, setexpiredProducts, refetchQueryTable}: ExpiredScanProps) {
	const [barcode, setBarcode] = useState('');
	const [searched, setSearched] = useState(false);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [scanning, setScanning] = useState(false);

	const onCompleted = (data: any) => {
		if (data) {
			const {
				getExpiredStockByBarcode: { success, products },
			} = data;
			if (success) {
				setexpiredProducts(products)
			}
		}
		setBarcode('');
		setSearched(true);
	};

	const [getStockQuery, { loading }] = useLazyQuery(EXPIRYDATE_BARCODE_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
		if (e.type === 'blur' && barcode !== '' && barcode.length > 4 ) {
			setSearched(false);
			getStockQuery({ variables: { getStockInput: { barcode } } });
		} else if (e.type === 'blur' && barcode !== '' && barcode.length < 5) {
			enqueueSnackbar(t(`'Barcode needs to contain at least 5 characters`), { variant: 'warning', autoHideDuration: 3000 });
		}
	};

	const handleKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && barcode !== '' && barcode.length > 4) {
			setSearched(false);
			getStockQuery({ variables: { getStockInput: { barcode } } });
		} else if (e.key === 'Enter' && barcode !== '' && barcode.length < 5) {
			enqueueSnackbar(t(`'Barcode needs to contain at least 5 characters`), { variant: 'warning', autoHideDuration: 3000 });
		}
	};  

	const checkValidation = (stockInfo: ExpiredInputType): Boolean => {
		if (!('quantity' in stockInfo)) {
			enqueueSnackbar(t(`'Quantity' field is required. Please fill 'Quantity'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!('expiryDate' in stockInfo)) {
			enqueueSnackbar(t(`'ExpiredDate' field is required. Please fill 'ExpiredDate'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		return true;
	};

	const [addExpiredProductMutation] = useMutation(ADD_EXPIREDITEM);

	const onCompletedStockUpdate = (data: any) => {
		const {
			addExpiredItem: { success },
		} = data;
		if (success) {
			refetchQueryTable();
			enqueueSnackbar(t(`Stock updated successfully`), { variant: 'success', autoHideDuration: 3000 });
			setexpiredProducts([]);
			setSearched(false);
		} else {
			enqueueSnackbar(t(`Cannot update stock..😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const addExpiredProduct = (stockInfo: ExpiredInputType) => {
		if (checkValidation(stockInfo)) {
			addExpiredProductMutation({
				variables: {expiredInputType: stockInfo},
				onCompleted: onCompletedStockUpdate,
			});
		}
	}

	const scanBarcode = () => {
		setScanning(!scanning);
	};

	useEffect(() => {
		if (barcode || barcode !== '') {
			setSearched(false);
			getStockQuery({ variables: { getStockInput: { barcode } } });
		}
		// eslint-disable-next-line 
	}, [scanning]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Grid container spacing={2} sx={{ padding: 1 }}>
				<Grid container spacing={1} sx={{ marginTop: 1 }}>
					{scanning && (
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<BarcodeScanner scanning={scanBarcode} setBarcode={setBarcode} />
						</Grid>
					)}
					<Grid item xs={12} xl={11}>
						<TextField
							label={t('Barcode...')}
							fullWidth
							value={barcode}
							onBlur={handleBlur}
							onKeyUp={handleKeyUp}
							onChange={(e) => {
								const {
									target: { value: newValue },
								} = e;
								setBarcode(newValue);
							}}></TextField>
						{loading && <LinearProgress sx={{ marginX: 1 }} />}
					</Grid>
					<Grid item xs={12} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Button fullWidth onClick={scanBarcode} variant='outlined'>
							{scanning ? 'Close' : '📷Scan'}
						</Button>
					</Grid>
				</Grid>
				{expiredProducts.length === 0 && searched && (
					<Grid item xs={12}>
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
							<SearchOffIcon sx={{ width: 120, height: 120, opacity: '0.6' }} />
							<Box sx={{ mt: 1 }}>{t('No Data')}</Box>
						</Box>
					</Grid>
				)}
				{expiredProducts.length !== 0 &&
					expiredProducts.map((stockInfo: any, index: number) => {
						return (
							<Grid item xs={12} key={index}>
								<Card>
									<CardContent>
										<Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-around' }}>
											<Grid item xs={12} md={3.5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
												<Typography gutterBottom variant='h5' component='div' sx={{ fontSize: { xs: '1.25rem'} }}>
													{stockInfo.productName}
												</Typography>
												<Grid container spacing={1}>
													<Grid item xs={6} md={12}>
														<Typography variant='subtitle1' color='text.secondary'>
															Brand: {stockInfo.brand}
														</Typography>
													</Grid>
													<Grid item xs={6} md={12}>
														<Typography variant='subtitle1' color='text.secondary'>
															Ref#: {stockInfo.referenceCode}
														</Typography>
													</Grid>
												</Grid>

											</Grid>
											<Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
												<Grid container spacing={1} direction='row' sx={{ marginTop: 1, marginBottom: 1, width: 1 }}>
													<Grid item md={7} xs={6}>
														<TextField
															label={t('Quantity')}
															fullWidth
															value={stockInfo.quantity}
															onChange={(e) => {
																const {
																	target: { value: newValue },
																} = e;
																let parsedValue: number | null = null;
																if (!isNaN(parseInt(newValue, 10)) && newValue.trim() !== '') {
																	parsedValue = parseInt(newValue, 10);
																}
																const p = expiredProducts.map((product: any) => {
																	if (product.barcode === stockInfo.barcode) return { ...product, quantity: parsedValue };
																	return product;
																});
																setexpiredProducts(p);
															  }}
															InputLabelProps={{ style: { marginBottom: '10px' } }}
														/>
													</Grid>
													<Grid item md={5} xs={6} sx={{ width: 1}}>
														<Box sx={{ width: 1}}>
															<LocalizationProvider dateAdapter={AdapterDateFns}>
																<DatePicker
																	label={t('ExpiredDate')}
																	openTo='year'
																	views={['year', 'month']}
																	value={stockInfo.expiryDate}
																	onChange={(newValue: Date | null) => {
																		if (newValue) {
																			newValue?.setHours(0, 0, 0);
																			const p: any = expiredProducts.map((product: any) => {
																				if (product.barcode === stockInfo.barcode) return { ...product, expiryDate: new Date(newValue) };
																				return product;
																			})
																			setexpiredProducts(p);
																		}
																	}}
																	renderInput={(params: any) => <TextField fullWidth {...params} />}
																/>
															</LocalizationProvider>
														</Box>
													</Grid>
												</Grid>
												<Grid container>
													<Grid item md={12} xs={12}>
															<TextField
																label={t('Note')}
																fullWidth
																onBlur={(e: any) => {
																	const {
																		target: { value: newValue },
																	} = e;
																	const p: any = expiredProducts.map((product: any) => {
																		if (product.barcode === stockInfo.barcode) {
																			return { ...product, comment: newValue };

																		} 
																		return product;
																	})
																	setexpiredProducts(p);
																}}
															/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
												<LoadingButton onClick={() => addExpiredProduct(stockInfo)} variant='outlined' sx={{ height: '100%', width: '100%' }}>
													Save
												</LoadingButton>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						);
					})}
			</Grid>
		</Paper>
	);
}

