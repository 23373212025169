import { useMutation, useReactiveVar } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Paper, Container, Typography, TextField, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { EDITPROFILE_MUTATION } from "../graphql/mutations";
import { userInfoVar } from "../apollo";

interface IEditProfileForm {
	email: string;
	password: string;
	confirm_password: string;
	firstName: string;
	lastName: string;
}

export const EditProfile = () => {
	const { enqueueSnackbar } = useSnackbar();
	const userInfo = useReactiveVar(userInfoVar);

	const {
		register,
		handleSubmit,
		getValues,
		watch,
		formState: { errors },
	} = useForm<IEditProfileForm>({
		mode: "onChange",
		defaultValues: {
			email: userInfo.email,
			firstName: userInfo.firstName,
			lastName: userInfo.lastName,
		},
	});

	const { email, password, firstName, lastName } = watch();
	const variables = {
		editProfileInput: { email, password, firstName, lastName },
	};

	const onCompleted = (data: any) => {
		const {
			editProfile: { success },
		} = data;

		if (success) {
			userInfoVar({ ...userInfo, email, firstName, lastName });
			enqueueSnackbar(`Account edited!`, { variant: "success", autoHideDuration: 3000 });
		}
	};

	const [editProfileMutation, { data: editProfileMutationResult, loading }] = useMutation(EDITPROFILE_MUTATION, {
		onCompleted,
		variables,
	});

	const _onSubmit = () => {
		editProfileMutation();
	};

	return (
		<Paper elevation={0} sx={{ width: "100%", backgroundColor: "transparent" }}>
			<Helmet>
				<title>Edit Profile | PMSK</title>
			</Helmet>
			<Container sx={{ p: 3 }} maxWidth='md'>
				<Typography variant='h3' sx={{ textAlign: "center", mb: 3 }}>
					Edit Profile
				</Typography>
				<Grid component='form' onSubmit={handleSubmit(_onSubmit)} container spacing={2}>
					<Grid item xs={12}>
						<TextField
							{...register("email", {
								required: "Email is required",
								pattern: {
									value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: "Email address invalid",
								},
							})}
							required
							fullWidth
							type='email'
							placeholder='Email'
						/>
						{errors.email && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: "error.main" }}>
								{errors.email.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField {...register("password", { required: "Password is required" })} required type='password' placeholder='Password' fullWidth />
						{errors.password && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: "error.main" }}>
								{errors.password.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField
							{...register("confirm_password", { required: "Password is required", validate: (value) => value === getValues().password })}
							required
							type='password'
							fullWidth
							placeholder='Confirm Password'
						/>
						{errors.confirm_password && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: "error.main" }}>
								{errors.confirm_password.message}
							</Typography>
						)}
						{errors.confirm_password && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: "error.main" }}>
								Password not matched
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField {...register("firstName", { required: "First Name is required" })} required type='text' placeholder='First Name' fullWidth />
						{errors.firstName && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: "error.main" }}>
								{errors.firstName.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField {...register("lastName", { required: "Last Name is required" })} required type='text' placeholder='Last Name' fullWidth />
						{errors.lastName && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: "error.main" }}>
								{errors.lastName.message}
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						<LoadingButton variant='contained' loading={loading} fullWidth type='submit'>
							Edit Profile
						</LoadingButton>
						{editProfileMutationResult?.editProfile.error && (
							<Typography variant='caption' display='block' gutterBottom sx={{ color: "error.main" }}>
								{editProfileMutationResult.editProfile.error}
							</Typography>
						)}
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
};
