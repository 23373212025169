import { forwardRef, RefCallback } from 'react';
import { IMaskInput } from 'react-imask';
import { TextField } from '@mui/material';
interface PhoneTextFiledProps {
	label?: string;
	variant?: 'standard' | 'outlined' | 'filled';
	required?: boolean;
	fullWidth: boolean;
	value?: string | null;
	focused?: boolean;
	color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
	const { onChange, ...other } = props;

	return (
		<IMaskInput
			{...other}
			mask={[{ mask: '000-000-0000' }, { mask: '000-0000-0000' }, { mask: '+0 000-000-0000' }, { mask: '+00 00-0000-0000' }]}
			inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
			onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
		/>
	);
});

export const PhoneTextField: React.FC<PhoneTextFiledProps> = ({ label, variant = 'outlined', required = false, fullWidth, onChange, value = '', focused, color, ...rest }) => {
	return <TextField value={value} onChange={onChange} label={label} variant={variant} fullWidth={fullWidth} focused={focused} color={color} InputProps={{ inputComponent: TextMaskCustom as any }} />;
};
