import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { authTokenVar, isLoggedInVar } from '../apollo';
import { LS_TOKEN } from '../constant';
import { loginMutation, loginMutationVariables } from '../graphql_types/loginMutation';
import { Container, Box, Typography, TextField, CssBaseline, Link, IconButton, InputAdornment, Stack, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { LOGIN_MUTATION } from '../graphql/mutations';

interface ILoginForm {
	email: string;
	password: string;
}

export const Login = () => {
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<ILoginForm>({ mode: 'onBlur' });

	const [showPassword, setShowPassword] = useState(false);
	const history = useHistory();

	const onCompleted = (data: loginMutation) => {
		//console.log(data);
		const {
			login: { success, token, error: loginError },
		} = data;

		if (success && token && !loginError) {
			localStorage.setItem(LS_TOKEN, token);
			authTokenVar(token);
			isLoggedInVar(true);
			history.push('/main/dashboard');
		}
	};
	const variables = {
		loginInput: watch(),
	};

	const [loginMutaion, { data: loginMutationResult, loading }] = useMutation<loginMutation, loginMutationVariables>(LOGIN_MUTATION, {
		onCompleted,
		variables,
	});

	function _onSubmit() {
		//console.log(variables);
		if (!loading) {
			loginMutaion();
		}
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
				<Typography variant='h3'>PMSK</Typography>
				<Typography>Purecell Management System for Korea</Typography>
				<Box component='form' onSubmit={handleSubmit(_onSubmit)} noValidate sx={{ mt: 1 }}>
					<TextField
						{...register('email', { required: 'Email is required' })}
						error={!!errors.email}
						helperText={errors?.email?.message || ' '}
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email Address'
						name='email'
						autoComplete='email'
						autoFocus
					/>
					<TextField
						{...register('password', { required: 'Password is required' })}
						error={!!errors.password}
						helperText={errors?.password?.message || ' '}
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						id='password'
						autoComplete='current-password'
						type={showPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)} onMouseDown={handleMouseDownPassword} edge='end'>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{loginMutationResult?.login.error && (
						<Stack sx={{ width: '100%' }} spacing={2}>
							<Alert severity='error'>{loginMutationResult?.login.error}</Alert>
						</Stack>
					)}
					<LoadingButton loading={loading} type='submit' fullWidth variant='contained' size='large' sx={{ mt: 3, mb: 2 }}>
						Log In
					</LoadingButton>
				</Box>
			</Box>
			<Typography variant='body2' color='text.secondary' align='center' sx={{ mt: 8, mb: 4 }}>
				{'Copyright © '}
				<Link color='inherit' href='https://vitashop.ca/'>
					VitaShop
				</Link>
				{' 2021.'}
			</Typography>
		</Container>
	);
};
