import { useState } from 'react';
import { Box, IconButton, Card, CardContent, FormControl, FormLabel, TextField, InputAdornment, Stack, Select, MenuItem, CircularProgress, Grid } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SearchOrdersInput, OrderState } from '../graphql_types/globalTypes';
import { useQuery } from '@apollo/client';
import { COURIERS_INFO_QUERY } from '../graphql/queries';
import { useTranslation } from 'react-i18next';

interface CustomerInvoiceSearchBarProp {
	onSearch: (data: SearchOrdersInput) => (e: React.FormEvent<HTMLFormElement>) => void;
	isSearching: boolean;
}

export interface CustomerInvoiceSearchDataProp {
	orderState: OrderState | 'All';
	orderFrom: Date | null;
	orderTo: Date | null;
	name: string;
}

export const CustomerInvoiceSearchBar: React.FC<CustomerInvoiceSearchBarProp> = ({ onSearch, isSearching = false, ...rest }) => {
	const toDay = new Date();
	const [searchData, setSearchData] = useState<CustomerInvoiceSearchDataProp>({
		orderState: 'All',
		orderFrom: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, 0),
		orderTo: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59),
		name: '',
	});
	const { t } = useTranslation();

	return (
		<Box {...rest}>
			<Box>
				<Card>
					<CardContent
						component='form'
						sx={{ padding: '24px', paddingBottom: '24px' }}
						onSubmit={onSearch({
							...searchData,
							orderState: searchData.orderState === 'All' ? null : searchData.orderState,
						})}>
						<Grid container>
							<Grid sm={3} md={2} xl={1}>
								<FormControl sx={{ width: '120px' }}>
									<FormLabel component='legend'>{t('Status')}</FormLabel>
									<Select
										sx={{ marginTop: 1 }}
										id='order-state-select'
										value={searchData.orderState}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setSearchData({ ...searchData, orderState: newValue });
										}}>
										<MenuItem value='All'>{t('All')}</MenuItem>
										{Object.keys(OrderState).map((state) => {
											if (state === OrderState.Sent) return null;
											return (
												<MenuItem value={state} key={state}>
													{t(state)}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid xs={12} sm={9} md={10} xl={11}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<FormLabel component='legend'>{t('Date')}</FormLabel>
									<Stack spacing={3} direction='row' sx={{ marginTop: 1, marginBottom: 1 }}>
										<DatePicker
											label='From'
											openTo='day'
											views={['year', 'month', 'day']}
											value={searchData.orderFrom}
											onChange={(newValue: Date | null) => {
												newValue?.setHours(0, 0, 0);
												setSearchData({ ...searchData, orderFrom: newValue });
											}}
											renderInput={(params: any) => <TextField {...params} />}
										/>
										<DatePicker
											label='To'
											openTo='day'
											views={['year', 'month', 'day']}
											value={searchData.orderTo}
											onChange={(newValue: Date | null) => {
												newValue?.setHours(23, 59, 59);
												setSearchData({ ...searchData, orderTo: newValue });
											}}
											renderInput={(params: any) => <TextField {...params} />}
										/>
									</Stack>
								</LocalizationProvider>
							</Grid>
							<Grid xs={12}>
								<TextField
									fullWidth
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{!isSearching && (
													<IconButton sx={{ p: '10px' }} aria-label='search' type='submit'>
														<SearchIcon />
													</IconButton>
												)}
												{isSearching && <CircularProgress size={24} color='inherit' />}
											</InputAdornment>
										),
									}}
									value={searchData.name}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setSearchData({ ...searchData, name: newValue });
									}}
									placeholder={t('Search')}
									variant='outlined'
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Box>
		</Box>
	);
};
