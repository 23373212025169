import { Box, Button, Container, Paper } from '@mui/material';
import { ReceiptOutlined as ReceiptIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import CustomOrderTable, { TableColumnDef } from '../../components/customOrderTable';
import { SEARCHORDERS_QUERY } from '../../graphql/queries';
import { padStart } from '../../utils';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from '@apollo/client';
import { OrderState, SearchOrdersInput } from '../../graphql_types/globalTypes';
import { searchOrdersQuery, searchOrdersQueryVariables } from '../../graphql_types/searchOrdersQuery';
import { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { InvoiceTemplate } from '../../components/InvoiceTemplate';
import { saveAs } from 'file-saver';
import { CustomerInvoiceSearchBar } from '../../components/customerInvoiceSearchbar';
import { OrderStateChip } from '../../components/orderStateChip';

export const CustomerInvoice = () => {
	const { t } = useTranslation();
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const { enqueueSnackbar } = useSnackbar();

	const columnDef: TableColumnDef[] = [
		{
			name: t('OrderNo'),
			datafield: 'orderNo',
			align: 'center',
			headerAlign: 'center',
			width: 100,
		},
		{
			name: t('From'),
			datafield: 'from',
			align: 'center',
			headerAlign: 'center',
			hideMobile: true,
			width: 150,
		},
		{
			name: t('Name'),
			datafield: 'pccName',
			align: 'center',
			headerAlign: 'center',
			width: 130,
		},
		{
			name: t('Address'),
			datafield: 'addr',
			width: 350,
			hideMobile: true,
		},
		{
			name: t('Order'),
			datafield: 'orderList',
			width: 500,
			customValue: (row: any) => {
				let itemCnt = 0;
				for (let i = 0; i < row.orderProducts.length; i++) {
					itemCnt += row.orderProducts[i].qty * row.orderProducts[i].productQty;
				}
				if (row.orderProducts.length > 1) return `(${itemCnt} item)${row.orderProducts[0].productName} 외 ${row.orderProducts.length - 1}`;
				return `(${itemCnt} item)${row.orderProducts[0]?.productName}`;
			},
			style: (row: any) => {
				let itemCnt = 0;
				for (let i = 0; i < row.orderProducts.length; i++) {
					itemCnt += row.orderProducts[i].qty * row.orderProducts[i].productQty;
				}
				if (itemCnt > 6) return { color: 'warning.main' };
				return {};
			},
			hideMobile: true,
		},
		{
			name: t('Order Status'),
			datafield: 'orderState',
			customValue: (row: any) => {
				return <OrderStateChip key={row.id} state={row.orderState} size='small' />;
			},
			hideMobile: true,
		},
		{
			name: t('Order Date'),
			datafield: 'orderedAt',
			customValue: (row: any) => {
				const date = new Date(row.orderedAt);
				return `${padStart(date.getMonth() + 1, 2, '0')}/${padStart(date.getDate(), 2, '0')}/${date.getFullYear()}`;
			},
			hideMobile: true,
		},
		{
			name: t('Personal Invoice'),
			datafield: 'invoice',
			customValue: (row: any) => {
				if (row.orderState !== OrderState.Canceled)
					return (
						<Button variant='outlined' size='small' onClick={_downloadInvoice(row)} startIcon={<ReceiptIcon />}>
							Download
						</Button>
					);
				else return '';
			},
		},
	];

	const _downloadInvoice = (data: any) => async (e: React.MouseEvent<HTMLElement>) => {
		const pdfDoc = <InvoiceTemplate invoiceData={convertInvoiceData(data)} />;
		const asPdf = pdf();
		asPdf.updateContainer(pdfDoc);

		const blob = await asPdf.toBlob();
		saveAs(blob, `${data.orderNo}.pdf`);
	};

	const convertInvoiceData = (data: any) => {
		console.log(data);
		return {
			orderNo: data.orderNo,
			companyAddr: '7181 Yonge st. unit #38, Markham ON L3T0C7',
			companyMobileNo: '+1-647-797-2348',
			companyEmail: 'info@canadavitamin.net',
			billTo: data.buyerName,
			billAddr: `${data.addr} ${data.addrDetail}`,
			billMobilNo: data.buyerPhone,
			billEmail: '',
			invoiceFrom: data.orderedAt,
			invoiceTo: data.orderedAt,
			orderData: data.orderProducts.map((orderData: any) => ({
				invoiceDate: data.orderedAt,
				productName: orderData.productName,
				brand: orderData.brand,
				qty: orderData.qty,
				unitPrice: orderData.unitPrice,
				shippingFee: data.shippingFee,
				currency: data.currency,
			})),
		};
	};

	const onCompleted = (data: searchOrdersQuery) => {
		const {
			searchOrder: { success, orders },
		} = data;

		if (success) {
			setTableRowData(orders as any);
		} else {
			enqueueSnackbar(t(`Cannot search orders...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [searchOrdersQuery, { loading, refetch }] = useLazyQuery(SEARCHORDERS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const _onSearch = (searchData: SearchOrdersInput) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: searchOrdersQueryVariables = {
			searchOrderInput: { ...searchData },
		};
		searchOrdersQuery({ variables });
	};

	useEffect(() => {
		const toDay = new Date();
		const tempVariables: searchOrdersQueryVariables = {
			searchOrderInput: {
				orderFrom: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, 0),
				orderTo: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59),
			},
		};
		searchOrdersQuery({ variables: tempVariables });
	}, [searchOrdersQuery]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Customer Invoice')} | PMSK</title>
			</Helmet>
			<CustomerInvoiceSearchBar onSearch={_onSearch} isSearching={loading} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					<CustomOrderTable TableData={tableRowData} TableColumn={columnDef} loading={loading} showTotal pagination height={450} refetch={refetch} />
				</Box>
			</Container>
		</Paper>
	);
};
