import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField, Autocomplete, Typography } from '@mui/material';
import { OrderProductInputType } from '../graphql_types/globalTypes';
import { useQuery } from '@apollo/client';
import { PRODUCTSINFO_QUERY } from '../graphql/queries';
import { productsInfoQuery } from '../graphql_types/productsInfoQuery';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface OrderDetailDialogProps {
	open: boolean;
	close: () => void;
	setOrderProduct?: (val: OrderProductInputType) => void;
	orderProduct?: OrderProductInputType;
}

const AddOrderProductDialog: React.FC<OrderDetailDialogProps> = ({ open = false, close, setOrderProduct, orderProduct }) => {
	const [product, setProduct] = useState<OrderProductInputType>({
		productName: '',
		alias: '',
		brand: '',
		productQty: 1,
		qty: 0,
		unitPrice: 0,
		reportableUnitPrice: 0,
		productWeight: 0,
	});
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const { data } = useQuery<productsInfoQuery>(PRODUCTSINFO_QUERY);

	const _onSave = () => {
		//console.log(product);
		if (product.productName === '') {
			enqueueSnackbar(t(`'Product Name' field must required. Please fill 'Product Name'`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (product.brand === '') {
			enqueueSnackbar(t(`'Brand' field must required. Please fill 'Brand'`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (!product.qty || product.qty <= 0) {
			enqueueSnackbar(t(`'Qty' field must required. Please fill 'Qty'`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (!product.reportableUnitPrice || product.reportableUnitPrice <= 0) {
			enqueueSnackbar(t(`'Unit Price' field must required. Please fill 'Unit Price'`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (!product.productWeight || product.productWeight <= 0) {
			enqueueSnackbar(t(`'Weight' field must required. Please fill 'Weight'`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (setOrderProduct) setOrderProduct(product);
		setProduct({
			productName: '',
			alias: '',
			brand: '',
			productQty: 1,
			qty: 0,
			unitPrice: 0,
			reportableUnitPrice: 0,
			productWeight: 0,
		});
		close();
	};

	useEffect(() => {
		if (orderProduct) setProduct(orderProduct);
		else
			setProduct({
				productName: '',
				alias: '',
				brand: '',
				productQty: 1,
				qty: 0,
				unitPrice: 0,
				reportableUnitPrice: 0,
				productWeight: 0,
			});
	}, [orderProduct]);

	return (
		<>
			<Dialog open={open} onClose={close} scroll='paper' maxWidth='sm' aria-labelledby='order-detail-dialog-title' aria-describedby='order-detail-dialog-description'>
				<DialogTitle id='order-detail-dialog-title'>{t('Product Information')}</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} sx={{ marginTop: 1 }}>
						<Grid item xs={12}>
							{data && (
								<Autocomplete
									freeSolo
									disableClearable
									options={data.productsInfo.products ? data.productsInfo.products : []}
									value={product.productName}
									getOptionLabel={(option: any) => {
										if (option.productName && option.productQty) return `${option.productName}(qty:${option.productQty})`;
										else {
											return option;
										}
									}}
									onChange={(e: any, newValue: any | null, reason: string) => {
										//console.log(reason);
										if (newValue?.productName) {
											//console.log(newValue);
											setProduct({
												productName: newValue.productName,
												alias: newValue.alias,
												brand: newValue.brand,
												barcode: newValue.barcode,
												qty: 1,
												productQty: newValue.productQty,
												unitPrice: newValue.unitPrice,
												reportableUnitPrice: newValue.reportableUnitPrice,
												productWeight: newValue.productWeight,
											});
										}
										if (reason === 'createOption') setProduct({ ...product, productQty: 1, barcode: '' });
									}}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id}>
												{option.productName}(qty:{option.productQty})
											</li>
										);
									}}
									renderInput={(params: any) => (
										<TextField
											{...params}
											label={t('Product Name')}
											fullWidth
											required
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												if (newValue === '') setProduct({ ...product, productName: newValue, alias: newValue, productQty: 1 });
												else setProduct({ ...product, productName: newValue, alias: newValue });
											}}
											InputProps={{
												...params.InputProps,
												type: 'search',
											}}
										/>
									)}
								/>
							)}
						</Grid>
						<Grid item xs={12} lg={6}>
							<TextField
								label={t('Brand')}
								fullWidth
								required
								value={product.brand}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setProduct({ ...product, brand: newValue });
								}}
							/>
						</Grid>
						<Grid item xs={4} lg={2}>
							<TextField label={t('Product Qty')} fullWidth type='number' value={product.productQty} disabled />
						</Grid>
						<Grid item xs={4} lg={2}>
							<TextField
								label={t('Qty')}
								fullWidth
								required
								type='number'
								value={product.qty}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setProduct({ ...product, qty: +newValue });
								}}
							/>
						</Grid>
						<Grid item xs={4} lg={2}>
							<TextField
								label={t('Weight')}
								fullWidth
								required
								type='number'
								value={product.productWeight}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setProduct({ ...product, productWeight: +newValue });
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label={t('Unit Price')}
								fullWidth
								required
								type='number'
								value={product.unitPrice}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setProduct({ ...product, unitPrice: +newValue });
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label={t('Reportable Unit Price')}
								fullWidth
								required
								type='number'
								value={product.reportableUnitPrice}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setProduct({ ...product, reportableUnitPrice: +newValue });
								}}
							/>
						</Grid>
					</Grid>
					<Typography variant='caption' display='block' gutterBottom>
						{t('* Weight = unit weight * Product Qty')}
					</Typography>
					<Typography variant='caption' display='block' gutterBottom>
						{t("* Reportable Unit Price = Only use for making shipping label. It's only for CAD")}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={_onSave}>{t('Save')}</Button>
					<Button onClick={close} color='error'>
						{t('Close')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AddOrderProductDialog;
