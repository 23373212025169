import { Chip } from '@mui/material';
import { TrendingDown, TrendingUp } from '@mui/icons-material';

interface TrendChipProps {
	current: number;
	last?: number;
}

export const TrendChip: React.FC<TrendChipProps> = ({ current, last }) => {
	let trend: number | string = 0;

	if (last) {
		trend = Math.round((current / last - 1) * 10000) / 100;
	}

	return (
		<>
			{trend ? <Chip sx={{ marginLeft: 2 }} variant='outlined' size='small' color={trend > 0 ? 'success' : 'warning'} icon={trend > 0 ? <TrendingUp /> : <TrendingDown />} label={`${trend}%`} /> : ''}
		</>
	);
};
