import { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from '../lang/i18n';
import { LANGUAGES, LS_LANGUAGE } from '../constant';
import { languageVar } from '../apollo';
import { useReactiveVar } from '@apollo/client';
import Flag from 'react-flagkit';

export const LanguageChangeDropDown = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();
	const language = useReactiveVar(languageVar);

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLanguage = (lang: string) => (event: React.MouseEvent<HTMLElement>) => {
		i18next.changeLanguage(lang);
		languageVar(lang);
		localStorage.setItem(LS_LANGUAGE, lang);
		handleClose();
	};

	return (
		<>
			<Button size='small' onClick={handleMenu} color='inherit'>
				{language?.toUpperCase()}
			</Button>
			<Menu
				sx={{ mt: '35px' }}
				id='menu-appbar'
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				{LANGUAGES.map((lang) => (
					<MenuItem onClick={handleLanguage(lang.code)}>
						<ListItemIcon>
							<Flag country={lang.countryCode} />
						</ListItemIcon>
						{t(lang.name)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
