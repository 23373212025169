import { useEffect } from "react";
import "./Loading.css";

export default () => {
	useEffect(() => {
		document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width:100%; height:100vh; overflow-y:scroll`;
		return () => {
			const scrollY = document.body.style.top;
			document.body.style.cssText = `position: ""; top: ""; width:100%`;
			window.scrollTo(0, parseInt(scrollY || "0") * -1);
		};
	}, []);

	return (
		<div className='background'>
			<div className='lds-ellipsis'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};
