import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from './apollo';
import './App.css';
import { LoggedInRouter } from './routers/logged-in-router';
import { LoggedOutRouter } from './routers/logged-out-router';

function App() {
	const isLoggedIn = useReactiveVar(isLoggedInVar);
	return isLoggedIn ? <LoggedInRouter /> : <LoggedOutRouter />;
}

export default App;
