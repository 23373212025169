import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
	mutation loginMutation($loginInput: LoginInput!) {
		login(input: $loginInput) {
			success
			token
			error
		}
	}
`;

export const CREATE_ACCOUNT_MUTATION = gql`
	mutation createAccountMutation($createAccountInput: CreateAccountInput!) {
		createAccount(input: $createAccountInput) {
			success
			error
		}
	}
`;

export const EDITPROFILE_MUTATION = gql`
	mutation editProfileMutation($editProfileInput: EditProfileInput!) {
		editProfile(input: $editProfileInput) {
			success
			error
		}
	}
`;

export const CHANGESTATE_MUTATION = gql`
	mutation changeStateMutation($changeStateIds: [Float!]!, $changeStateOrderState: OrderState!) {
		changeState(orderIds: $changeStateIds, orderState: $changeStateOrderState) {
			success
			error
		}
	}
`;

export const SAVEORDERS_MUTATION = gql`
	mutation saveOrdersMutation($orderInput: [OrderInputType!]!, $checkPCCInput: Boolean) {
		saveOrders(orders: $orderInput, checkPCC: $checkPCCInput) {
			success
			saveRowCnt
			error
		}
	}
`;

export const UPDATEORDERS_MUTATION = gql`
	mutation updateOrdersMutation($updateOrdersInput: [UpdateOrderInputType!]!) {
		updateOrders(orders: $updateOrdersInput) {
			success
			error
		}
	}
`;

export const SAVECOURIER_MUTATION = gql`
	mutation saveCourierMutation($saveCourierInput: SaveCourierInput!) {
		saveCourier(input: $saveCourierInput) {
			success
			error
		}
	}
`;

export const DELETECOURIERS_MUTATION = gql`
	mutation deleteCouriersMutation($deleteCourierInput: [Float!]!) {
		deleteCouriers(ids: $deleteCourierInput) {
			success
			error
		}
	}
`;

export const SAVEPRODUCT_MUTATION = gql`
	mutation saveProductMutation($saveProductInput: SaveProductInput!) {
		saveProduct(input: $saveProductInput) {
			success
			error
		}
	}
`;

export const DELETEPRODUCTS_MUTATION = gql`
	mutation deleteProductsMutation($deleteProductsInput: [Float!]!) {
		deleteProducts(ids: $deleteProductsInput) {
			success
			error
		}
	}
`;

export const SAVECUSTOMER_MUTATION = gql`
	mutation saveCustomerMutation($saveCustomerInput: SaveCustomerInput!) {
		saveCustomer(input: $saveCustomerInput) {
			success
			error
		}
	}
`;

export const DELETECUSTOMERS_MUTATION = gql`
	mutation deleteCustomersMutation($deleteCustomersInput: [Float!]!) {
		deleteCustomers(ids: $deleteCustomersInput) {
			success
			error
		}
	}
`;

export const SAVESHIPPINGNUMBERS_MUTATION = gql`
	mutation saveShippingNumbersMutation($courierId: Float!, $saveShippingNoInput: [SaveShippingNumberInputType!]!) {
		saveShippingNumbers(courierId: $courierId, input: $saveShippingNoInput) {
			success
			error
			result {
				id
				msg
			}
		}
	}
`;

export const MERGEORDER_MUTATION = gql`
	mutation mergeOrderMutation($mergeOrder1Id: Float!, $mergeOrder2Id: Float!) {
		mergeOrder(order1Id: $mergeOrder1Id, order2Id: $mergeOrder2Id) {
			success
			error
		}
	}
`;

export const CHECKANDSAVEPCC_MUTATION = gql`
	mutation checkAndSavePCCMutation($pccInfo: CheckAndSavePCCInput!) {
		checkAndSavePCC(input: $pccInfo) {
			success
			error
			checkResult {
				id
				errId
				result
				resultCode
				errorMsg
			}
		}
	}
`;

export const CONFIRMPCCCHECK_MUTATION = gql`
	mutation confirmPccCheckMutation($errIds: [Float!]!) {
		confirmPCCCheck(errIds: $errIds) {
			success
			error
		}
	}
`;

export const SAVEUSER_MUTATION = gql`
	mutation saveUserMutation($saveUserInput: EditProfileInput!) {
		saveUser(input: $saveUserInput) {
			success
			error
		}
	}
`;

export const DELETEUSERS_MUTATION = gql`
	mutation deleteUsersMutation($deleteUsersInput: [Float!]!) {
		deleteUsers(ids: $deleteUsersInput) {
			success
			error
		}
	}
`;

export const UPDATE_STOCK_MUTATION = gql`
	mutation updateStockMutation($updateStockInput: UpdateStockInput!) {
		updateStock(input: $updateStockInput) {
			success
			error
		}
	}
`;

export const ADD_EXPIREDITEM = gql`
	mutation AddExpiredItem($expiredInputType: SaveExpiredProductInput!) {
		addExpiredItem(input: $expiredInputType) {
			success
			error
		}
	}
`

export const DELETEEXPIREDPRODUCTS_MUTATION = gql`
	mutation deleteExpiredProducts($deleteExpiredProductsInput: [Float!]!) {
		deleteExpiredProducts(ids: $deleteExpiredProductsInput) {
			success
			error
		}
	}
`;