import React, { useState, useEffect } from 'react';
import { UserRole } from '../../graphql_types/globalTypes'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { SEARCH_EXPIRED_PRODUCTS_QUERY } from '../../graphql/queries';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Stack, Button } from '@mui/material';
import {
	FileDownloadOutlined as DownloadIcon,
	CleaningServicesOutlined as CleaningServicesIcon,
} from '@mui/icons-material';
import { userInfoVar } from '../../apollo';
import * as XLSX from 'xlsx';
import CustomOrderTable from '../../components/customOrderTable';
import { ExpiredScan } from '../../components/expiredDate/expiredScan';
import { TableColumnDef } from '../../components/customTable';
import { DELETEEXPIREDPRODUCTS_MUTATION } from '../../graphql/mutations';
import { deleteExpiredProductsMutation, deleteExpiredProductsMutationVariables } from '../../graphql_types/deleteExpiredProductsMutation';
import { useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export interface ExpiredInputType {
	productName: string;
	brand: string;
	referenceCode: string;
	barcode: string;
	quantity: number;
	expiryDate: Date;
	comment?: string | null;
}

const ExpiredDate = () => {
    const [tableRowData, setTableRowData] = useState<any[]>([]);
	const userInfo = useReactiveVar(userInfoVar);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
	const [expiredProducts, setexpiredProducts] = useState<ExpiredInputType[]>([]);
	const isMobile = useMediaQuery('(max-width: 600px)');
	const columnDef: TableColumnDef[] = [
		{
			name: t('Product Name'),
			datafield: 'productName',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('reference Code'),
			datafield: 'referenceCode',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Brand'),
			datafield: 'brand',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('qty'),
			datafield: 'quantity',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Expiry Date'),
			datafield: 'expiryDate',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Note'),
			datafield: 'comment',
			headerAlign: 'center',
			align: 'center',
		},
	];

	const _download = (data: any) => (e: React.MouseEvent<HTMLElement>) => {
		const exportData = data.map((item: {barcode: any;referenceCode: any; id: any; productName: string; brand: string; quantity: string; expiryDate: any; comment: string; }) => ({
			id: item.id,
			productName: item.productName,
			referenceCode: item.referenceCode,
			barcode: item.barcode,
			brand: item.brand,
			quantity: item.quantity,
			expiryDate: item.expiryDate,
			note: item.comment
		}));
		const FILE_NAME = `product_expirydate.xlsx`;
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(exportData);
		XLSX.utils.book_append_sheet(wb, ws, 'ExpiriedProducts');
		XLSX.writeFile(wb, FILE_NAME);
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		setCheckedVal(checkedData);
	};

	const searchQueryCompleted = (data: any) => {
		const {
			searchExpiredProducts: { success, products },
		} = data;

		if (success) {
			const updatedProducts = products.map((product: { [x: string]: any; expiryDate: any; }) => {
				const {expiryDate, ...rest} = product;
				const date = new Date(expiryDate);
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const trimmedDate = `${year}-${month.toString().padStart(2, '0')}`;
		  
				return {
					...rest,
					expiryDate: trimmedDate,
				}
			})
			setTableRowData(updatedProducts as any);
		} else {
			enqueueSnackbar(t(`Cannot search ExpiredProducts...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onDeleteCompleted = (data: any) => {
		const {deleteExpiredProducts: { success },} = data;
		if (success) {
			refetchQueryTable();
			enqueueSnackbar(t(`Expired Products Deleted successfully`), { variant: 'success', autoHideDuration: 3000 });
		} else {
			enqueueSnackbar(t(`Cannot update stock..😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	}
	
	const [deleteUsersMutation] = useMutation<deleteExpiredProductsMutation, deleteExpiredProductsMutationVariables>(DELETEEXPIREDPRODUCTS_MUTATION, { onCompleted: onDeleteCompleted });

	const __removeExpiredProducts = (data: any) => {
		const checkedIds = checkedVal.map((value) => value.id);
		deleteUsersMutation({ variables: { deleteExpiredProductsInput: checkedIds } });
	};

	const [searchExpiredProductsQuery, {loading: loadingQuery1, refetch: refetchQueryTable}] = useLazyQuery(SEARCH_EXPIRED_PRODUCTS_QUERY, {
		onCompleted: searchQueryCompleted,
		fetchPolicy: 'no-cache',
	})

	useEffect(() => {
		searchExpiredProductsQuery()
	}, [searchExpiredProductsQuery])
	
    return (
        <>
			<Helmet>
				<title>{t('Expire date manage')} | PMSK</title>
			</Helmet>
			<ExpiredScan expiredProducts={expiredProducts} setexpiredProducts={setexpiredProducts} refetchQueryTable={refetchQueryTable} />
			{!isMobile && (
				<Stack direction='row' spacing={1} justifyContent="flex-end">
					{(userInfo.role === UserRole.Admin || userInfo.role === UserRole.Manager || userInfo.role === UserRole.StockManager) && (
						<Button size='medium' onClick={_download(checkedVal)}>
							<DownloadIcon fontSize='medium' />
							{t('Download')}
						</Button>
					)}
					<Button size='medium' onClick={() => __removeExpiredProducts(checkedVal)}>
						<CleaningServicesIcon fontSize='medium' />
						{t('Remove')}
					</Button>
				</Stack>
			)}
			{!isMobile && (
				<CustomOrderTable 
					TableData={tableRowData} 
					TableColumn={columnDef} 
					loading={loadingQuery1}
					height={450} 
					checkbox 
					pagination
					setCheckedValues={getCheckedVal}
				/>
			)}
        </>
    )
}

export default ExpiredDate