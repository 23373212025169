import { gql } from '@apollo/client';

export const GETPROFILE_QUERY = gql`
	query getProfileQuery {
		getProfile {
			firstName
			lastName
			role
			email
		}
	}
`;

export const SEARCHORDERS_QUERY = gql`
	query searchOrdersQuery($searchOrderInput: SearchOrdersInput!) {
		searchOrder(input: $searchOrderInput) {
			success
			error
			orders {
				id
				orderNo
				from
				referenceNo
				category
				website
				buyerPhone
				buyerName
				recieverPhone
				recieverName
				postal
				addr
				addrDetail
				PCC
				pccName
				pccPhone
				pccResult
				pccResultMsg
				msg
				comment
				orderState
				shippingNo
				orderedAt
				sentDate
				currency
				shippingFee
				courier {
					name
				}
				customer {
					name
					delimiter
				}
				orderProducts {
					productName
					alias
					brand
					option
					reportableUnitPrice
					barcode
					unitPrice
					qty
					productQty
					productWeight
				}
			}
		}
	}
`;

export const SEARCHORDERDETAIL_QUERY = gql`
	query searchOrderDetailQuery($searchOrderDetailInput: SearchOrderDetailInput!) {
		searchOrderDetail(input: $searchOrderDetailInput) {
			success
			error
			order {
				id
				orderNo
				from
				referenceNo
				category
				website
				buyerPhone
				buyerName
				recieverPhone
				recieverName
				postal
				addr
				addrDetail
				PCC
				pccType
				pccName
				pccPhone
				pccResult
				msg
				comment
				orderState
				shippingNo
				orderedAt
				courier {
					id
					name
				}
				customer {
					name
					delimiter
				}
				orderProducts {
					productName
					alias
					brand
					option
					reportableUnitPrice
					unitPrice
					qty
					productQty
					productWeight
				}
			}
		}
	}
`;

export const CHECKPCC_QUERY = gql`
	query checkPccQuery($pccInfo: [PccInfoType!]!) {
		checkPCC(pccData: $pccInfo) {
			success
			error
			checkResults {
				id
				result
				resultCode
				errorMsg
			}
		}
	}
`;

export const PRODUCTSINFO_QUERY = gql`
	query productsInfoQuery {
		productsInfo {
			products {
				id
				productName
				alias
				brand
				barcode
				productQty
				unitPrice
				reportableUnitPrice
				productWeight
			}
		}
	}
`;

export const SEARCHSHIPPINGORDERS_QUERY = gql`
	query searchShippingOrdersQuery($searchShippingOrdersInput: SearchShippingOrdersInput!) {
		searchShippingOrders(input: $searchShippingOrdersInput) {
			success
			error
			itemOver
			orders {
				id
				orderNo
				from
				category
				website
				buyerPhone
				buyerName
				recieverPhone
				recieverName
				postal
				addr
				addrDetail
				PCC
				pccName
				pccPhone
				pccResult
				msg
				comment
				orderState
				shippingNo
				orderedAt
				orderProducts {
					productName
					alias
					brand
					option
					reportableUnitPrice
					qty
					productQty
					productWeight
				}
			}
		}
	}
`;

export const SEARCHPRODUCTS_QUERY = gql`
	query searchProductsQuery($searchProductsInput: SearchProductsInput!) {
		searchProducts(input: $searchProductsInput) {
			success
			error
			products {
				id
				productName
				alias
				brand
				barcode
				unitPrice
				reportableUnitPrice
				cost
				productWeight
				productQty
			}
		}
	}
`;

export const SEARCHCOURIERS_QUERY = gql`
	query searchCouriersQuery($searchCouriersInput: SearchCouriersInput!) {
		searchCouriers(input: $searchCouriersInput) {
			success
			error
			couriers {
				id
				name
				trackUrl
				seqForOrder
				isActive
			}
		}
	}
`;

export const SEARCHCUSTOMERS_QUERY = gql`
	query searchCustomersQuery($searchCustomersInput: SearchCustomersInput!) {
		searchCustomers(input: $searchCustomersInput) {
			success
			error
			customers {
				id
				name
				representative
				delimiter
				email
				contact
				zipcode
				address
				detailAddress
				isActive
			}
		}
	}
`;

export const FINDPCCERROR_QUERY = gql`
	query findPccErrorQuery($findPccErrorInput: FindPCCErrorInput!) {
		findPccError(input: $findPccErrorInput) {
			success
			error
			errorList {
				id
				errId
				name
				beforePCC
				modifiedName
				pccPhone
				modifiedPCC
				checkMsg
				briefOrder
				modifyChecked
				modifyConfirm
				from
				buyerPhone
				recieverPhone
				orderDate
			}
		}
	}
`;

export const SEARCHUSERS_QUERY = gql`
	query searchUsersQuery($searchUsersInput: SearchUsersInput!) {
		searchUsers(input: $searchUsersInput) {
			success
			error
			users {
				id
				email
				firstName
				lastName
				role
				isActive
			}
		}
	}
`;

export const COURIERS_INFO_QUERY = gql`
	query couriersInfoQuery {
		couriersInfo {
			success
			error
			couriers {
				id
				name
				trackUrl
				seqForOrder
				isActive
			}
		}
	}
`;

export const CUSTOMERS_INFO_QUERY = gql`
	query customersInfoQuery {
		customersInfo {
			success
			error
			customers {
				id
				name
				delimiter
				isActive
			}
		}
	}
`;

export const STATUS_COUNT_BY_COURIER_QUERY = gql`
	query statusCountByCourierQuery {
		statusCountByCourier {
			success
			output {
				name
				status {
					shipState
					count
				}
			}
		}
	}
`;

export const ORDER_COUNT_BY_DATE_QUERY = gql`
	query orderCountByDateQuery {
		orderCountByDate {
			success
			output {
				orderDate
				openMarketCount
				B2BCount
			}
		}
	}
`;

export const MONTHLY_SALES_AMOUNT_BY_MARKET_QUERY = gql`
	query monthlySalesAmountByMarketQuery {
		monthlySalesAmountByMarket {
			success
			total {
				current
				lastMonth
			}
			output {
				month
				market
				salesAmount
			}
		}
	}
`;

export const MONTHLY_SALES_AMOUNT_BY_B2B_QUERY = gql`
	query monthlySalesAmountByB2BQuery {
		monthlySalesAmountByB2B {
			success
			total {
				current
				lastMonth
			}
			output {
				month
				B2BName
				salesAmount
			}
		}
	}
`;

export const PERIOD_INVOICE_QUERY = gql`
	query getPeriodInvoiceQuery($customerId: Float!, $invoiceFrom: DateTime!, $invoiceTo: DateTime!) {
		getPeriodInvoice(customerId: $customerId, invoiceFrom: $invoiceFrom, invoiceTo: $invoiceTo) {
			success
			invoiceData {
				companyAddr
				companyEmail
				companyMobileNo
				billTo
				billAddr
				billMobilNo
				billEmail
				invoiceFrom
				invoiceTo
				orderData {
					invoiceDate
					shippingFee
					currency
					productName
					brand
					qty
					unitPrice
				}
			}
		}
	}
`;

export const GET_STOCK_BY_BARCODE_QUERY = gql`
	query getStockByBarcodeQuery($getStockInput: GetStockInput!) {
		getStockByBarcode(input: $getStockInput) {
			success
			products {
				productId
				productName
				reference
				stock
				physical_qty
				reserved_qty
				weight
			}
		}
	}
`;

export const EXPIRYDATE_BARCODE_QUERY = gql`
	query getStockByBarcodeQuery2($getStockInput: GetExpiredStockInput!) {
		getExpiredStockByBarcode(input: $getStockInput) {
			success
			products {
				productName
				referenceCode
				brand
				barcode
				quantity
			}
		}
	}
`;

export const SEARCH_EXPIRED_PRODUCTS_QUERY = gql`
	query searchExpiredProductsQuery {
		searchExpiredProducts {
			success
			products {
				id
				productName
				brand
				referenceCode
				barcode
				quantity
				expiryDate
				comment
			}
		}
	}
`;

export const BEST_SALES_PRODUCT_QUERY = gql`
	query bestSalesProductQuery {
		bestSalesProductByMarket{
			success
			output{
				productName
				naver
				elevenst
				coupang
				interpark
				auction
				gmarket
				others
				salesAmount
			}
		}
	}
`
