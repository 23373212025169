import { useState } from 'react';
import {
	Paper,
	Select,
	FormControl,
	TextField,
	MenuItem,
	Grid,
	InputLabel,
	Container,
	Typography,
	IconButton,
	Switch,
	FormGroup,
	FormControlLabel,
	Autocomplete,
	CircularProgress,
	Divider,
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon, EditOutlined as EditIcon } from '@mui/icons-material';
import { CheckOutlined as CheckIcon, CloseOutlined as FailIcon, QuestionMarkOutlined as QuestionIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';
import { OrderCategory, OrderProductInputType, PccType, OrderInputType } from '../../graphql_types/globalTypes';
import { SAVEORDERS_MUTATION } from '../../graphql/mutations';
import { saveOrdersMutation, saveOrdersMutationVariables } from '../../graphql_types/saveOrdersMutation';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import CustomOrderTable, { TableColumnDef } from '../../components/customOrderTable';
import AddOrderProductDialog from '../../components/addOrderProductDialog';
import { CHECKPCC_QUERY, CUSTOMERS_INFO_QUERY } from '../../graphql/queries';
import { checkPccQuery, checkPccQueryVariables } from '../../graphql_types/checkPccQuery';
import { PhoneTextField } from '../../components/phoneTextfield';
import { useTranslation } from 'react-i18next';

const CreateOrderPage = () => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const [orderInfo, setOrderInfo] = useState<OrderInputType>({
		sortOrder: 0,
		category: OrderCategory.B2B,
		from: '',
		website: '',
		referenceNo: '',
		recieverPhone: '',
		recieverName: '',
		buyerPhone: '',
		buyerName: '',
		postal: '',
		addr: '',
		addrDetail: '',
		PCC: '',
		pccType: PccType.PCC,
		msg: '',
		comment: '',
		currency: 'CAD',
		shippingFee: 0,
		orderAmount: 0,
		orderProducts: [],
	});
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [editProduct, setEditProduct] = useState<OrderProductInputType | undefined>(undefined);
	const [checkPCC, setCheckPCC] = useState(true);
	const { t } = useTranslation();

	const onSaveCompleted = (data: saveOrdersMutation) => {
		const {
			saveOrders: { success },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Orders are saved!`), { variant: 'success', autoHideDuration: 3000 });
			history.push('/orders/manage');
		} else {
			enqueueSnackbar(t(`Cannot save order...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onPccCheckCompleted = (data: checkPccQuery) => {
		const {
			checkPCC: { success, checkResults },
		} = data;

		if (success && checkResults) {
			setOrderInfo({ ...orderInfo, pccResult: checkResults[0].resultCode === 1 });
		} else {
		}
	};

	const [saveOrdersMutation, { loading }] = useMutation<saveOrdersMutation, saveOrdersMutationVariables>(SAVEORDERS_MUTATION, { onCompleted: onSaveCompleted });
	const { data, loading: customerLoading } = useQuery(CUSTOMERS_INFO_QUERY);
	const [checkPccQuery, { loading: checkLoading }] = useLazyQuery<checkPccQuery, checkPccQueryVariables>(CHECKPCC_QUERY, {
		onCompleted: onPccCheckCompleted,
		notifyOnNetworkStatusChange: true,
	});

	const pccCheck = () => {
		const variables = { pccInfo: [{ id: 1, name: orderInfo.pccName ? orderInfo.pccName : '', pcc: orderInfo.PCC, pccType: orderInfo.pccType, phoneNo: orderInfo.pccPhone ? orderInfo.pccPhone : '' }] };
		checkPccQuery({ variables });
	};

	const checkValidation = (): Boolean => {
		if (!orderInfo.from) {
			enqueueSnackbar(t(`'From' field must required. Please fill 'From'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.recieverName) {
			enqueueSnackbar(t(`'Reciever name' field must required. Please fill 'Reciever name'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.buyerName) {
			enqueueSnackbar(t(`'Buyer name' field must required. Please fill 'Buyer name'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.buyerPhone) {
			enqueueSnackbar(t(`'Buyer Phone' field must required. Please fill 'Buyer Phone'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.recieverPhone) {
			enqueueSnackbar(t(`'Reciever Phone' field must required. Please fill 'Reciever Phone'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.pccName) {
			enqueueSnackbar(t(`'PCC Name' field must required. Please fill 'PCC Name'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.pccPhone) {
			enqueueSnackbar(t(`'PCC Phone' field must required. Please fill 'PCC Phone'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.PCC) {
			enqueueSnackbar(t(`'PCC' field must required. Please fill 'PCC'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.postal) {
			enqueueSnackbar(t(`'Postal code' field must required. Please fill 'Postal code'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (!orderInfo.addr) {
			enqueueSnackbar(t(`'Address' field must required. Please fill 'Address'`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		if (orderInfo.orderProducts.length <= 0) {
			enqueueSnackbar(t(`Product is empty. Please add products for order`), { variant: 'warning', autoHideDuration: 3000 });
			return false;
		}
		return true;
	};

	const handleClick = () => {
		if (checkValidation()) {
			saveOrdersMutation({
				variables: {
					orderInput: [orderInfo],
					checkPCCInput: checkPCC,
				},
			});
		} else {
			enqueueSnackbar(t(`Cannot save order...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const columnDef: TableColumnDef[] = [
		{
			name: t('Product Name'),
			datafield: 'productName',
		},
		{
			name: t('Brand'),
			datafield: 'brand',
		},
		{
			name: t('Qty'),
			datafield: 'qty',
			customValue: (row: any) => {
				return `${row.productQty * row.qty}`;
			},
			align: 'center',
			headerAlign: 'center',
		},
		{
			name: t('Unit Price'),
			datafield: 'unitPrice',
			align: 'center',
			headerAlign: 'center',
		},
		{
			name: t('Weight'),
			datafield: 'productWeight',
			align: 'center',
			headerAlign: 'center',
		},
	];

	const getCheckedVal = (values: any[]) => {
		setCheckedVal(values);
	};

	const getOrderProduct = (values: OrderProductInputType) => {
		let orderProducts = orderInfo.orderProducts;
		let orderAmount = 0;
		if (editProduct) orderProducts.splice(checkedVal[0], 1, values);
		else orderProducts.push(values);
		orderProducts.forEach((product) => (orderAmount += (product.unitPrice ? +product.unitPrice : 0) * (product.qty ? +product.qty : 0) * (product.productQty ? +product.productQty : 0)));
		setOrderInfo({ ...orderInfo, orderProducts, orderAmount });
	};

	const addOrderProduct = () => {
		setEditProduct(undefined);
		setOpenDialog(true);
	};

	const editOrderProduct = () => {
		if (checkedVal.length !== 1) {
			enqueueSnackbar(t('Edit Product must check 1 item'), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		setEditProduct(orderInfo.orderProducts.find((val, id) => id === checkedVal[0]));
		setOpenDialog(true);
	};

	const removeOrderProduct = () => {
		if (!checkedVal.length) {
			enqueueSnackbar(t('Please check the product to remove...'), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		const removedProducts = orderInfo.orderProducts.filter((val, id) => !checkedVal.includes(id));
		setOrderInfo({ ...orderInfo, orderProducts: removedProducts });
		setCheckedVal([]);
	};

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Create an Order')} | PMSK</title>
			</Helmet>
			<Container maxWidth='lg'>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel id='select-label-category'>{t('Category')}</InputLabel>
									<Select
										fullWidth
										defaultValue={orderInfo.category}
										label={t('Category')}
										labelId='select-label-category'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setOrderInfo({ ...orderInfo, category: newValue });
										}}>
										{Object.keys(OrderCategory).map((category, idx) => (
											<MenuItem key={idx} value={category}>
												{category}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label={t('Reference No.')}
									fullWidth
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, referenceNo: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									id='order-from'
									freeSolo
									options={!customerLoading && data.customersInfo?.customers?.map((option: any) => option.name)}
									loading={customerLoading}
									onChange={(e: any, newValue: any | null) => {
										//console.log(newValue);
										setOrderInfo({ ...orderInfo, from: newValue });
									}}
									renderInput={(params: any) => (
										<TextField
											{...params}
											fullWidth
											required
											label={t('Order From')}
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												//console.log(newValue);
												setOrderInfo({ ...orderInfo, from: newValue });
											}}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{customerLoading ? <CircularProgress color='inherit' size={20} /> : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label={t('WebSite')}
									fullWidth
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, website: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label={t('Buyer Name')}
									required
									fullWidth
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, buyerName: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label={t('Reciever Name')}
									required
									fullWidth
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, recieverName: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<PhoneTextField
									required
									label={t('Buyer Phone')}
									fullWidth
									value={orderInfo.buyerPhone}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, buyerPhone: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<PhoneTextField
									required
									label={t('Reciever Phone')}
									fullWidth
									value={orderInfo.recieverPhone}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, recieverPhone: newValue });
									}}
								/>
							</Grid>

							<Grid item xs={12}>
								<FormGroup sx={{ display: 'block' }}>
									<FormControlLabel
										control={
											<Switch
												checked={checkPCC}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
													setCheckPCC(event.target.checked);
												}}
											/>
										}
										label={t('Automatically PCC Check')}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={6} md={3}>
								<FormControl fullWidth focused={typeof orderInfo.pccResult !== 'undefined'}>
									<InputLabel id='select-label-pcctype'>{t('PCC Type')}</InputLabel>
									<Select
										defaultValue={orderInfo.pccType}
										label={t('PCC Type')}
										color={typeof orderInfo.pccResult === 'undefined' ? undefined : orderInfo.pccResult ? 'primary' : 'warning'}
										labelId='select-label-pcctype'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setOrderInfo({ ...orderInfo, pccType: newValue });
										}}>
										{Object.keys(PccType).map((pccType, idx) => (
											<MenuItem key={idx} value={pccType}>
												{t(pccType)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={3}>
								<LoadingButton
									sx={{ mt: 0.5 }}
									loading={checkLoading}
									loadingPosition='start'
									variant='outlined'
									onClick={pccCheck}
									color={typeof orderInfo.pccResult === 'undefined' ? undefined : orderInfo.pccResult ? 'primary' : 'warning'}
									startIcon={
										typeof orderInfo.pccResult === 'undefined' ? (
											<QuestionIcon fontSize='small' />
										) : orderInfo.pccResult ? (
											<CheckIcon color='success' fontSize='small' />
										) : (
											<FailIcon color='error' fontSize='small' />
										)
									}>
									{t('Check')}
								</LoadingButton>
							</Grid>
							<Grid item xs={3} md={6}></Grid>
							<Grid item xs={4}>
								<TextField
									label={t('PCC Name')}
									fullWidth
									required
									focused={typeof orderInfo.pccResult !== 'undefined'}
									color={typeof orderInfo.pccResult === 'undefined' ? undefined : orderInfo.pccResult ? 'primary' : 'warning'}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, pccName: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<PhoneTextField
									label={t('PCC Phone')}
									fullWidth
									required
									focused={typeof orderInfo.pccResult !== 'undefined'}
									color={typeof orderInfo.pccResult === 'undefined' ? undefined : orderInfo.pccResult ? 'primary' : 'warning'}
									value={orderInfo.pccPhone}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, pccPhone: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									label={t('PCC')}
									fullWidth
									required
									focused={typeof orderInfo.pccResult !== 'undefined'}
									color={typeof orderInfo.pccResult === 'undefined' ? undefined : orderInfo.pccResult ? 'primary' : 'warning'}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, PCC: newValue });
									}}
								/>
							</Grid>

							<Grid item xs={4} md={3}>
								<TextField
									label={t('Postal Code')}
									fullWidth
									required
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, postal: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={8} md={9}>
								<TextField
									label={t('Address')}
									fullWidth
									required
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, addr: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label={t('Address Detail')}
									fullWidth
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, addrDetail: newValue });
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label={t('Memo')}
									fullWidth
									multiline
									minRows={2}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, msg: newValue });
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid item xs={12}>
							<TextField
								label={t('Comment(Not print on shipping Label)')}
								fullWidth
								multiline
								minRows={2}
								onChange={(e: any) => {
									const {
										target: { value: newValue },
									} = e;
									setOrderInfo({ ...orderInfo, comment: newValue });
								}}
							/>
						</Grid>
						<AddOrderProductDialog open={openDialog} close={() => setOpenDialog(false)} setOrderProduct={getOrderProduct} orderProduct={editProduct} />
						<Grid container sx={{ marginTop: 2 }}>
							<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography variant='h6' component='div'>
									{t('Items')}
								</Typography>
							</Grid>
							<Grid item xs={6} sx={{ textAlign: 'right' }}>
								<IconButton aria-label='Add Product' color='primary' onClick={addOrderProduct}>
									<AddIcon />
								</IconButton>
								<IconButton aria-label='Remove Product' color='error' onClick={removeOrderProduct}>
									<RemoveIcon />
								</IconButton>
								<IconButton aria-label='Edit Product' color='secondary' onClick={editOrderProduct}>
									<EditIcon />
								</IconButton>
							</Grid>
							<Grid item xs={12}>
								<CustomOrderTable TableData={orderInfo.orderProducts} TableColumn={columnDef} checkbox setCheckedValues={getCheckedVal} rowClick />
							</Grid>
						</Grid>
						<Grid container sx={{ marginTop: 2 }} spacing={2}>
							<Grid item xs={4}>
								<FormControl fullWidth>
									<InputLabel id='select-label-category'>{t('Currency')}</InputLabel>
									<Select
										fullWidth
										defaultValue={orderInfo.currency}
										label={t('Currency')}
										labelId='select-label-Currency'
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setOrderInfo({ ...orderInfo, currency: newValue });
										}}>
										<MenuItem key='CAD' value='CAD'>
											CAD
										</MenuItem>
										<MenuItem key='KRW' value='KRW'>
											KRW
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={2}></Grid>
							<Grid item xs={6}>
								<TextField
									label={t('Order Amount(Total)')}
									required
									fullWidth
									inputProps={{ min: 0, style: { textAlign: 'right' } }}
									value={orderInfo.orderAmount}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, orderAmount: +newValue });
									}}
								/>
							</Grid>
							<Grid item xs={6}></Grid>
							<Grid item xs={6}>
								<TextField
									label={t('Shipping Fee')}
									required
									fullWidth
									value={orderInfo.shippingFee}
									inputProps={{ min: 0, style: { textAlign: 'right' } }}
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setOrderInfo({ ...orderInfo, shippingFee: +newValue });
									}}
								/>
							</Grid>
							<Grid item xs={4}></Grid>
							<Grid item xs={8}>
								<Divider />
							</Grid>
							<Grid item xs={6}></Grid>
							<Grid item xs={6} sx={{ textAlign: 'right' }}>
								<Typography variant='h6' component='div'>
									{t('Total')} :{' '}
									{new Intl.NumberFormat('en-CA', { style: 'currency', currency: orderInfo.currency ? orderInfo.currency : 'CAD' }).format(
										Math.round(((orderInfo.orderAmount ? +orderInfo.orderAmount : 0) + (orderInfo.shippingFee ? +orderInfo.shippingFee : 0)) * 100) / 100
									)}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<LoadingButton variant='outlined' fullWidth onClick={handleClick} loading={loading}>
							{t('Create an order')}
						</LoadingButton>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
};

export default CreateOrderPage;
