import { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, TextField, Grid, Select, MenuItem } from "@mui/material";
import { Search as SearchIcon, SaveOutlined as SaveIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import * as XLSX from "xlsx";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { SAVESHIPPINGNUMBERS_MUTATION } from "../graphql/mutations";
import { saveShippingNumbersMutation, saveShippingNumbersMutationVariables, saveShippingNumbersMutation_saveShippingNumbers_result } from "../graphql_types/saveShippingNumbersMutation";
import CustomTable, { TableColumnDef } from "./customTable";
import { couriersInfoQuery } from "../graphql_types/couriersInfoQuery";
import { COURIERS_INFO_QUERY } from "../graphql/queries";

interface ShippingUploadDialogProps {
	open: boolean;
	close: () => void;
	refetch: () => void;
}

interface IShippingData {
	courierId: number;
	shippingInfo: {
		id: number;
		shippingNo: string;
	}[];
}

export const ShippingUploadDialog: React.FC<ShippingUploadDialogProps> = ({ open = false, close, refetch }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [shippingData, setShippingData] = useState<IShippingData>({ courierId: 0, shippingInfo: [] });
	const [fileName, setFileName] = useState("");
	const [saveResult, setSaveResult] = useState<saveShippingNumbersMutation_saveShippingNumbers_result[] | null>(null);

	const columnDef: TableColumnDef[] = [
		{
			name: "ID",
			datafield: "id",
			headerAlign: "center",
			align: "center",
		},
		{
			name: "Message",
			datafield: "msg",
			headerAlign: "center",
			align: "center",
		},
	];

	const onCompleted = (data: saveShippingNumbersMutation) => {
		const {
			saveShippingNumbers: { success, error, result },
		} = data;

		if (success) {
			setSaveResult(result);
			enqueueSnackbar(`Shipping No. was uploaded!`, { variant: "success", autoHideDuration: 3000 });
			refetch();
		} else {
			enqueueSnackbar(`Cannot save Shipping No...😥 : ${error}`, { variant: "error", autoHideDuration: 3000 });
		}
	};

	const [saveShippingNoMutation, { loading }] = useMutation<saveShippingNumbersMutation, saveShippingNumbersMutationVariables>(SAVESHIPPINGNUMBERS_MUTATION, { onCompleted });
	const { data } = useQuery<couriersInfoQuery>(COURIERS_INFO_QUERY);

	const onSave = () => {
		const variables = { courierId: shippingData.courierId, saveShippingNoInput: shippingData.shippingInfo };
		if (!shippingData.courierId) {
			enqueueSnackbar(`Please select courier`, { variant: "warning", autoHideDuration: 3000 });
			return;
		}
		saveShippingNoMutation({ variables });
	};

	const handleFile = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file) {
			let reader = new FileReader();
			reader.readAsBinaryString(file);
			reader.onloadend = () => {
				const bstr = reader.result;
				const wb = XLSX.read(bstr, { type: "binary" });
				const json = XLSX.utils.sheet_to_json(wb.Sheets["Shipping"]);
				if (json.length === 0) {
					enqueueSnackbar(`Cannot read excel file😥 Check file or format again`, { variant: "warning", autoHideDuration: 3000 });
				} else {
					setShippingData({ ...shippingData, shippingInfo: json.map((data: any) => ({ id: +data.id, shippingNo: `${data.shippingNo}` })) });
				}
			};
			setFileName(file.name);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setFileName("");
			setSaveResult(null);
		}, 500);
	}, [open]);

	return (
		<>
			<Dialog open={open} onClose={close} scroll='paper' maxWidth='sm' fullWidth aria-labelledby='shipping-upload-dialog-title' aria-describedby='shipping-upload-dialog-description'>
				<DialogTitle id='shipping-upload-dialog-title'>Upload Shipping No.</DialogTitle>
				<DialogContent dividers>
					{saveResult && <CustomTable TableData={saveResult} TableColumn={columnDef} />}
					{!saveResult && (
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<Select
									id='order-state-select'
									value={shippingData.courierId}
									fullWidth
									size='small'
									onChange={(e: any) => {
										const {
											target: { value: newValue },
										} = e;
										setShippingData({ ...shippingData, courierId: +newValue });
									}}>
									<MenuItem value='0'>- Select -</MenuItem>
									{data?.couriersInfo?.couriers?.map((courier: any) => {
										if(courier.isActive){
											return (
												<MenuItem value={courier.id} key={courier.id}>
													{courier.name}
												</MenuItem>
											);
										}
									})}
								</Select>
							</Grid>
							<Grid item xs={8}>
								<TextField size='small' fullWidth inputProps={{ readOnly: true }} value={fileName} />
							</Grid>
							<Grid item xs={1}>
								<label htmlFor='contained-button-file'>
									<input id='contained-button-file' type='file' hidden onChange={handleFile} />
									<IconButton component='span'>
										<SearchIcon />
									</IconButton>
								</label>
							</Grid>
						</Grid>
					)}
				</DialogContent>
				<DialogActions>
					{!saveResult && (
						<LoadingButton loading={loading} size='small' loadingPosition='start' onClick={onSave} color='primary' startIcon={<SaveIcon fontSize='small' />}>
							Save
						</LoadingButton>
					)}
					<Button onClick={close} color='error'>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
