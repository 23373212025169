import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuRoutes } from '../routers/menu';
import { ListItemButton, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface MenuItemProps {
	menuProps: MenuRoutes;
}

export const MenuListItem: React.FC<MenuItemProps> = ({ menuProps, ...rest }) => {
	const [collapse, setCollapse] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation();

	const _onClick = (prop: MenuRoutes) => {
		if (prop.views) {
			setCollapse(!collapse);
			return;
		}
		history.push({ pathname: prop.layout + prop.path, state: { pageName: prop.name } });
	};

	return (
		<>
			<ListItemButton
				onClick={() => _onClick(menuProps)}
				sx={{
					backgroundColor: menuProps.layout + menuProps.path === location.pathname ? 'action.selected' : 'transparent',
				}}>
				<ListItemIcon sx={{ minWidth: '36px' }}>{menuProps.icon}</ListItemIcon>
				<ListItemText primary={t(menuProps.name)} primaryTypographyProps={{ fontSize: '0.9em', fontWeight: 600 }} />
				{menuProps.views && (collapse ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
			</ListItemButton>
			{menuProps.views && (
				<Collapse in={collapse} timeout='auto' unmountOnExit>
					<List component='div' disablePadding>
						{menuProps.views.map((view, key2) => {
							if (view.hide) return null;
							return (
								<ListItemButton key={key2} sx={{ pl: 4, backgroundColor: view.layout + view.path === location.pathname ? 'action.selected' : 'transparent' }} onClick={() => _onClick(view)}>
									<ListItemIcon>{view.icon}</ListItemIcon>
									<ListItemText
										primary={t(view.name)}
										primaryTypographyProps={{
											fontSize: '0.8em',
										}}
									/>
								</ListItemButton>
							);
						})}
					</List>
				</Collapse>
			)}
		</>
	);
};
