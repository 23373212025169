import { useState } from 'react';
import { Card, Typography, Box } from '@mui/material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { BEST_SALES_PRODUCT_QUERY } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export const BestSalesProductCard = () => {
	const { data, loading } = useQuery(BEST_SALES_PRODUCT_QUERY, { fetchPolicy: 'no-cache' });
	const { t } = useTranslation();

	const [options, setOptions] = useState<ApexOptions>({
		chart: {
			id: 'best-sales-product',
			type: 'bar',
			stacked: true,
			toolbar: {
				show: false,
			},
		},
		colors: ['#00c73c', '#f43142', '#346aff', '#b30000', '#1d1b1b', '#4421af', '#7c1158'],
		plotOptions: {
			bar: {
				horizontal: true,
				borderRadius: 5,
				dataLabels: {
					position: 'right'
				}
			},
		},
		dataLabels:{
			enabled: true,
			enabledOnSeries: [6],
			textAnchor:'start',
			style: {
				colors:['#000'],
				fontSize:'12px',
				fontWeight: 800
			},
			formatter: function (_val, opt){
				let series = opt.w.config.series;
				let idx = opt.dataPointIndex;
				const total = series.reduce((total:any, self:any) => total + self.data[idx],0);
				return total;
			}
		}
	});

	return (
		<>
			{!loading && (
				<Card sx={{ margin: '8px' }}>
					<Box sx={{ display: 'flex', margin: '8px', justifyContent: 'space-between' }}>
						<Box>
							<Typography variant='h6'>{t(`Best Sales Products`)}</Typography>
						</Box>
						<Typography variant='overline'>{`${new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString().split('T')[0]} - ${
							new Date().toLocaleDateString().split('T')[0]
						}`}</Typography>
					</Box>
					<Chart
						options={{...options, xaxis: {categories: data?.bestSalesProductByMarket?.output?.map((result: any)=>result.productName),labels:{show:true}}}}
						series={[
							{
								name: 'Naver',
								data: data?.bestSalesProductByMarket?.output?.map((result: any) => result.naver)
							},
							{
								name: '11st',
								data: data?.bestSalesProductByMarket?.output?.map((result: any) => result.elevenst)
							},
							{
								name: 'Coupang',
								data: data?.bestSalesProductByMarket?.output?.map((result: any) => result.coupang)
							},
							{
								name: 'Interpark',
								data: data?.bestSalesProductByMarket?.output?.map((result: any) => result.interpark)
							},
							{
								name: 'Gmarket',
								data: data?.bestSalesProductByMarket?.output?.map((result: any) => result.gmarket)
							},
							{
								name: 'Auction',
								data: data?.bestSalesProductByMarket?.output?.map((result: any) => result.auction)
							},
							{
								name: 'Others',
								data: data?.bestSalesProductByMarket?.output?.map((result: any) => result.others)
							},
						]}
						type='bar'
						width='100%'
						height='400'
					/>
				</Card>
			)}
		</>
	);
};
