import { Container, Paper, Card, CardContent, TextField, FormControl, FormLabel, Stack, Select, MenuItem, Grid } from '@mui/material';
import { LocalizationProvider, DatePicker, LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Search as SearchIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useQuery } from '@apollo/client';
import { customersInfoQuery } from '../../graphql_types/customersInfoQuery';
import { CUSTOMERS_INFO_QUERY, PERIOD_INVOICE_QUERY } from '../../graphql/queries';
import { useState } from 'react';
import { InvoiceData, invoiceSampleData, InvoiceTemplate } from '../../components/InvoiceTemplate';
import { PDFViewer } from '@react-pdf/renderer';

export interface invoiceSearchProp {
	customerId?: number | 'select';
	invoiceFrom: Date | null;
	invoiceTo: Date | null;
}

export const PeriodInvoice = () => {
	const toDay = new Date();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { data } = useQuery<customersInfoQuery>(CUSTOMERS_INFO_QUERY);
	const [invoice, setInvoice] = useState<InvoiceData>(invoiceSampleData);

	const [searchData, setSearchData] = useState<invoiceSearchProp>({
		customerId: 'select',
		invoiceFrom: new Date(toDay.getFullYear(), toDay.getMonth() - 1, 1, 0, 0, 0), //first day of last month
		invoiceTo: new Date(toDay.getFullYear(), toDay.getMonth(), 0, 23, 59, 59), //last day of last month
	});

	const onCompleted = async (data: any) => {
		const {
			getPeriodInvoice: { success, invoiceData },
		} = data;

		if (success) {
			console.log(invoiceData);
			//enqueueSnackbar(`Orders are saved!`, { variant: "success", autoHideDuration: 3000 });
			setInvoice(invoiceData);
		} else {
			enqueueSnackbar(t(`Cannot search orders...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [getInvoiceQuery, { loading, refetch }] = useLazyQuery(PERIOD_INVOICE_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const _onSearch = (searchData: any) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: any = searchData;
		if (variables.customerId === 'select') {
			enqueueSnackbar(`Please select the customer`, { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		getInvoiceQuery({ variables });
	};

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Period Invoice')} | PMSK</title>
			</Helmet>
			<Container maxWidth='xl' disableGutters>
				<Card sx={{ marginBottom: '10px' }}>
					<CardContent component='form' onSubmit={_onSearch(searchData)}>
						<Grid container>
							<Grid sm={12} md={3}>
								<FormControl sx={{ marginLeft: '10px' }}>
									<FormLabel component='legend'>{t('Customer')}</FormLabel>
									<Select
										sx={{ marginTop: 1 }}
										id='order-state-select'
										value={searchData.customerId}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setSearchData({ ...searchData, customerId: newValue });
										}}>
										<MenuItem value='select'>{t('Select Customer')}</MenuItem>
										{data?.customersInfo?.customers?.map((customer: any) => {
											return (
												<MenuItem value={customer.id} key={customer.id}>
													{customer.name}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
							<Grid sm={12} md={8}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<FormControl sx={{ marginLeft: '10px' }}>
										<FormLabel component='legend'>{t('Date')}</FormLabel>
										<Stack spacing={3} direction='row' sx={{ marginTop: 1, marginBottom: 1 }}>
											<DatePicker
												label='From'
												openTo='day'
												views={['year', 'month', 'day']}
												value={searchData.invoiceFrom}
												onChange={(newValue: Date | null) => {
													newValue?.setHours(0, 0, 0);
													setSearchData({ ...searchData, invoiceFrom: newValue });
												}}
												renderInput={(params: any) => <TextField {...params} />}
											/>
											<DatePicker
												label='To'
												openTo='day'
												views={['year', 'month', 'day']}
												value={searchData.invoiceTo}
												onChange={(newValue: Date | null) => {
													newValue?.setHours(23, 59, 59);
													setSearchData({ ...searchData, invoiceTo: newValue });
												}}
												renderInput={(params: any) => <TextField {...params} />}
											/>
										</Stack>
									</FormControl>
								</LocalizationProvider>
							</Grid>
							<Grid xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', marginTop: '15px', flexDirection: 'row-reverse' }}>
								<LoadingButton loading={loading} variant='outlined' startIcon={<SearchIcon />} type='submit'>
									Search
								</LoadingButton>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				{invoice !== invoiceSampleData && (
					<PDFViewer width='100%' height='650px'>
						<InvoiceTemplate invoiceData={invoice} />
					</PDFViewer>
				)}
			</Container>
		</Paper>
	);
};
