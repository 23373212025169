import { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar } from '../components/sidebar';
import { Navbar } from '../components/navbar';
import Menus from './menu';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { NotFound } from '../pages/404-error';
import { useQuery } from '@apollo/client';
import { GETPROFILE_QUERY } from '../graphql/queries';
import { getProfileQuery } from '../graphql_types/getProfileQuery';
import Loading from '../components/Loading';
import { userInfoVar } from '../apollo';
import { EditProfile } from '../pages/edit-profile';
const sidebarWidth: number = 200;
//const container = window !== undefined ? () => window().document.body : undefined;

export const LoggedInRouter = () => {
	const [mobileOpen, setMobileOpen] = useState(false);

	const { data, loading } = useQuery<getProfileQuery>(GETPROFILE_QUERY, { fetchPolicy: 'no-cache' });

	const UserMenu = data?.getProfile.role ? Menus[data.getProfile.role] : [];
	userInfoVar(data?.getProfile);

	const _onClick = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			{loading && <Loading />}
			{!loading && (
				<>
					<Sidebar routes={UserMenu} sidebarWidth={sidebarWidth} open={mobileOpen} toggle={_onClick} />
					<Box sx={{ width: '100%' }}>
						<CssBaseline />
						<Navbar sidebarWidth={sidebarWidth} onClick={_onClick} />
						<Box component='main' sx={{ flexGrow: 1, p: 3, paddingTop: 2 }}>
							<Toolbar />
							<Switch>
								{UserMenu.map((prop: any, key: any) => {
									if (prop.views) {
										return prop.views.map((view: any, key2: any) => <Route path={view.layout + view.path} component={view.component} key={key2}></Route>);
									}
									return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
								})}
								<Route path='/edit-profile'>
									<EditProfile />
								</Route>
								<Redirect from='/' to='/orders/manage' exact />
								<Route>
									<NotFound />
								</Route>
								{/* <Redirect from="/main" to="/dashboard" /> */}
							</Switch>
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};
