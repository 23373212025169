import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomOrderTable, { TableColumnDef } from '../../components/customOrderTable';
import { Paper, Container, Box, Button, Menu, MenuItem, Stack } from '@mui/material';
import { AutorenewOutlined as AutorenewIcon, FileDownloadOutlined as DownloadIcon } from '@mui/icons-material';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { searchOrdersQuery, searchOrdersQueryVariables } from '../../graphql_types/searchOrdersQuery';
import { SearchOrdersInput, OrderState, UserRole } from '../../graphql_types/globalTypes';
import { Helmet } from 'react-helmet-async';
import { SEARCHORDERS_QUERY } from '../../graphql/queries';
import { CHANGESTATE_MUTATION, SAVEORDERS_MUTATION } from '../../graphql/mutations';
import { DownloadExcelButton } from '../../components/downloadExcelButton';
import { padStart } from '../../utils';
import { ShippingUploadDialog } from '../../components/shippingUploadDialog';
import React from 'react';
import { userInfoVar } from '../../apollo';
import { SentOrderSearchBar } from '../../components/sentOrdersearchbar';
import { OrderStateChip } from '../../components/orderStateChip';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';

export const SentOrder = () => {
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
	const [openShipDialog, setOpenShipDialog] = useState(false);
	const history = useHistory();
	const userInfo = useReactiveVar(userInfoVar);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('Courier'),
			datafield: 'courier.name',
			align: 'center',
			headerAlign: 'center',
			hideMobile: true,
			customValue: (row: any) => {
				return `${row.courier?.name}`;
			},
		},
		{
			name: t('Category'),
			datafield: 'category',
			align: 'center',
			headerAlign: 'center',
			hideMobile: true,
		},
		{
			name: t('From'),
			datafield: 'from',
			align: 'center',
			headerAlign: 'center',
			hideMobile: true,
		},
		{
			name: t('Name'),
			datafield: 'pccName',
			align: 'center',
			headerAlign: 'center',
			width: 130,
		},
		{
			name: t('Address'),
			datafield: 'addr',
			width: 450,
			hideMobile: true,
		},
		{
			name: t('PCC'),
			datafield: 'PCC',
			style: (row: any) => {
				if (!row.pccResult) return { color: 'warning.main' };
				return {};
			},
		},
		{
			name: t('Order'),
			datafield: 'orderList',
			width: 500,
			customValue: (row: any) => {
				if (row.orderProducts.length > 1) return `${row.orderProducts[0].productName} 외 ${row.orderProducts.length - 1}`;
				return `${row.orderProducts[0]?.productName}`;
			},
			style: (row: any) => {
				let itemCnt = 0;
				for (let i = 0; i < row.orderProducts.length; i++) {
					itemCnt += row.orderProducts[i].qty * row.orderProducts[i].productQty;
				}
				if (itemCnt > 6) return { color: 'warning.main' };
				return {};
			},
			hideMobile: true,
		},
		{
			name: t('Sent Date'),
			datafield: 'sentDate',
			align: 'center',
			headerAlign: 'center',
			customValue: (row: any) => {
				const date = new Date(row.sentDate);
				return `${padStart(date.getMonth() + 1, 2, '0')}-${padStart(date.getDate(), 2, '0')}`;
			},
			hideMobile: true,
		},
	];

	const onCompleted = (data: searchOrdersQuery) => {
		const {
			searchOrder: { success, orders },
		} = data;

		if (success) {
			//console.log(orders);
			//enqueueSnackbar(`Orders are saved!`, { variant: "success", autoHideDuration: 3000 });
			setTableRowData(orders as any);
		} else {
			enqueueSnackbar(t(`Cannot search orders...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onChangeStateCompleted = (data: any) => {
		const {
			changeState: { success, error },
		} = data;

		if (success) {
			refetch(); //searchOrdersQuery
			enqueueSnackbar(t(`Status has been changed`), { variant: 'success', autoHideDuration: 3000 });
		} else {
			enqueueSnackbar(`${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onResendOrderCompleted = (data: any) => {
		const {
			saveOrders: { success, error },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Resend Order made`), { variant: 'success', autoHideDuration: 3000 });
			history.push('/orders/manage');
		} else {
			enqueueSnackbar(`${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [searchOrdersQuery, { loading, refetch }] = useLazyQuery(SEARCHORDERS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [changeStateMutation] = useMutation(CHANGESTATE_MUTATION, {
		onCompleted: onChangeStateCompleted,
	});

	const [resendOrderMuation] = useMutation(SAVEORDERS_MUTATION, {
		onCompleted: onResendOrderCompleted,
	});

	const _onSearch = (searchData: SearchOrdersInput) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: searchOrdersQueryVariables = {
			searchOrderInput: { ...searchData, orderState: OrderState.Sent },
		};
		searchOrdersQuery({ variables });
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setMenuEl(event.currentTarget);
	};

	const closeMenu = () => {
		setMenuEl(null);
	};

	const handleChangeStatus = (to: OrderState) => (event: React.MouseEvent<HTMLElement>) => {
		const ids = checkedVal.map((data) => data.id);
		if (!ids.length) enqueueSnackbar(t(`Check order data to change status!`), { variant: 'warning', autoHideDuration: 3000 });
		// if(confirm('Data Will be changed. Are you sure?')){
		//   changeStateMutation({variables:{changeStateIds:ids, changeStateOrderState:to}});
		// }
		changeStateMutation({ variables: { changeStateIds: ids, changeStateOrderState: to } });
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		setCheckedVal(checkedData);
	};

	const _onResendOrder = () => {
		//console.log(checkedVal);
		if (checkedVal.length !== 1) {
			enqueueSnackbar(t(`Pleas select only 1 order`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}

		//trim unnecessary data
		let resendOrder: any = { ...checkedVal[0], sortOrder: 1, orderState: OrderState.OrderConfirm, shippingNo: '', orderedAt: new Date(), orderAmount: 0, netIncome: 0, shippingFee: 0 };
		delete resendOrder['customer'];
		delete resendOrder['courier'];
		delete resendOrder['id'];

		resendOrderMuation({ variables: { orderInput: [resendOrder] } });
	};

	const _download = (data: any) => (e: React.MouseEvent<HTMLElement>) => {
		//console.log(data);
		const exportData: any[] = [];
		const countData: any[] = [];
		data.forEach((d: any) => {
			for (let i = 0; i < d.orderProducts.length; i++) {
				const productInfo = d.orderProducts[i];
				exportData.push({ from: d.from, productName: productInfo.productName, option: productInfo.option, qty: productInfo.qty, alias: productInfo.alias });
				countData.push({ productName: productInfo.productName, qty: productInfo.qty * productInfo.productQty, alias: productInfo.alias });
			}
		});
		const countResult: any[] = [];
		countData.reduce((res: any, value: any) => {
			if (!res[value.alias]) {
				res[value.alias] = { productName: value.alias, qty: 0 };
				countResult.push(res[value.alias]);
			}
			res[value.alias].qty += value.qty;
			return res;
		}, {});
		countResult.sort((a, b) => {
			return a.productName < b.productName ? -1 : a.productName > b.productName ? 1 : 0;
		});

		const FILE_NAME = `Selling-products.xlsx`;
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(exportData);
		const countSheet = XLSX.utils.json_to_sheet(countResult);

		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.utils.book_append_sheet(wb, countSheet, 'Count');
		XLSX.writeFile(wb, FILE_NAME);
	};

	useEffect(() => {
		const toDay = new Date();
		const fromDay = new Date();
		fromDay.setDate(toDay.getDate() - 7);
		const tempVariables: searchOrdersQueryVariables = {
			searchOrderInput: {
				sentFrom: new Date(fromDay.getFullYear(), fromDay.getMonth(), fromDay.getDate(), 0, 0, 0),
				sentTo: new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59),
				orderState: OrderState.Sent,
			},
		};
		searchOrdersQuery({ variables: tempVariables });
	}, [searchOrdersQuery]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Sent Orders')} | PMSK</title>
			</Helmet>
			<SentOrderSearchBar onSearch={_onSearch} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					{userInfo.role !== UserRole.Staff && (
						<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
							<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
								<Stack direction='row' spacing={1}>
									{(userInfo.role === UserRole.Admin || userInfo.role === UserRole.Manager) && (
										<Button size='small' onClick={_download(checkedVal)}>
											<DownloadIcon fontSize='small' />
											{t('Download')}
										</Button>
									)}
									<DownloadExcelButton data={checkedVal} />
									<Button size='small' onClick={handleMenu} variant='outlined'>
										<AutorenewIcon fontSize='small' />
										{t('Status')}
									</Button>
									<Button size='small' onClick={_onResendOrder} variant='contained'>
										<AutorenewIcon fontSize='small' />
										{t('Re-send')}
									</Button>
									<Menu
										sx={{ mt: '35px' }}
										id='menu-status'
										anchorEl={menuEl}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(menuEl)}
										onClose={closeMenu}>
										{Object.keys(OrderState).map((state: any) => (
											<MenuItem onClick={handleChangeStatus(state)} key={state}>
												<OrderStateChip state={state} size='small' />
											</MenuItem>
										))}
									</Menu>
								</Stack>
							</Stack>
						</Box>
					)}
					<ShippingUploadDialog open={openShipDialog} close={() => setOpenShipDialog(false)} refetch={refetch} />
					<CustomOrderTable
						TableData={tableRowData}
						TableColumn={columnDef}
						loading={loading}
						checkbox
						showOrderDetail
						showTotal
						pagination
						height={450}
						setCheckedValues={getCheckedVal}
						refetch={refetch}
						editDetailInfo
					/>
				</Box>
			</Container>
		</Paper>
	);
};
