import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { dateFormat } from '../utils';

const styles = StyleSheet.create({
	body: {
		padding: 30,
		fontFamily: `Nanum Gothic`,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 80,
	},
	titleWraper: {},
	title: {
		fontSize: 24,
		textAlign: 'left',
		fontWeight: 800,
	},
	underline: {
		width: '100%',
		borderBottom: 1,
	},
	subtitle: {
		fontSize: 13,
		fontWeight: 600,
	},
	subtitle2: {
		fontSize: 12,
		width: '20%',
		fontWeight: 800,
		paddingVertical: 3,
	},
	info: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 3,
	},
	infofrom: {
		width: '50%',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	infoto: {
		width: '50%',
	},
	totalInfoContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	totalInfo: {
		width: '40%',
		fontSize: 12,
		marginTop: 30,
		fontWeight: 800,
	},
	total: {
		borderTop: 1,
		fontSize: 13,
		fontWeight: 800,
		padding: 5,
	},
	text: {
		margin: 3,
		fontSize: 10,
		textAlign: 'justify',
	},
	table: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 10,
	},
	tableHeader: {
		fontSize: 13,
		fontWeight: 600,
		backgroundColor: '#f0f0f0',
	},
	tableRow: {
		display: 'flex',
		flexDirection: 'row',
		borderTop: 1,
		borderTopColor: '#f0f0f0',
		borderBottom: 1,
		borderBottomColor: '#f0f0f0',
	},
	tableCell: {
		display: 'flex',
		padding: 6,
		flexGrow: 1,
		justifyContent: 'center',
		alignContent: 'center',
		flexWrap: 'wrap',
		fontSize: 9,
	},
	col0: {
		width: '10%',
	},
	col1: {
		width: '35%',
	},
	col2: {
		width: '15%',
	},
	col3: {
		width: '10%',
	},
	col4: {
		width: '5%',
	},
	col5: {
		width: '10%',
	},
	alignCenter: {
		textAlign: 'center',
	},
	colorGray: {
		color: '#a29f9f',
	},
	middle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
});

Font.register({
	family: 'Nanum Gothic',
	fonts: [
		{ src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf' },
		{ src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Bold.ttf', fontWeight: 600 },
		{ src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf', fontWeight: 800 },
	],
});

interface InvoiceTemplateProps {
	invoiceData?: InvoiceData;
}

export type InvoiceData = {
	orderNo: string;
	companyAddr: string;
	companyMobileNo: string;
	companyEmail: string;
	billTo: string;
	billAddr: string;
	billMobilNo: string;
	billEmail: string;
	invoiceFrom: Date | string;
	invoiceTo: Date | string;
	orderData: {
		invoiceDate: Date;
		shippingFee: number;
		currency: string;
		productName: string;
		brand: string;
		qty: number;
		unitPrice: number;
	}[];
};

export const invoiceSampleData = {
	orderNo: 'TEST001',
	companyAddr: '7181 Yonge st. unit #38, Markham ON L3T0C7',
	companyMobileNo: '+1-647-797-2348',
	companyEmail: 'info@canadavitamin.net',
	billTo: 'TEST Company',
	billAddr: '경기도 남양주시 화도읍 창현로 60',
	billMobilNo: '010-5832-6094',
	billEmail: 'rorm6094@gmail.com',
	invoiceFrom: new Date(),
	invoiceTo: new Date(),
	orderData: [
		{
			invoiceDate: new Date(),
			productName: 'Tart Cherry 120Caps',
			brand: 'Inno Nutrition',
			qty: 1,
			unitPrice: 25,
			shippingFee: 9,
			currency: 'CAD',
		},
		{
			invoiceDate: new Date(),

			productName: 'Albumin 200Caps',
			brand: 'Inno Nutrition',
			qty: 1,
			unitPrice: 84,
			shippingFee: 0,
			currency: 'CAD',
		},
		{
			invoiceDate: new Date(),
			productName: 'Cissus X20 120Caps',
			brand: 'Inno Nutrition',
			qty: 1,
			unitPrice: 18,
			shippingFee: 9,
			currency: 'CAD',
		},
		{
			invoiceDate: new Date(),
			productName: 'Chondroitine 120Caps',
			brand: 'Inno Nutrition',
			qty: 1,
			unitPrice: 10,
			shippingFee: 9,
			currency: 'CAD',
		},
	],
};

export const InvoiceTemplate: React.FC<InvoiceTemplateProps> = ({ invoiceData = invoiceSampleData }) => {
	let subTotal = 0;
	let shippingTotal = 0;
	let isSameDate = invoiceData.invoiceFrom === invoiceData.invoiceTo;

	invoiceData.orderData.forEach((product) => {
		subTotal += product.unitPrice * product.qty;
		shippingTotal += product.shippingFee;
	});
	return (
		<Document title={`${invoiceData.billTo}_${Date.now()}`}>
			{invoiceData.orderData.length > 0 ? (
				<Page style={styles.body}>
					<View style={styles.titleContainer}>
						<View style={styles.titleWraper}>
							<Text style={styles.title} fixed>
								INVOICE
							</Text>
							{invoiceData.orderNo && (
								<Text style={[styles.subtitle, styles.colorGray]} fixed>
									{`#${invoiceData.orderNo}`}
								</Text>
							)}
						</View>
						<View style={styles.titleWraper}>
							<Text style={[styles.subtitle]}>{isSameDate ? `${dateFormat(invoiceData.invoiceFrom)}` : `${dateFormat(invoiceData.invoiceFrom)} ~ ${dateFormat(invoiceData.invoiceTo)}`}</Text>
						</View>
					</View>
					<View style={styles.info}>
						<View style={styles.infofrom}>
							<Text style={styles.text}>{invoiceData.companyAddr}</Text>
							<Text style={styles.text}>{invoiceData.companyMobileNo}</Text>
							<Text style={styles.text}>{invoiceData.companyEmail}</Text>
						</View>

						<View style={styles.infoto}>
							<View style={[styles.info, styles.underline]}>
								<Text style={styles.subtitle}>Bill To</Text>
								<Text style={styles.text}>{invoiceData.billTo}</Text>
							</View>

							<Text style={styles.text}>{invoiceData.billAddr}</Text>
							<Text style={styles.text}>{invoiceData.billMobilNo}</Text>
							<Text style={styles.text}>{invoiceData.billEmail}</Text>
						</View>
					</View>

					<View style={styles.table}>
						<View style={styles.tableRow}>
							{!isSameDate && <Text style={[styles.tableHeader, styles.tableCell, styles.col0]}>Invoice Date</Text>}
							<Text style={[styles.tableHeader, styles.tableCell, styles.col1]}>Product Name</Text>
							<Text style={[styles.tableHeader, styles.tableCell, styles.alignCenter, styles.col2]}>Brand</Text>
							<Text style={[styles.tableHeader, styles.tableCell, styles.alignCenter, styles.col3]}>UnitPrice</Text>
							<Text style={[styles.tableHeader, styles.tableCell, styles.alignCenter, styles.col4]}>Qty</Text>
							<Text style={[styles.tableHeader, styles.tableCell, styles.alignCenter, styles.col5]}>Total</Text>
							{!isSameDate && <Text style={[styles.tableHeader, styles.tableCell, styles.col5]}>Shipping</Text>}
						</View>
						{invoiceData.orderData.map((productData) => (
							<View style={styles.tableRow}>
								{!isSameDate && <Text style={[styles.tableCell, styles.col0]}>{dateFormat(productData.invoiceDate)}</Text>}
								<Text style={[styles.tableCell, styles.col1]}>{productData.productName}</Text>
								<Text style={[styles.tableCell, styles.alignCenter, styles.col2]}>{productData.brand}</Text>
								<Text style={[styles.tableCell, styles.alignCenter, styles.col3]}>
									{new Intl.NumberFormat('en-CA', { style: 'currency', currency: productData.currency }).format(Math.round(productData.unitPrice * 100) / 100)}
								</Text>
								<Text style={[styles.tableCell, styles.alignCenter, styles.col4]}>{productData.qty}</Text>
								<Text style={[styles.tableCell, styles.alignCenter, styles.col5]}>
									{new Intl.NumberFormat('en-CA', { style: 'currency', currency: productData.currency }).format(Math.round(productData.unitPrice * productData.qty * 100) / 100)}
								</Text>
								{!isSameDate && (
									<Text style={[styles.tableCell, styles.alignCenter, styles.col5]}>
										{new Intl.NumberFormat('en-CA', { style: 'currency', currency: productData.currency }).format(Math.round(productData.shippingFee * 100) / 100)}
									</Text>
								)}
							</View>
						))}
					</View>
					<View style={styles.totalInfoContainer}>
						<View style={styles.totalInfo}>
							<View style={styles.info}>
								<Text>SUBTOTAL </Text>
								<Text>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: invoiceData.orderData[0].currency }).format(Math.round(subTotal * 100) / 100)}</Text>
							</View>
							<View style={styles.info}>
								<Text>Total Shipping</Text>
								<Text>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: invoiceData.orderData[0].currency }).format(Math.round(shippingTotal * 100) / 100)}</Text>
							</View>
							<View style={styles.info}>
								<Text>TAX </Text>
								<Text>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: invoiceData.orderData[0].currency }).format(Math.round(0 * 100) / 100)}</Text>
							</View>
							<View style={[styles.info, styles.total]}>
								<Text>TOTAL </Text>
								<Text>{new Intl.NumberFormat('en-CA', { style: 'currency', currency: invoiceData.orderData[0].currency }).format(Math.round((subTotal + shippingTotal) * 100) / 100)}</Text>
							</View>
						</View>
						<View style={styles.subtitle2}>
							<Text>THANK YOU FOR YOUR BUSSINESS!</Text>
						</View>
					</View>
				</Page>
			) : (
				<Page>
					<View style={styles.middle}>
						<Text>There is no data...</Text>
					</View>
				</Page>
			)}
		</Document>
	);
};
