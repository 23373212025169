import { useState, useEffect } from 'react';
import { Paper, Container, Box, Button } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { SearchProductsInput } from '../../graphql_types/globalTypes';
import { Helmet } from 'react-helmet-async';
import { SEARCHPRODUCTS_QUERY } from '../../graphql/queries';
import { SimpleSearchBar } from '../../components/simpleSearchBar';
import { searchProductsQuery, searchProductsQueryVariables } from '../../graphql_types/searchProductsQuery';
import CustomTable, { TableColumnDef } from '../../components/customTable';
import { ProductData, ProductDialog } from '../../components/productDialog';
import { DELETEPRODUCTS_MUTATION } from '../../graphql/mutations';
import { deleteProductsMutation, deleteProductsMutationVariables } from '../../graphql_types/deleteProductsMutation';
import { useTranslation } from 'react-i18next';

export const ManageProducts = () => {
	const [tableRowData, setTableRowData] = useState<any[]>([]);
	const [checkedVal, setCheckedVal] = useState<any[]>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [productDetail, setProductDetail] = useState<ProductData>({
		productName: '',
		alias: '',
		brand: '',
		barcode: '',
		unitPrice: 0,
		reportableUnitPrice: 0,
		cost: 0,
		productWeight: 0,
		productQty: 0,
	});
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('Product Name'),
			datafield: 'productName',
			headerAlign: 'center',
			align: 'center',
		},
		{
			name: t('Brand'),
			datafield: 'brand',
		},
		{
			name: t('UPC'),
			datafield: 'barcode',
		},
	];

	const onCompleted = (data: searchProductsQuery) => {
		const {
			searchProducts: { success, products },
		} = data;

		if (success) {
			setTableRowData(products as any);
		} else {
			enqueueSnackbar(`Cannot search producs...😥`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const onDeleteCompleted = (data: deleteProductsMutation) => {
		const {
			deleteProducts: { success },
		} = data;

		if (success) {
			enqueueSnackbar(`Courier Deleted`, { variant: 'error', autoHideDuration: 3000 });
			refetch();
		} else {
			enqueueSnackbar(`Cannot delete couriers...😥`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [searchProductsQuery, { loading, refetch }] = useLazyQuery<searchProductsQuery, searchProductsQueryVariables>(SEARCHPRODUCTS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [deleteProductsMutation] = useMutation<deleteProductsMutation, deleteProductsMutationVariables>(DELETEPRODUCTS_MUTATION, { onCompleted: onDeleteCompleted });

	const _onSearch = (searchData: SearchProductsInput) => (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const variables: searchProductsQueryVariables = {
			searchProductsInput: { ...searchData },
		};
		searchProductsQuery({ variables });
	};

	const getCheckedVal = (values: any[]) => {
		const checkedData = tableRowData.filter((data) => values.includes(data.id));
		setCheckedVal(checkedData);
	};

	const addProduct = () => {
		setOpenDialog(true);
	};

	const removeProduct = () => {
		const checkedIds = checkedVal.map((value) => value.id);
		const variables: deleteProductsMutationVariables = {
			deleteProductsInput: checkedIds,
		};
		deleteProductsMutation({ variables });
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTimeout(() => {
			setProductDetail({
				productName: '',
				alias: '',
				brand: '',
				barcode: '',
				unitPrice: 0,
				reportableUnitPrice: 0,
				cost: 0,
				productWeight: 0,
				productQty: 0,
			});
		}, 500);
	};

	const getDetailData = (value: any) => {
		setProductDetail(value);
	};

	useEffect(() => {
		const tempVariables: searchProductsQueryVariables = {
			searchProductsInput: {
				name: '',
			},
		};
		searchProductsQuery({ variables: tempVariables });
	}, [searchProductsQuery]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Product Management')} | PMSK</title>
			</Helmet>
			<SimpleSearchBar onSearch={_onSearch} />
			<Container maxWidth='xl' disableGutters>
				<Box component='div' sx={{ marginTop: 1 }}>
					<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
						<Button size='small' variant='outlined' color='error' onClick={removeProduct}>
							<RemoveIcon fontSize='small' />
							{t('Remove')}
						</Button>
						<Button size='small' variant='outlined' color='primary' onClick={addProduct}>
							<AddIcon fontSize='small' />
							{t('Add')}
						</Button>
					</Box>
					<CustomTable
						TableData={tableRowData}
						TableColumn={columnDef}
						loading={loading}
						checkbox
						showTotal
						showDetail
						pagination
						height={550}
						setCheckedValues={getCheckedVal}
						detailDialog={{ setDetailData: getDetailData, openDialog: () => setOpenDialog(true) }}
					/>
					<ProductDialog open={openDialog} close={handleCloseDialog} refetch={refetch} editData={productDetail} />
				</Box>
			</Container>
		</Paper>
	);
};
