import { useState } from 'react';
import { Card, Typography, Box } from '@mui/material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { MONTHLY_SALES_AMOUNT_BY_MARKET_QUERY } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { TrendChip } from './trendChip';
import { useTranslation } from 'react-i18next';

export const MonthlySalesOpenMarketCard = () => {
	const { data, loading } = useQuery(MONTHLY_SALES_AMOUNT_BY_MARKET_QUERY, { fetchPolicy: 'no-cache' });
	const { t } = useTranslation();

	const [options, setOptions] = useState<ApexOptions>({
		chart: {
			id: 'monthly-sales-bar-open-market',
			toolbar: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				formatter: (value: any) => {
					return '₩ ' + Intl.NumberFormat('en-US').format(value);
				},
			},
		},
		dataLabels: {
			formatter: (val: any, opt: any) => {
				return Intl.NumberFormat('en-US').format(val);
			},
		},
		colors: ['#00c73c', '#f43142', '#346aff', '#b30000', '#1d1b1b', '#4421af', '#7c1158'],
		plotOptions: {
			bar: {
				distributed: true,
				borderRadius: 5,
			},
		},
	});

	return (
		<>
			{!loading && (
				<Card sx={{ margin: '8px' }}>
					<Box sx={{ display: 'flex', margin: '8px', justifyContent: 'space-between' }}>
						<Box>
							<Typography variant='h6'>{t(`Monthly Sales Amount`)}(Open Market, KRW)</Typography>
							{data?.monthlySalesAmountByMarket?.total && (
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography variant='h4' sx={{ marginLeft: '8px' }}>
										₩ {Intl.NumberFormat('en-US').format(data?.monthlySalesAmountByMarket?.total?.current)}
									</Typography>
									<TrendChip current={data?.monthlySalesAmountByMarket?.total?.current} last={data?.monthlySalesAmountByMarket?.total?.lastMonth} />
								</Box>
							)}
						</Box>
						<Typography variant='overline'>{`${new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString().split('T')[0]} - ${
							new Date().toLocaleDateString().split('T')[0]
						}`}</Typography>
					</Box>
					<Chart
						options={options}
						series={[
							{
								name: 'Sales Amount(KRW)',
								data: data?.monthlySalesAmountByMarket?.output?.map((result: any) => ({ x: result.market, y: result.salesAmount })),
							},
						]}
						type='bar'
						width='100%'
						height='400'
					/>
				</Card>
			)}
		</>
	);
};
