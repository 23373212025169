import React from 'react';
import { Chip } from '@mui/material';
import { OrderState } from '../graphql_types/globalTypes';
import { useTranslation } from 'react-i18next';

export const getStateColor = (state: OrderState) => {
	switch (state) {
		case OrderState.Canceled:
			return 'error';
		case OrderState.OrderConfirm:
			return 'info';
		case OrderState.ShipReady:
			return 'secondary';
		case OrderState.Shipped:
			return 'primary';
		case OrderState.Sent:
			return 'warning';
		default:
			return undefined;
	}
};
interface OrderStateChipProps {
	id?: number;
	state: OrderState;
	size?: 'small' | 'medium' | undefined;
}
export const OrderStateChip: React.FC<OrderStateChipProps> = ({ id, state, size }) => {
	const { t } = useTranslation();
	return <Chip key={id} color={getStateColor(state)} label={t(state)} size={size} variant='outlined' />;
};
