import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { authTokenVar, isLoggedInVar, userInfoVar } from '../apollo';
import { LS_TOKEN } from '../constant';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Divider, ListItemIcon } from '@mui/material';
import { Menu as MenuIcon, AccountCircle, Logout } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LanguageChangeDropDown } from './languageChangeDropDown';

interface NavbarProps {
	sidebarWidth: number;
	onClick: any;
}

interface ILocationProp {
	pageName?: string;
}

export const Navbar: React.FC<NavbarProps> = ({ sidebarWidth, onClick, ...rest }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const history = useHistory();
	const location = useLocation<ILocationProp>();
	const { t } = useTranslation();

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const _logout = () => {
		localStorage.removeItem(LS_TOKEN);
		authTokenVar('');
		isLoggedInVar(false);
		userInfoVar(undefined);
		history.replace('/login');
	};

	return (
		<AppBar
			position='fixed'
			sx={{
				width: { lg: `calc(100% - ${sidebarWidth}px)` },
				ml: { lg: `${sidebarWidth}px` },
			}}>
			<Toolbar>
				<IconButton color='inherit' aria-label='open drawer' edge='start' onClick={onClick} sx={{ mr: 2, display: { lg: 'none' } }}>
					<MenuIcon />
				</IconButton>
				<Typography component='div' sx={{ flexGrow: 1 }} variant='h5'>
					{location.state?.pageName ? t(location.state?.pageName) : ''}
				</Typography>
				<LanguageChangeDropDown />
				<IconButton size='large' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleMenu} color='inherit'>
					<AccountCircle />
				</IconButton>
				<Menu
					sx={{ mt: '35px' }}
					id='menu-appbar'
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(anchorEl)}
					onClose={handleClose}>
					<Link to='/edit-profile'>
						<MenuItem>
							<Avatar sx={{ width: 24, height: 24, mr: 2 }} />
							{t('My Account')}
						</MenuItem>
					</Link>
					<Divider />
					<MenuItem onClick={_logout}>
						<ListItemIcon>
							<Logout />
						</ListItemIcon>
						{t('logout')}
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
};
