import React, { useState, useEffect, forwardRef, ReactElement, Ref, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { ReplayOutlined as ReturnIcon } from '@mui/icons-material';
import { Dialog, DialogContent, Card, CardContent, Grid, TextField, Table, TableHead, TableBody, TableRow, TableCell, Typography, Slide, Box, CircularProgress, Collapse } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { TransitionProps } from '@mui/material/transitions';
import { SubdirectoryArrowLeft as BackIcon, FlightTakeoffOutlined as ShippingModeIcon } from '@mui/icons-material';
import { SEARCHSHIPPINGORDERS_QUERY } from '../../graphql/queries';
import { OrderData } from '../../components/orderDetailDialog';
import { OrderState } from '../../graphql_types/globalTypes';
import { SwipeableList, SwipeableListItem, ActionAnimations } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { CHANGESTATE_MUTATION } from '../../graphql/mutations';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { OrderStateChip } from '../../components/orderStateChip';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.body}`]: {
		fontSize: 'larger',
	},
}));

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: ReactElement;
	},
	ref: Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

export const ShippingMode = () => {
	const [orderData, setOrderData] = useState<OrderData[]>([]);
	const [shippingNo, setShippingNo] = useState('');
	const history = useHistory();
	const inputRef = createRef<HTMLInputElement>();
	const { t } = useTranslation();

	const onCompleted = (data: any) => {
		if (data) {
			const {
				searchShippingOrders: { success, orders },
			} = data;
			if (success) {
				setOrderData(orders as any);
			}
		}
		setShippingNo('');
	};

	const onCompletedChangeStatus = (id: any) => (data: any) => {
		const {
			changeState: { success },
		} = data;
		if (success) {
			setOrderData(orderData.filter((singleOrder) => singleOrder.id !== id));
		}
	};

	const [searchShippingOrdersQuery, { loading, data }] = useLazyQuery(SEARCHSHIPPINGORDERS_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [changeStateMutation] = useMutation(CHANGESTATE_MUTATION);

	const returnAction = (id: any) => {
		changeStateMutation({
			variables: { changeStateIds: [id], changeStateOrderState: OrderState.ShipReady },
			onCompleted: onCompletedChangeStatus(id),
		});
	};

	const handleClose = () => {
		history.goBack();
	};

	const handleAutoFocus = (e: React.MouseEvent) => {
		if (inputRef.current !== null) inputRef.current.focus();
	};

	const handleKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			searchShippingOrdersQuery({ variables: { searchShippingOrdersInput: { shippingNo } } });
		}
	};

	useEffect(() => {
		searchShippingOrdersQuery({ variables: { searchShippingOrdersInput: { shippingNo } } });
	}, [searchShippingOrdersQuery]);

	const threshold = 0.4;

	return (
		<Dialog
			fullScreen
			open={true}
			scroll='paper'
			maxWidth='md'
			aria-labelledby='order-detail-dialog-title'
			aria-describedby='order-detail-dialog-description'
			TransitionComponent={Transition}
			onClick={handleAutoFocus}>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
						<ShippingModeIcon /> {t('Shipping Mode')}
					</Typography>
					<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
						<BackIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Grid container spacing={1} sx={{ height: '100%' }}>
					<Grid item xs={1} md={2} xl={3}></Grid>
					<Grid item xs={10} md={8} xl={6}>
						<TextField
							label={t('Shipping Number')}
							fullWidth
							value={shippingNo}
							inputRef={inputRef}
							onKeyUp={handleKeyUp}
							onChange={(e) => {
								const {
									target: { value: newValue },
								} = e;
								setShippingNo(newValue);
							}}></TextField>
					</Grid>
					<Grid item xs={1} md={2} xl={3}></Grid>
					<Grid item xs={12}>
						{loading && (
							<Card sx={{ height: '65vh', margin: '5px 0', backgroundColor: '#C0C1C3' }} variant='outlined'>
								<CardContent sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<CircularProgress size={80} />
								</CardContent>
							</Card>
						)}
						{!loading && orderData.length === 0 && (
							<Collapse key='noData' timeout={1500} in>
								<Card sx={{ height: '65vh', margin: '5px 0', backgroundColor: '#C0C1C3' }} variant='outlined'>
									<CardContent sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{t(`Oops, There hasn't any data😂`)}</CardContent>
								</Card>
							</Collapse>
						)}
						{!loading && !data?.searchShippingOrders?.success && (
							<Collapse key='noData' timeout={1500} in>
								<Card sx={{ height: '65vh', margin: '5px 0', backgroundColor: '#FFC4DD' }} variant='outlined'>
									<CardContent sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '2rem' }}>{data?.searchShippingOrders?.error}</CardContent>
								</Card>
							</Collapse>
						)}
						{!loading && data?.searchShippingOrders?.success && (
							<SwipeableList threshold={threshold}>
								<TransitionGroup>
									{orderData.slice(0, 1).map((currentOrderData, id) => (
										<Collapse key={currentOrderData.id} timeout={1500}>
											<SwipeableListItem
												swipeLeft={{
													content: (
														<Box
															sx={{
																backgroundColor: 'warning.main',
																color: 'secondary.contrastText',
																height: '98%',
																width: '100%',
																display: 'flex',
																flexDirection: 'column',
																justifyContent: 'center',
																alignItems: 'flex-end',
																padding: 2,
															}}>
															<ReturnIcon sx={{ fontSize: 50 }} />
															<Box sx={{ marginLeft: 1 }}>{t('Return to')}</Box>
															<Box sx={{ marginLeft: 1 }}>{t('ShippReady')}</Box>
														</Box>
													),
													actionAnimation: ActionAnimations.REMOVE,
													action: () => returnAction(currentOrderData.id),
												}}
												key={id}
												threshold={threshold}>
												{/* <div>{currentOrderData.id}, {currentOrderData.pccName}</div> */}
												<Card sx={{ height: '65vh', margin: '5px 0', backgroundColor: data?.searchShippingOrders?.itemOver ? '#FFC4DD' : '#B2C5DA' }} variant='outlined' key={id}>
													<Box sx={{ position: 'absolute', bottom: 15, right: 15, fontWeight: 700 }}>
														<OrderStateChip state={currentOrderData.orderState as OrderState} size='small' />
													</Box>
													{data?.searchShippingOrders?.itemOver && (
														<Box sx={{ position: 'absolute', bottom: 40, right: 15, fontWeight: 700 }}>This order may over 6 items or 150 USD with other order. Please check it again</Box>
													)}
													<PerfectScrollbar>
														<CardContent>
															<Grid container spacing={2}>
																<Grid item xs={12}>
																	<Typography variant='h6'>
																		{t('Shipping Number')} : {currentOrderData?.shippingNo}
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<Typography variant='h6'>{t('Items')}</Typography>
																	<Table>
																		<TableHead>
																			<TableRow>
																				<StyledTableCell align='center'>{t('No.')}</StyledTableCell>
																				<StyledTableCell>{t('Product Name')}</StyledTableCell>
																				<StyledTableCell>{t('Brand')}</StyledTableCell>
																				<StyledTableCell>{t('Option')}</StyledTableCell>
																				<StyledTableCell sx={{ width: 50, textAlign: 'center' }}>{t('Product Qty')}</StyledTableCell>
																				<StyledTableCell sx={{ width: 30, textAlign: 'center' }}>{t('Qty')}</StyledTableCell>
																				<StyledTableCell sx={{ width: 30, textAlign: 'center' }}>{t('Total Qty')}</StyledTableCell>
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{currentOrderData?.orderProducts?.map((product, idx) => {
																				const totalQty = product.qty && product.productQty ? product.qty * product.productQty : 0;
																				return (
																					<TableRow key={idx}>
																						<StyledTableCell align='center'>{idx + 1}</StyledTableCell>
																						<StyledTableCell>{product.productName}</StyledTableCell>
																						<StyledTableCell>{product.brand}</StyledTableCell>
																						<StyledTableCell>{product.option}</StyledTableCell>
																						<StyledTableCell sx={{ width: 30, textAlign: 'center' }}>{product.productQty}</StyledTableCell>
																						<StyledTableCell sx={{ width: 30, textAlign: 'center' }}>{product.qty}</StyledTableCell>
																						<StyledTableCell
																							sx={{ width: 30, textAlign: 'center', fontWeight: totalQty > 1 ? 600 : 400, color: totalQty > 1 ? 'red' : 'black', fontSize: 'x-large !important' }}>
																							{totalQty}
																						</StyledTableCell>
																					</TableRow>
																				);
																			})}
																		</TableBody>
																	</Table>
																</Grid>
																<Grid item xs={12} md={6}>
																	<Grid container spacing={3}>
																		<Grid item xs={6}>
																			<TextField
																				label={t('PCC Name')}
																				value={currentOrderData?.pccName}
																				variant='standard'
																				color={typeof currentOrderData.pccResult === 'undefined' ? undefined : currentOrderData.pccResult ? 'primary' : 'error'}
																				focused
																				inputProps={{ readOnly: true }}
																			/>
																		</Grid>
																		<Grid item xs={6}>
																			<TextField
																				label={t('PCC')}
																				value={currentOrderData?.PCC}
																				variant='standard'
																				color={typeof currentOrderData.pccResult === 'undefined' ? undefined : currentOrderData.pccResult ? 'primary' : 'error'}
																				focused
																				inputProps={{ readOnly: true }}
																			/>
																		</Grid>
																		<Grid item xs={2}>
																			<TextField label={t('Postal Code')} value={currentOrderData?.postal} variant='standard' color='primary' focused inputProps={{ readOnly: true }} />
																		</Grid>
																		<Grid item xs={10}>
																			<TextField label={t('Address')} value={currentOrderData?.addr} variant='standard' fullWidth color='primary' focused inputProps={{ readOnly: true }} />
																		</Grid>
																	</Grid>
																</Grid>
																<Grid item xs={12} md={6}>
																	<Grid container spacing={3}>
																		<Grid item xs={12}>
																			<TextField label={t('Delivery Message')} value={currentOrderData?.msg} variant='standard' fullWidth color='primary' focused inputProps={{ readOnly: true }} />
																		</Grid>
																		<Grid item xs={12}>
																			<TextField label={t('Comment')} value={currentOrderData?.comment} fullWidth color='primary' focused inputProps={{ readOnly: true }} />
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>
														</CardContent>
													</PerfectScrollbar>
												</Card>
											</SwipeableListItem>
										</Collapse>
									))}
								</TransitionGroup>
							</SwipeableList>
						)}
					</Grid>
					<Grid item xs={1}></Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
