import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Button, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from '@apollo/client';
import { SAVEUSER_MUTATION } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { UserRole } from '../graphql_types/globalTypes';
import { useTranslation } from 'react-i18next';

interface AddUserDialogProps {
	open: boolean;
	close: () => void;
	refetch: () => void;
	editData?: UserData;
}

export interface UserData {
	id?: number;
	email: string;
	firstName: string;
	lastName: string;
	role: UserRole;
	password?: string;
	confirmPassword?: string;
	isActive: boolean;
}

export const UserDialog: React.FC<AddUserDialogProps> = ({ open = false, close, refetch, editData }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [userData, setUserData] = useState<UserData>({
		email: '',
		firstName: '',
		lastName: '',
		role: UserRole.Staff,
		isActive: true,
	});
	const { t } = useTranslation();

	const onCompleted = (data: any) => {
		const {
			saveUser: { success, error },
		} = data;

		if (success) {
			enqueueSnackbar(t(`User information was saved!`), { variant: 'success', autoHideDuration: 3000 });
			refetch();
			close();
		} else {
			enqueueSnackbar(`${t(`Cannot save user information...😥`)} : ${error}`, { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [saveUserMutation, { loading }] = useMutation(SAVEUSER_MUTATION, { onCompleted });

	const onSave = () => {
		const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!emailPattern.test(userData.email)) {
			enqueueSnackbar(t(`Please check email again`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		if (userData.password !== userData.confirmPassword) {
			enqueueSnackbar(t(`Please check password again`), { variant: 'warning', autoHideDuration: 3000 });
			return;
		}
		const variables = {
			saveUserInput: {
				id: userData.id,
				email: userData.email,
				firstName: userData.firstName,
				lastName: userData.lastName,
				role: userData.role,
				password: userData.password,
				isActive: userData.isActive,
			},
		};
		saveUserMutation({ variables });
	};

	useEffect(() => {
		if (editData) {
			setUserData(editData);
		}
	}, [editData]);

	return (
		<>
			<Dialog open={open} onClose={close} scroll='paper' maxWidth='md' aria-labelledby='courier-detail-dialog-title' aria-describedby='courier-detail-dialog-description'>
				<DialogTitle id='courier-detail-dialog-title'>{t('User Information')}</DialogTitle>
				<PerfectScrollbar>
					<DialogContent dividers>
						<PerfectScrollbar>
							<Grid container spacing={2} sx={{ marginTop: 1 }}>
								<Grid item xs={6}>
									<TextField
										type='email'
										label={t('E-mail')}
										fullWidth
										value={userData.email}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setUserData({ ...userData, email: newValue });
										}}
										required
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<InputLabel id='roleLabel'>{t('Role')}</InputLabel>
										<Select
											labelId='roleLabel'
											label={t('Role')}
											value={userData.role}
											onChange={(e: any) => {
												const {
													target: { value: newValue },
												} = e;
												setUserData({ ...userData, role: newValue });
											}}>
											{Object.keys(UserRole).map((role, idx) => (
												<MenuItem value={role} key={idx}>
													{role}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<TextField
										type='password'
										label={t('Password')}
										fullWidth
										value={userData.password}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setUserData({ ...userData, password: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										type='password'
										label={t('Confirm Password')}
										fullWidth
										value={userData.confirmPassword}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setUserData({ ...userData, confirmPassword: newValue });
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('First Name')}
										fullWidth
										value={userData.firstName}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setUserData({ ...userData, firstName: newValue });
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										label={t('Last Name')}
										fullWidth
										value={userData.lastName}
										onChange={(e: any) => {
											const {
												target: { value: newValue },
											} = e;
											setUserData({ ...userData, lastName: newValue });
										}}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox checked={userData.isActive} />}
											label={t('Active')}
											onChange={(e: any) => {
												setUserData({ ...userData, isActive: !userData.isActive });
											}}
										/>
									</FormGroup>
								</Grid>
							</Grid>
						</PerfectScrollbar>
					</DialogContent>
				</PerfectScrollbar>
				<DialogActions>
					<LoadingButton loading={loading} onClick={onSave} color='primary'>
						{t('Save')}
					</LoadingButton>
					<Button onClick={close} color='error'>
						{t('Close')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
