import { Paper, Typography, Container, Box, Skeleton, Stack, Chip, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import CustomOrderTable, { TableColumnDef } from '../../components/customOrderTable';
import { SaveAlt as SaveAltIcon, CleaningServicesOutlined as CleaningServicesIcon, PrintOutlined as PrintIcon } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import { saveOrdersMutation, saveOrdersMutationVariables } from '../../graphql_types/saveOrdersMutation';
import { OrderInputType, OrderCategory } from '../../graphql_types/globalTypes';
import { delete_cols, phoneFomatter, filterKoreanName } from '../../utils';
import { Helmet } from 'react-helmet-async';
import { SAVEORDERS_MUTATION } from '../../graphql/mutations';
import { CUSTOMERS_INFO_QUERY } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';

const FILENAME_NAVER = '스마트스토어';
const FILENAME_11ST = 'LOGISTICS';
const FILENAME_COUPANG = 'DELIVERYLIST';
const FILENAME_WEMAKEPRICE = 'ORDERLIST';
const FILENAME_INTERPARK = '주문_발송관리';
const FILENAME_INTERPARK2 = '전체주문내역';
const FILENAME_GMARKET = 'GENERALDELIVERY';
const FILENAME_PLAYAUTO = '신규주문';
const FILENAME_PLAYAUTO2 = '출고관리';
const FILENAME_VITASHOP = 'VITASHOP';

const FILENAME_B2B = /(^([0-9]+\.))+(.*)((\.(xls|xlsx))$)/i;

// const FROM_B2B_NAME: any = {
// 	FBY: "Freshbuy",
// 	MRG: "미리나글로벌",
// 	HBC: "허브캐나다",
// 	APP: "APPlus",
// 	LND: "런던",
// 	NNP: "나인팟",
// 	PSB: "폰손비",
// 	ORG: "마이오가니캐토",
// 	KWM: "키위몬",
// 	BLB: "블루박스",
// 	VIS: "비타스토어",
// 	EDN: "에덴",
// 	ETO: "이티오글로벌",
// 	NUG: "뉴트리글로벌",
// 	ERV: "어스빌",
// 	BOH: "보옥헬스",
// 	LKC: "엘케이컴퍼니",
// };

export const OrderPage = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tbody, setTbody] = useState([]);
	const [files, setFiles] = useState([]);
	const [checkPCC, setCheckPCC] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { t } = useTranslation();

	const columnDef: TableColumnDef[] = [
		{
			name: t('From'),
			datafield: 'from',
			align: 'center',
			headerAlign: 'center',
		},
		{
			name: t('Name'),
			datafield: 'name',
			customValue: (row: any) => {
				if (!(row.buyerName && row.recieverName)) return '';
				if (row.buyerName === row.recieverName) return `${row.buyerName}`;
				return `${row.buyerName}-${row.recieverName}`;
			},
			align: 'center',
			headerAlign: 'center',
			style: () => {
				return { maxWidth: 100, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
			},
		},
		{
			name: t('Address'),
			datafield: 'addr',
			style: () => {
				return { maxWidth: 100, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
			},
		},
		{
			name: t('PCC'),
			datafield: 'PCC',
			style: () => {
				return { maxWidth: 100, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
			},
		},
		{
			name: t('Product'),
			datafield: 'productName',
			style: () => {
				return { maxWidth: 400, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
			},
		},
		{
			name: t('Option'),
			datafield: 'option',
		},
		{
			name: t('Qty'),
			datafield: 'qty',
		},
	];

	let resultSheet: any[] = [];
	let promiseFiles: any[] = [];
	let customer_by_del: any = {};

	const onCompleted = (data: saveOrdersMutation) => {
		const {
			saveOrders: { success },
		} = data;

		if (success) {
			enqueueSnackbar(t(`Orders are saved!`), { variant: 'success', autoHideDuration: 3000 });
			history.push('/orders/manage');
		} else {
			enqueueSnackbar(t(`Cannot save orders...😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const variables: saveOrdersMutationVariables = {
		orderInput: tbody,
		checkPCCInput: checkPCC,
	};

	const { data, loading: customerLoading } = useQuery(CUSTOMERS_INFO_QUERY);
	const [saveOrdersMutation, { loading }] = useMutation<saveOrdersMutation, saveOrdersMutationVariables>(SAVEORDERS_MUTATION, {
		onCompleted,
		variables,
	});

	if (!customerLoading) {
		const {
			customersInfo: { customers },
		} = data;

		customers.forEach((customer: any) => {
			customer_by_del[customer.delimiter] = customer.name;
		});
	}

	const excelDateToJSDate = (excelDate: number) => {
		const daysBeforeUnixEpoch = 70 * 365 + 19;
		const hour = 60 * 60 * 1000;
		const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

		const date = new Date(Math.round((excelDate - daysBeforeUnixEpoch) * 24 * hour) + tzOffset);

		return `${date.getFullYear()}-${leadingZeros(date.getMonth() + 1, 2)}-${leadingZeros(date.getDate(), 2)} ${leadingZeros(date.getHours(), 2)}:${leadingZeros(date.getMinutes(), 2)}:${leadingZeros(
			date.getSeconds(),
			2
		)}`;
	};

	function leadingZeros(n: any, digits: number) {
		var zero = '';
		n = n.toString();

		if (n.length < digits) {
			for (let i = 0; i < digits - n.length; i++) zero += '0';
		}
		return zero + n;
	}

	const onDrop = useCallback(
		(acceptedFiles, _, event) => {
			setIsLoading(true);
			acceptedFiles.forEach((file: any) => {
				if (!checkValidFileName(file)) {
					enqueueSnackbar(`${t('Fail to load file')}(${file.name})`, { variant: 'error', autoHideDuration: 3000 });
					return;
				}
				const promise = new Promise<void>((resolve, reject) => {
					const reader = new FileReader();
					const fileName = file.name.toUpperCase();
					reader.onabort = () => console.log('file reading was aborted');
					reader.onerror = () => reject();
					reader.onloadend = () => resolve();
					reader.onload = () => {
						const bstr = reader.result;
						const wb = XLSX.read(bstr, { type: 'binary' });

						let results: OrderInputType[] = [];
						//Naver
						if (fileName.indexOf(FILENAME_NAVER) >= 0) {
							const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { range: 1 });
							let previousOrderNo: any;

							json.forEach((data) => {
								const row: any = data;
								//console.log(row['수취인명'], row['결제일'], excelDateToJSDate(row['결제일']));
								if (previousOrderNo !== row['주문번호']) {
									results.push({
										sortOrder: 1,
										category: OrderCategory.OpenMarket,
										from: 'NAVER',
										website: 'shopping.naver.com',
										referenceNo: row['주문번호'] + '',
										recieverPhone: row['수취인연락처1'] + '',
										buyerPhone: row['구매자연락처'] + '',
										recieverName: row['수취인명'],
										buyerName: row['구매자명'],
										postal: row['우편번호'].replace(/-/gi, ''),
										addr: row['기본배송지'],
										addrDetail: row['상세배송지'],
										PCC: row['개인통관고유부호'],
										paidAt: excelDateToJSDate(row['결제일']),
										orderAmount: +row['상품별 총 주문금액'] || +row['상품별 총 주문금액'].replace(/,/gi, ''),
										netIncome: +row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''),
										currency: 'KRW',
										orderProducts: [
											{
												productName: row['상품명'],
												alias: row['상품명'],
												option: row['옵션정보'],
												qty: +row['수량'],
												barcode: row['옵션관리코드'] ? row['옵션관리코드'] : row['판매자 상품코드'],
												unitPrice: +row['상품별 총 주문금액'] / +row['수량'],
											},
										],
										msg: row['배송메세지'] ? row['배송메세지'] + '' : '',
									});
								} else if (results.length > 0) {
									//console.log(results[results.length - 1].orderAmount, +row['상품별 총 주문금액'] || +row['상품별 총 주문금액'].replace(/,/gi, ''));
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (+row['상품별 총 주문금액'] || +row['상품별 총 주문금액'].replace(/,/gi, ''));
									results[results.length - 1].netIncome = (results[results.length - 1].netIncome || 0) + (+row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''));
									results[results.length - 1].orderProducts.push({
										productName: row['상품명'],
										alias: row['상품명'],
										option: row['옵션정보'],
										qty: +row['수량'],
										barcode: row['옵션관리코드'] ? row['옵션관리코드'] : row['판매자 상품코드'],
										unitPrice: +row['상품별 총 주문금액'] / +row['수량'],
									});
								}

								previousOrderNo = row['주문번호'];
							});

							//console.log('NAVER', results);
						}

						//11st
						else if (fileName.indexOf(FILENAME_11ST) >= 0) {
							const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { range: 1 });
							let previousOrderNo: string;

							json.forEach((data) => {
								const row: any = data;
								if (previousOrderNo !== row['주문번호']) {
									results.push({
										sortOrder: 2,
										category: OrderCategory.OpenMarket,
										from: '11st',
										website: 'www.11st.co.kr',
										referenceNo: row['주문번호'] + '',
										recieverPhone: row['전화번호'] + '',
										buyerPhone: row['휴대폰번호'] + '',
										recieverName: row['수취인'],
										buyerName: row['구매자'],
										postal: row['우편번호'].replace(/-/gi, ''),
										addr: row['주소'],
										addrDetail: '',
										PCC: row['세관신고정보'],
										paidAt: row['결제일시'],
										orderAmount: +row['주문금액'] || +row['주문금액'].replace(/,/gi, ''),
										netIncome: +row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''),
										currency: 'KRW',
										orderProducts: [
											{
												productName: row['상품명'],
												alias: row['상품명'],
												option: row['옵션'],
												qty: +row['수량'],
												barcode: row['판매자 상품코드'],
												unitPrice: +row['주문금액'] / +row['수량'],
											},
										],
										msg: row['배송메시지'] ? row['배송메시지'] + '' : '',
									});
								} else if (results.length > 0) {
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (+row['주문금액'] || +row['주문금액'].replace(/,/gi, ''));
									results[results.length - 1].netIncome = (results[results.length - 1].netIncome || 0) + (+row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''));
									results[results.length - 1].orderProducts.push({
										productName: row['상품명'],
										alias: row['상품명'],
										option: row['옵션'],
										qty: +row['수량'],
										barcode: row['판매자 상품코드'],
										unitPrice: +row['주문금액'] / +row['수량'],
									});
								}

								previousOrderNo = row['주문번호'];
							});

							//console.log('11ST', results);
						}

						//Coupang
						else if (fileName.indexOf(FILENAME_COUPANG) >= 0) {
							const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
							let previousOrderNo: string;

							json.forEach((data) => {
								const row: any = data;
								//console.log(row['Recipient name'], row['Order date']);
								if (previousOrderNo !== row['Order number']) {
									results.push({
										sortOrder: 3,
										category: OrderCategory.OpenMarket,
										from: 'Coupang',
										website: 'www.coupang.com',
										referenceNo: row['Order number'] + '',
										buyerPhone: row['Buyer phone number'] + '',
										recieverPhone: row['Contact information of buyer for customs clearance purpose'] + '',
										recieverName: row['Recipient name'],
										buyerName: row['Buyer'],
										postal: row['Zipcode'].replace(/-/gi, ''),
										addr: row['Recipient address'],
										addrDetail: '',
										PCC: row['Personal Customs Clearance Code (PCCC)'],
										paidAt: row['Order date'],
										orderAmount: +row['paid amount'] || +row['paid amount'].replace(/,/gi, ''),
										netIncome: +row['paid amount'] * 0.87 || +row['paid amount'].replace(/,/gi, '') * 0.87,
										currency: 'KRW',
										orderProducts: [
											{
												productName: row['Registered product name'],
												alias: row['Registered product name'],
												option: row['Registered option name'],
												qty: +row['Purchased qty'],
												barcode: row['Vendor product code'],
												unitPrice: +row['paid amount'] / +row['Purchased qty'],
											},
										],
										msg: row['Delivery message'] ? row['Delivery message'] + '' : '',
									});
								} else if (results.length > 0) {
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (+row['paid amount'] || +row['paid amount'].replace(/,/gi, ''));
									results[results.length - 1].netIncome = (results[results.length - 1].netIncome || 0) + (+row['paid amount'] * 0.87 || +row['paid amount'].replace(/,/gi, '') * 0.87);
									results[results.length - 1].orderProducts.push({
										productName: row['Registered product name'],
										alias: row['Registered product name'],
										option: row['Registered option name'],
										qty: +row['Purchased qty'],
										barcode: row['Vendor product code'],
										unitPrice: +row['paid amount'] / +row['Purchased qty'],
									});
								}
								previousOrderNo = row['Order number'];
							});

							//console.log('Coupang', results);
						}

						//WeMakePrice
						else if (fileName.indexOf(FILENAME_WEMAKEPRICE) >= 0) {
							const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
							let previousOrderNo: string;

							json.forEach((data) => {
								const row: any = data;
								if (previousOrderNo !== row['주문번호']) {
									results.push({
										sortOrder: 4,
										category: OrderCategory.OpenMarket,
										from: 'WeMakePrice',
										website: 'front.wemakeprice.com',
										referenceNo: row['주문번호'] + '',
										recieverPhone: row['받는사람 연락처'] + '',
										buyerPhone: row['구매자 휴대폰'] + '',
										recieverName: row['받는사람'],
										buyerName: row['구매자'],
										postal: row['우편번호'].replace(/-/gi, ''),
										addr: row['주소'],
										addrDetail: '',
										PCC: row['개인통관고유번호'],
										paidAt: row['결제일'],
										orderAmount: +row['총상품금액'] || +row['총상품금액'].replace(/,/gi, ''),
										netIncome: +row['총상품금액'] - row['판매대행수수료'] || +row['총상품금액'].replace(/,/gi, '') - row['판매대행수수료'].replace(/,/gi, ''),
										currency: 'KRW',
										orderProducts: [
											{
												productName: row['상품명'],
												alias: row['상품명'],
												option: row['옵션'],
												qty: +row['수량'],
												barcode: row['업체옵션코드'] ? row['업체옵션코드'] : row['업체상품코드'],
												unitPrice: +row['총상품금액'] / +row['수량'],
											},
										],
										msg: row['배송메세지'] ? row['배송메세지'] + '' : '',
									});
								} else if (results.length > 0) {
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (+row['총상품금액'] || +row['총상품금액'].replace(/,/gi, ''));
									results[results.length - 1].netIncome =
										(results[results.length - 1].netIncome || 0) + (+row['총상품금액'] - row['판매대행수수료'] || +row['총상품금액'].replace(/,/gi, '') - row['판매대행수수료'].replace(/,/gi, ''));
									results[results.length - 1].orderProducts.push({
										productName: row['상품명'],
										alias: row['상품명'],
										option: row['옵션'],
										qty: +row['수량'],
										barcode: row['업체옵션코드'] ? row['업체옵션코드'] : row['업체상품코드'],
										unitPrice: +row['총상품금액'] / +row['수량'],
									});
								}
								previousOrderNo = row['주문번호'];
							});

							//console.log('WeMakePrice', results);
						}

						//Interpark
						else if (fileName.indexOf(FILENAME_INTERPARK) >= 0 || fileName.indexOf(FILENAME_INTERPARK2) >= 0) {
							let json;
							let previousOrderNo: string;
							if (fileName.indexOf(FILENAME_INTERPARK) >= 0) {
								//filename: 주문_발송관리
								json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { range: 5 });
							} else {
								//filename: 전체주문내역
								json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { range: 4 });
							}
							//console.log(json);
							json.forEach((data) => {
								const row: any = data;
								if (previousOrderNo !== row['주문번호']) {
									results.push({
										sortOrder: 5,
										category: OrderCategory.OpenMarket,
										from: 'INTERPARK',
										website: 'interpark.com',
										referenceNo: row['주문번호'] + '',
										recieverPhone: row['수령자휴대폰번호'] + '',
										buyerPhone: row['구매자휴대폰번호'] + '',
										recieverName: row['수령자명'],
										buyerName: row['구매자명'],
										postal: row['우편번호'].replace(/-/gi, ''),
										addr: row['주소'],
										addrDetail: '',
										PCC: row['개인통관정보'],
										paidAt: row['결제일'],
										orderAmount: +row['주문금액'] || +row['주문금액'].replace(/,/gi, ''),
										netIncome: +row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''),
										currency: 'KRW',
										orderProducts: [
											{
												productName: row['상품명'],
												alias: row['상품명'],
												option: row['옵션명'],
												qty: +row['수량'],
												barcode: row['판매자옵션상품번호'] ? row['판매자옵션상품번호'] : row['판매자상품번호'],
												unitPrice: +row['주문금액'] / +row['수량'],
											},
										],
										msg: row['배송메시지'] ? row['배송메시지'] + '' : '',
									});
								} else if (results.length > 0) {
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (+row['주문금액'] || +row['주문금액'].replace(/,/gi, ''));
									results[results.length - 1].netIncome = (results[results.length - 1].netIncome || 0) + (+row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''));
									results[results.length - 1].orderProducts.push({
										productName: row['상품명'],
										alias: row['상품명'],
										option: row['옵션명'],
										qty: +row['수량'],
										barcode: row['판매자옵션상품번호'] ? row['판매자옵션상품번호'] : row['판매자상품번호'],
										unitPrice: +row['주문금액'] / +row['수량'],
									});
								}
								previousOrderNo = row['주문번호'];
							});

							//console.log('INTERPARK', results);
						}

						//Auction/GMarket
						else if (fileName.indexOf(FILENAME_GMARKET) >= 0) {
							const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
							let previousOrderNo: string;

							json.forEach((data) => {
								const row: any = data;
								if (previousOrderNo !== row['장바구니번호(결제번호)']) {
									results.push({
										sortOrder: 6,
										category: OrderCategory.OpenMarket,
										from: row['아이디'].indexOf('옥션') >= 0 ? 'AUCTION' : 'GMARKET',
										website: row['아이디'].indexOf('옥션') >= 0 ? 'www.auction.co.kr' : 'www.gmarket.co.kr',
										referenceNo: row['주문번호'] + '',
										buyerPhone: row['구매자 휴대폰'] ? row['구매자 휴대폰'] + '' : '',
										recieverPhone: row['수령인 휴대폰'] ? row['수령인 휴대폰'] + '' : '',
										//name: row["구매자명"] === row["수령인명"] ? row["구매자명"] : `${row["구매자명"]}-${row["수령인명"]}`,
										recieverName: row['수령인명'],
										buyerName: row['구매자명'],
										postal: row['우편번호'].replace(/-/gi, ''),
										addr: row['주소'],
										addrDetail: '',
										PCC: row['수령인 통관정보'],
										paidAt: row['결제완료일'],
										orderAmount: +row['판매금액'] || +row['판매금액'].replace(/,/gi, ''),
										netIncome: +row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''),
										currency: 'KRW',
										orderProducts: [
											{
												productName: row['상품명'],
												alias: row['상품명'],
												option: '',
												qty: +row['수량'],
												barcode: row['판매자 관리코드'],
												unitPrice: +row['판매금액'] / +row['수량'],
											},
										],
										msg: row['배송시 요구사항'] ? row['배송시 요구사항'] + '' : '',
									});
								} else if (results.length > 0) {
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (+row['판매금액'] || +row['판매금액'].replace(/,/gi, ''));
									results[results.length - 1].netIncome = (results[results.length - 1].netIncome || 0) + (+row['정산예정금액'] || +row['정산예정금액'].replace(/,/gi, ''));
									results[results.length - 1].orderProducts.push({
										productName: row['상품명'],
										alias: row['상품명'],
										option: '',
										qty: +row['수량'],
										barcode: row['판매자 관리코드'],
										unitPrice: +row['판매금액'] / +row['수량'],
									});
								}
								previousOrderNo = row['장바구니번호(결제번호)'];
							});

							//console.log('AUCTION/GMARKET', results);
						}

						//PlayAuto
						else if (fileName.indexOf(FILENAME_PLAYAUTO) >= 0 || fileName.indexOf(FILENAME_PLAYAUTO2) >= 0) {
							const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
							let previousOrderNo: string;
							const MARKET: any = {
								캐나다비타민즈: {
									name: 'NAVER',
									website: 'shopping.naver.com',
									sortOrder: 1,
									currency: 'KRW',
									category: OrderCategory.OpenMarket,
								},
								이노코리아: {
									name: 'Inno Korea',
									website: 'shopping.naver.com',
									sortOrder: 8,
									currency: 'CAD',
									category: OrderCategory.B2B,
								},
								'11번가': {
									name: '11st',
									website: 'www.11st.co.kr',
									sortOrder: 2,
									currency: 'KRW',
									category: OrderCategory.OpenMarket,
								},
								쿠팡: {
									name: 'Coupang',
									website: 'www.coupang.com',
									sortOrder: 3,
									currency: 'KRW',
									category: OrderCategory.OpenMarket,
								},
								인터파크: {
									name: 'INTERPARK',
									website: 'www.gmarket.co.kr',
									sortOrder: 4,
									currency: 'KRW',
									category: OrderCategory.OpenMarket,
								},
								옥션: {
									name: 'AUCTION',
									website: 'www.auction.co.kr',
									sortOrder: 5,
									currency: 'KRW',
									category: OrderCategory.OpenMarket,
								},
								지마켓: {
									name: 'GMARKET',
									website: 'www.gmarket.co.kr',
									sortOrder: 6,
									currency: 'KRW',
									category: OrderCategory.OpenMarket,
								},
								'위메프2.0': {
									name: 'WeMakePrice',
									website: 'front.wemakeprice.com',
									sortOrder: 7,
									currency: 'KRW',
									category: OrderCategory.OpenMarket,
								},
								롯데ON: {
									name: 'LotteON',
									website: 'lotteon.com',
									sortOrder: 8,
									currency: 'CAD',
									category: OrderCategory.B2B,
								},
								'카페24(신)': {
									name: 'Cafe24',
									website: 'innonutrition.kr',
									sortOrder: 9,
									currency: 'CAD',
									category: OrderCategory.B2B,
								},
								'SSG(통합)': {
									name: 'SSG.COM',
									website: 'ssg.com',
									sortOrder: 10,
									currency: 'CAD',
									category: OrderCategory.B2B,
								},
							};

							json.forEach((data) => {
								const row: any = data;
								let store = row['쇼핑몰'] === '스마트스토어' ? row['별칭'] : row['쇼핑몰'];

								if (previousOrderNo !== row['묶음번호']) {
									results.push({
										sortOrder: MARKET[store].sortOrder,
										category: MARKET[store].category,
										from: MARKET[store].name,
										website: MARKET[store].website,
										referenceNo: row['묶음번호'] + '',
										buyerPhone: row['주문자 휴대폰번호'] ? row['주문자 휴대폰번호'] + '' : row['주문자휴대폰번호'] ? row['주문자휴대폰번호'] + '' : '',
										buyerName: row['주문자명'] || row['주문자(ID)'].split('/')[0],
										recieverPhone: row['수령자 휴대폰번호'] ? row['수령자 휴대폰번호'] + '' : row['수령자휴대폰번호'] ? row['수령자휴대폰번호'] + '' : '',
										recieverName: row['수령자명'],
										postal: row['우편번호'].replace(/-/gi, ''),
										addr: row['주소'],
										addrDetail: '',
										PCC: row['개인통관번호'],
										paidAt: MARKET[store].name === 'INTERPARK' ? row['주문일'] : row['결제완료일'],
										orderAmount: MARKET[store].category === OrderCategory.B2B ? 0 : +row['금액'],
										netIncome: +row['공급가'] !== 0 ? +row['공급가'] : +row['금액'] * 0.87,
										currency: MARKET[store].currency,
										orderProducts: [
											{
												productName: row['온라인 상품명'] || row['온라인상품명'],
												alias: row['온라인 상품명'] || row['온라인상품명'],
												option: row['옵션'],
												qty: +row['주문수량'],
												barcode: row['SKU코드'] ? row['SKU코드'] : row['판매자관리코드'],
												unitPrice: MARKET[store].category === OrderCategory.B2B ? 0 : +row['금액'] / +row['주문수량'],
											},
										],
										msg: row['배송메세지'] ? row['배송메세지'] + '' : '',
										shippingFee: MARKET[store].category === OrderCategory.B2B ? 9:0
									});
								} else if (results.length > 0) {
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (MARKET[store].category === OrderCategory.B2B ? 0 : +row['금액']);
									results[results.length - 1].netIncome = (results[results.length - 1].netIncome || 0) + (+row['공급가'] !== 0 ? +row['공급가'] : +row['금액'] * 0.87);
									results[results.length - 1].orderProducts.push({
										productName: row['온라인 상품명'] || row['온라인상품명'],
										alias: row['온라인 상품명'] || row['온라인상품명'],
										option: row['옵션'],
										qty: +row['주문수량'],
										barcode: row['SKU코드'] ? row['SKU코드'] : row['판매자관리코드'],
										unitPrice: MARKET[store].category === OrderCategory.B2B ? 0 : +row['금액'] / +row['주문수량'],
									});
								}
								previousOrderNo = row['묶음번호'];
							});

							//console.log('PlayAuto', results);
						}
						//Vitashop
						else if (fileName.indexOf(FILENAME_VITASHOP) >= 0) {
							const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
							let previousOrderNo: string;

							json.forEach((data) => {
								const row: any = data;
								if (previousOrderNo !== row['id_order']) {
									results.push({
										sortOrder: 1,
										category: OrderCategory.B2B,
										from: 'Vita Shop',
										website: 'vitashop.ca',
										referenceNo: row['id_order']+'',
										buyerPhone: row['orderer_phone'] !== '' ? phoneFomatter(row['orderer_phone'] + '',1) : (row['orderer_phone_mobile'] !== '' ? phoneFomatter(row['orderer_phone_mobile'] + '',1) : ''),
										buyerName: filterKoreanName(row['orderer']),
										recieverPhone: row['recipient_phone'] !== '' ? phoneFomatter(row['recipient_phone'] + '',1) : (row['recipient_phone_mobile'] !== '' ? phoneFomatter(row['recipient_phone_mobile'] + '',1) : ''),
										recieverName: filterKoreanName(row['recipient']),
										postal: (row['postcode']+'').replace(/-/gi, ''),
										addr: row['address1']+'',
										addrDetail: row['address2'] ? row['address2']+'' : '',
										PCC: row['clearence code']+'',
										paidAt: excelDateToJSDate(row['date_add']),
										orderAmount: row['unit_price'],
										netIncome: row['product_cost'],
										currency: row['currency'],
										orderProducts: [
											{
												productName: row['product_name'],
												alias: row['product_name'],
												option: '',
												qty: +row['product_quantity'],
												barcode: row['upc']+'',
												unitPrice: +row['unit_price'],
											},
										],
										msg: row['memo']||'',
										shippingFee: row['shipping']
									});
								} else if (results.length > 0) {
									results[results.length - 1].orderAmount = (results[results.length - 1].orderAmount || 0) + (+row['unit_price']);
									results[results.length - 1].netIncome = (results[results.length - 1].netIncome || 0) + (+row['product_cost']);
									results[results.length - 1].orderProducts.push({
										productName: row['product_name'],
										alias: row['product_name'],
										option: '',
										qty: +row['product_quantity'],
										barcode: row['upc']+'',
										unitPrice: +row['unit_price'],
									});
								}
								previousOrderNo = row['id_order'];
							});

							console.log('Vitashop', results);
						}

						//B2B
						else if (fileName.match(FILENAME_B2B)) {
							const sortOrder = parseInt((/^[0-9]+/.exec(fileName) || ['0'])[0]);
							let delimiter = (/(_[A-Z]+_)/.exec(fileName) || ['__'])[0];
							delimiter = trimString(delimiter);

							let sheet = wb.Sheets[wb.SheetNames[1]];
							let isFirstRow = false;
							let start_rownum = 1;
							let isRowExsists = true;
							if (delimiter === 'HBC') {
								delete_cols(sheet, 4);
							}
							//find first row to read data
							while (!isFirstRow && start_rownum < 999) {
								const first = sheet[`G${start_rownum}`]?.v;
								if (first === '주문자') {
									isFirstRow = true;
									start_rownum += 1;
									break;
								}
								start_rownum++;
							}
							//console.log("start_rownum", start_rownum);
							while (isRowExsists) {
								//read buyer cell
								const buyer = sheet[`G${start_rownum}`]?.v || false;
								if (!buyer) {
									isRowExsists = false;
									return;
								}
								const rowData = {
									sortOrder,
									category: OrderCategory.B2B,
									delimiter: delimiter,
									from: customer_by_del[delimiter],
									recieverPhone: sheet[`J${start_rownum}`]?.v ? sheet[`J${start_rownum}`]?.v + '' : '',
									buyerPhone: sheet[`I${start_rownum}`]?.v ? sheet[`I${start_rownum}`]?.v + '' : '',
									buyerName: sheet[`G${start_rownum}`].v || '',
									recieverName: sheet[`H${start_rownum}`].v || '',
									postal: ('' + sheet[`K${start_rownum}`].v).replace(/-/gi, '') || '',
									addr: sheet[`L${start_rownum}`]?.v ? sheet[`L${start_rownum}`]?.v + '' : '',
									addrDetail: sheet[`M${start_rownum}`]?.v ? sheet[`M${start_rownum}`]?.v + '' : '',
									PCC: sheet[`N${start_rownum}`]?.v || '',
									msg: sheet[`O${start_rownum}`]?.v ? sheet[`O${start_rownum}`]?.v + '' : '',
									shippingFee: +sheet[`C${start_rownum}`]?.v,
									//paidAt: row['결제완료일'],
									orderAmount: +sheet[`D${start_rownum}`]?.v,
									netIncome: +sheet[`D${start_rownum}`]?.v,
									currency: 'CAD',
									orderProducts: [
										{
											productName: sheet[`P${start_rownum}`]?.v || '',
											alias: sheet[`P${start_rownum}`]?.v || '',
											brand: sheet[`AF${start_rownum}`]?.v || '',
											barcode: sheet[`AK${start_rownum}`]?.v || '',
											reportableUnitPrice: sheet[`AG${start_rownum}`]?.v || '',
											unitPrice: sheet[`AJ${start_rownum}`]?.v / sheet[`AH${start_rownum}`]?.v || 0,
											qty: +sheet[`AH${start_rownum}`]?.v,
										},
										{
											productName: sheet[`R${start_rownum}`]?.v || '',
											alias: sheet[`R${start_rownum}`]?.v || '',
											brand: sheet[`AN${start_rownum}`]?.v || '',
											barcode: sheet[`AS${start_rownum}`]?.v || '',
											reportableUnitPrice: sheet[`AO${start_rownum}`]?.v || '',
											unitPrice: sheet[`AR${start_rownum}`]?.v / sheet[`AP${start_rownum}`]?.v || 0,
											qty: +sheet[`AP${start_rownum}`]?.v || '',
										},
										{
											productName: sheet[`T${start_rownum}`]?.v || '',
											alias: sheet[`T${start_rownum}`]?.v || '',
											brand: sheet[`AV${start_rownum}`]?.v || '',
											barcode: sheet[`BA${start_rownum}`]?.v || '',
											reportableUnitPrice: sheet[`AW${start_rownum}`]?.v || '',
											unitPrice: sheet[`AZ${start_rownum}`]?.v / sheet[`AX${start_rownum}`]?.v || 0,
											qty: +sheet[`AX${start_rownum}`]?.v,
										},
										{
											productName: sheet[`V${start_rownum}`]?.v || '',
											alias: sheet[`V${start_rownum}`]?.v || '',
											brand: sheet[`BD${start_rownum}`]?.v || '',
											barcode: sheet[`BI${start_rownum}`]?.v || '',
											reportableUnitPrice: sheet[`BE${start_rownum}`]?.v || '',
											unitPrice: sheet[`BH${start_rownum}`]?.v / sheet[`BF${start_rownum}`]?.v || 0,
											qty: +sheet[`BF${start_rownum}`]?.v,
										},
										{
											productName: sheet[`X${start_rownum}`]?.v || '',
											alias: sheet[`X${start_rownum}`]?.v || '',
											brand: sheet[`BL${start_rownum}`]?.v || '',
											barcode: sheet[`BQ${start_rownum}`]?.v || '',
											reportableUnitPrice: sheet[`BM${start_rownum}`]?.v || '',
											unitPrice: sheet[`BP${start_rownum}`]?.v / sheet[`BN${start_rownum}`]?.v || 0,
											qty: +sheet[`BN${start_rownum}`]?.v,
										},
										{
											productName: sheet[`Z${start_rownum}`]?.v || '',
											alias: sheet[`Z${start_rownum}`]?.v || '',
											brand: sheet[`BT${start_rownum}`]?.v || '',
											barcode: sheet[`BY${start_rownum}`]?.v || '',
											reportableUnitPrice: sheet[`BU${start_rownum}`]?.v || '',
											unitPrice: sheet[`BX${start_rownum}`]?.v / sheet[`BV${start_rownum}`]?.v || 0,
											qty: +sheet[`BV${start_rownum}`]?.v,
										},
									],
								};
								for (let i = rowData.orderProducts.length - 1; i >= 0; i--) {
									if (rowData.orderProducts[i].productName === '') rowData.orderProducts.pop();
								}
								start_rownum++;
								resultSheet.push(rowData);
							}
						}

						if (results) {
							results.forEach((rs) => {
								resultSheet.push(rs);
							});
						}
					};

					reader.readAsBinaryString(file);
				});
				promiseFiles.push(promise);
			});
			Promise.all(promiseFiles).then(() => {
				if (resultSheet.length !== 0) {
					const tempSheet = tbody.concat(...resultSheet);
					const sortedSheet = tempSheet.sort((a: any, b: any) => {
						return a.sortOrder - b.sortOrder;
					});
					//console.log(sortedSheet);
					setTbody(sortedSheet);
					enqueueSnackbar(t(`Load complete!`), { variant: 'success', autoHideDuration: 3000 });
				}
				setFiles(files.concat(...acceptedFiles));
				setIsLoading(false);
			});
		},
		[files, customer_by_del]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

	const trimString = (str: string) => {
		let trimStr = str;
		//remove first and last _ character
		trimStr = str.substring(1, str.length - 1);
		return trimStr;
	};

	const checkValidFileName = (file: any) => {
		if (!file.name) return false;
		const fileName = file.name.toUpperCase();
		if (
			fileName.indexOf(FILENAME_NAVER) < 0 &&
			fileName.indexOf(FILENAME_11ST) < 0 &&
			fileName.indexOf(FILENAME_COUPANG) < 0 &&
			fileName.indexOf(FILENAME_WEMAKEPRICE) < 0 &&
			fileName.indexOf(FILENAME_INTERPARK) < 0 &&
			fileName.indexOf(FILENAME_INTERPARK2) < 0 &&
			fileName.indexOf(FILENAME_GMARKET) < 0 &&
			fileName.indexOf(FILENAME_PLAYAUTO) < 0 &&
			fileName.indexOf(FILENAME_PLAYAUTO2) < 0 &&
			fileName.indexOf(FILENAME_VITASHOP) < 0 &&
			!fileName.match(FILENAME_B2B)
		) {
			return false;
		}

		return true;
	};

	const fileList = files.map((file: any) => {
		let isError = false;
		if (!checkValidFileName(file)) {
			isError = true;
		}
		return <Chip key={file.path} color={isError ? 'error' : file.name.match(FILENAME_B2B) ? 'secondary' : 'primary'} label={file.path} size='small' sx={{ margin: 0.5 }} />;
	});

	const _onClear = () => {
		setFiles([]);
		setTbody([]);
	};

	const _onPrint = () => {
		if (tbody.length === 0) {
			enqueueSnackbar(t(`There are no data!`), { variant: 'error', autoHideDuration: 3000 });
			return;
		}
		//const styles = document.querySelectorAll("style");
		const table: any = document.querySelector('table');

		const newWin = window.open('', '_blank');

		// styles.forEach((style) => {
		// 	newWin?.document.write(style.outerHTML);
		// });
		newWin?.document.write(`<style>
		table{
			width:100%;
		}
		td{
			padding: 5px !important;
			font-size: 11px !important;
			border-top: 1px solid rgba(193, 193, 194, 0.3);
		}
		td:nth-child(3), td:nth-child(4), td:nth-child(5){
			max-width: 100;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		td:nth-child(2), td:nth-child(3), td:nth-child(5), td:nth-child(8){
			text-align:center;
		}
		</style>`);
		newWin?.document.write(table.outerHTML);

		newWin?.focus();
		newWin?.print();
		newWin?.close();
	};

	const _onSave = () => {
		//console.log(tbody);
		if (!isLoading && tbody.length !== 0) saveOrdersMutation();
	};

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>Bulk Order | PMSK</title>
			</Helmet>
			<Container maxWidth='xl' disableGutters>
				<Box>
					<Box
						component='div'
						{...getRootProps({ className: 'dropzone' })}
						sx={{
							textAlign: 'center',
							height: '150px',
							lineHeight: '150px',
							outline: '2px dashed #eeeeee',
							color: '#bdbdbd',
							backgroundColor: '#fafafa',
							marginTop: 2,
						}}>
						<input {...getInputProps()} />
						<p>{t('Put the order excel file here')}</p>
					</Box>
				</Box>
				{isLoading ? (
					<div>
						<Box component='div' sx={{ marginTop: '22px' }}>
							<Skeleton variant='rectangular' height={24} width={48} />
							<Typography component='span' variant='h5'>
								<Skeleton variant='text' />
							</Typography>
							<Box sx={{ width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>span': { margin: '0 2px' } }}>
								<Skeleton component='span' variant='text' height={52} width={90} />
								<Skeleton component='span' variant='text' height={52} width={90} />
								<Skeleton component='span' variant='text' height={52} width={90} />
							</Box>
						</Box>
						<Skeleton variant='rectangular' height={40} />
						<Typography component='span' variant='h4'>
							<Skeleton variant='text' />
						</Typography>
						<Typography component='span' variant='h4'>
							<Skeleton variant='text' />
						</Typography>
						<Typography component='span' variant='h4'>
							<Skeleton variant='text' />
						</Typography>
						<Typography component='span' variant='h4'>
							<Skeleton variant='text' />
						</Typography>
					</div>
				) : (
					<Box component='div' sx={{ marginTop: 1 }}>
						<FormGroup sx={{ display: 'block' }}>
							<FormControlLabel
								control={
									<Switch
										checked={checkPCC}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											setCheckPCC(event.target.checked);
										}}
									/>
								}
								label={t('Automatically PCC Check')}
							/>
						</FormGroup>
						<Typography variant='h6'>{t('Files')}</Typography>
						<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='flex-start' alignItems={{ sm: 'center' }} sx={{ flexWrap: 'wrap' }}>
							{fileList}
						</Stack>
						<Box component='div' sx={{ marginTop: 1, width: '100%', textAlign: 'right', display: 'flex', flexDirection: 'row-reverse', '&>button': { margin: '0 2px' } }}>
							<LoadingButton onClick={_onSave} loading={loading} size='small' loadingPosition='start' startIcon={<SaveAltIcon fontSize='small' />} variant='contained'>
								{t('Save')}
							</LoadingButton>
							<LoadingButton onClick={_onClear} loading={loading} size='small' loadingPosition='start' startIcon={<CleaningServicesIcon fontSize='small' />}>
								{t('Clear')}
							</LoadingButton>
							<LoadingButton onClick={_onPrint} loading={loading} size='small' loadingPosition='start' startIcon={<PrintIcon fontSize='small' />}>
								{t('Print')}
							</LoadingButton>
						</Box>
						<CustomOrderTable TableData={tbody} TableColumn={columnDef} rownum showTotal flattened loading={loading} />
					</Box>
				)}
			</Container>
		</Paper>
	);
};
