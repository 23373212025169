import * as XLSX from 'xlsx';

function clamp_range(range: any) {
	if (range.e.r >= 1 << 20) range.e.r = (1 << 20) - 1;
	if (range.e.c >= 1 << 14) range.e.c = (1 << 14) - 1;
	return range;
}

var crefregex = /(^|[^._A-Z0-9])([$]?)([A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D])([$]?)([1-9]\d{0,5}|10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6])(?![_.(A-Za-z0-9])/g;

/*
deletes `ncols` cols STARTING WITH `start_col`
usage: delete_cols(ws, 4, 3); // deletes columns E-G and shifts everything after G to the left by 3 columns
*/
export function delete_cols(ws: XLSX.WorkSheet, start_col = 0, ncols = 1) {
	if (!ws) throw new Error('operation expects a worksheet');

	/* extract original range */
	var range = XLSX.utils.decode_range(ws['!ref'] || '');
	var R = 0,
		C = 0;

	var formula_cb = function ($0: any, $1: any, $2: any, $3: any, $4: any, $5: any) {
		var _R = XLSX.utils.decode_row($5),
			_C = XLSX.utils.decode_col($3);
		if (_C >= start_col) {
			_C -= ncols;
			if (_C < start_col) return '#REF!';
		}
		return $1 + ($2 === '$' ? $2 + $3 : XLSX.utils.encode_col(_C)) + ($4 === '$' ? $4 + $5 : XLSX.utils.encode_row(_R));
	};

	var addr, naddr;
	for (C = start_col + ncols; C <= range.e.c; ++C) {
		for (R = range.s.r; R <= range.e.r; ++R) {
			addr = XLSX.utils.encode_cell({ r: R, c: C });
			naddr = XLSX.utils.encode_cell({ r: R, c: C - ncols });
			if (!ws[addr]) {
				delete ws[naddr];
				continue;
			}
			if (ws[addr].f) ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
			ws[naddr] = ws[addr];
		}
	}
	for (C = range.e.c; C > range.e.c - ncols; --C) {
		for (R = range.s.r; R <= range.e.r; ++R) {
			addr = XLSX.utils.encode_cell({ r: R, c: C });
			delete ws[addr];
		}
	}
	for (C = 0; C < start_col; ++C) {
		for (R = range.s.r; R <= range.e.r; ++R) {
			addr = XLSX.utils.encode_cell({ r: R, c: C });
			if (ws[addr] && ws[addr].f) ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
		}
	}

	/* write new range */
	range.e.c -= ncols;
	if (range.e.c < range.s.c) range.e.c = range.s.c;
	ws['!ref'] = XLSX.utils.encode_range(clamp_range(range));

	/* merge cells */
	if (ws['!merges'])
		ws['!merges'].forEach(function (merge, idx) {
			var mergerange;
			switch (typeof merge) {
				case 'string':
					mergerange = XLSX.utils.decode_range(merge);
					break;
				case 'object':
					mergerange = merge;
					break;
				default:
					throw new Error('Unexpected merge ref ' + merge);
			}
			if (mergerange.s.c >= start_col) {
				mergerange.s.c = Math.max(mergerange.s.c - ncols, start_col);
				if (mergerange.e.c < start_col + ncols) {
					if (ws['!merges']) delete ws['!merges'][idx];
					return;
				}
				mergerange.e.c -= ncols;
				if (mergerange.e.c < mergerange.s.c) {
					if (ws['!merges']) delete ws['!merges'][idx];
					return;
				}
			} else if (mergerange.e.c >= start_col) mergerange.e.c = Math.max(mergerange.e.c - ncols, start_col);
			clamp_range(mergerange);
			if (ws['!merges']) ws['!merges'][idx] = mergerange;
		});
	if (ws['!merges'])
		ws['!merges'] = ws['!merges'].filter(function (x) {
			return !!x;
		});

	/* cols */
	if (ws['!cols']) ws['!cols'].splice(start_col, ncols);
}

export function yymmdd() {
	let date = new Date();
	const yy = date.getFullYear() % 100;
	const mm = padStart(date.getMonth() + 1, 2, '0');
	const dd = padStart(date.getDate(), 2, '0');

	return `${yy}${mm}${dd}`;
}

export function padStart(str: string | number, width: number, pad: string = ' ') {
	const string = str + ''; //cast to string
	return string.length >= width ? string : new Array(width - string.length + 1).join(pad) + string;
}

export function dateFormat(d: any) {
	let date = new Date(d);
	const yy = date.getFullYear();
	const mm = padStart(date.getMonth() + 1, 2, '0');
	const dd = padStart(date.getDate(), 2, '0');

	return `${yy}/${mm}/${dd}`;
}

export function filterKoreanName(name:string){
	const regex = /[ㄱ-ㅎ가-힣]+$/;
	if(regex.test(name)){ //it has korean word
		const filterName = name.match(regex);
		return filterName?filterName[0]:name;
	}
	else return name;
}

export function phoneFomatter(number:string,type :0|1){
	let formatNum = '';
  const num = number.replaceAll('-','').replaceAll('+','');
	if(num.length===11){
			if(type===0){
					formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
			}else{
					formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
			}
	}
	else if(num.length===8){
			formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
	}else{
			if(num.indexOf('02')===0){
					if(type===0){
							formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
					}else{
							formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
					}
			}
			else if(num.indexOf('82')===0){
				if(num.indexOf('821') === 0){
					if(type===0){
						formatNum = num.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '0$2-****-$4');
					}else{
						formatNum = num.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '0$2-$3-$4');
					}
				}
				else {
					if(type===0){
						formatNum = num.replace(/(\d{2})(\d{3})(\d{4})(\d{4})/, '$2-****-$4');
					}else{
						formatNum = num.replace(/(\d{2})(\d{3})(\d{4})(\d{4})/, '$2-$3-$4');
					}
				}
			}
			else{
					if(type===0){
							formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
					}else{
							formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
					}
			}
	}
	return formatNum;
}
