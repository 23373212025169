import { Paper, Grid, TextField, Typography, Card, CardContent, ButtonGroup, Button, LinearProgress, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_STOCK_BY_BARCODE_QUERY } from '../../graphql/queries';
import { UPDATE_STOCK_MUTATION } from '../../graphql/mutations';
import BarcodeScanner from '../../components/barcodeScanner';
import { SearchOffOutlined as SearchOffIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';

export const Stock = () => {
	const [barcode, setBarcode] = useState('');
	const [searched, setSearched] = useState(false);
	const [products, setProducts] = useState([]);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [scanning, setScanning] = useState(false);

	const onCompleted = (data: any) => {
		if (data) {
			const {
				getStockByBarcode: { success, products },
			} = data;
			if (success) {
				setProducts(products);
			}
		}
		setBarcode('');
		setSearched(true);
	};

	const onCompletedStockUpdate = (data: any) => {
		const {
			updateStock: { success },
		} = data;
		if (success) {
			refetch();
			enqueueSnackbar(t(`Stock updated successfully`), { variant: 'success', autoHideDuration: 3000 });
		} else {
			enqueueSnackbar(t(`Cannot update stock..😥`), { variant: 'error', autoHideDuration: 3000 });
		}
	};

	const [getStockQuery, { loading, refetch }] = useLazyQuery(GET_STOCK_BY_BARCODE_QUERY, {
		onCompleted,
		fetchPolicy: 'no-cache',
	});

	const [updateStockMutation, { loading: updateLoading }] = useMutation(UPDATE_STOCK_MUTATION);

	const handleKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && barcode !== '') {
			setSearched(false);
			getStockQuery({ variables: { getStockInput: { barcode } } });
		}
	};

	const handleBlur = () => {
		if (barcode !== '') getStockQuery({ variables: { getStockInput: { barcode } } });
	};

	const manageStock = (id: number, cal: '+' | '-') => {
		const p: any = products.map((stockInfo: any) => {
			if (stockInfo.productId === id)
				if (cal === '+') return { ...stockInfo, stock: ++stockInfo.stock };
				else return { ...stockInfo, stock: --stockInfo.stock };
			return stockInfo;
		});
		setProducts(p);
	};

	const saveStock = (stockInfo: any) => {
		updateStockMutation({
			variables: { updateStockInput: { productId: stockInfo.productId, stock: +stockInfo.stock, weight:+stockInfo.weight } },
			onCompleted: onCompletedStockUpdate,
		});
	};

	const scanBarcode = () => {
		setScanning(!scanning);
	};

	useEffect(() => {
		if (barcode || barcode !== '') {
			setSearched(false);
			getStockQuery({ variables: { getStockInput: { barcode } } });
		}
	}, [scanning]);

	return (
		<Paper elevation={0} sx={{ width: '100%', backgroundColor: 'transparent' }}>
			<Helmet>
				<title>{t('Stock Management')} | PMSK</title>
			</Helmet>
			<Grid container spacing={2} sx={{ padding: 1 }}>
				<Grid container spacing={1} sx={{ marginTop: 1 }}>
					{scanning && (
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<BarcodeScanner scanning={scanBarcode} setBarcode={setBarcode} />
						</Grid>
					)}
					<Grid item xs={12} xl={11}>
						<TextField
							label={t('Barcode...')}
							fullWidth
							value={barcode}
							onBlur={handleBlur}
							onKeyUp={handleKeyUp}
							onChange={(e) => {
								const {
									target: { value: newValue },
								} = e;
								setBarcode(newValue);
							}}></TextField>
						{loading && <LinearProgress sx={{ marginX: 1 }} />}
					</Grid>
					<Grid item xs={12} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Button fullWidth onClick={scanBarcode} variant='outlined'>
							{scanning ? 'Close' : '📷Scan'}
						</Button>
					</Grid>
				</Grid>
				{products.length === 0 && searched && (
					<Grid item xs={12}>
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
							<SearchOffIcon sx={{ width: 120, height: 120, opacity: '0.6' }} />
							<Box sx={{ mt: 1 }}>{t('No Data')}</Box>
						</Box>
					</Grid>
				)}
				{products.length !== 0 &&
					products.map((stockInfo: any) => {
						return (
							<Grid item xs={12} key={stockInfo.productId}>
								<Card>
									<CardContent>
										<Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={1}>
											<Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
												<Typography gutterBottom variant='h5' component='div'>
													{stockInfo.productName}
												</Typography>
												<Typography variant='subtitle1' color='text.secondary' component='div'>
													Ref# {stockInfo.reference}
												</Typography>
											</Grid>
											<Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
												<Grid container spacing={1}>
													<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
														<Typography variant='subtitle1' color='text.primary' component='div' sx={{ textAlign: 'center' }}>
															Physical : {stockInfo.physical_qty}
														</Typography>
														<Typography variant='subtitle1' color='error.light' component='div' sx={{ textAlign: 'center' }}>
															Reserved : {stockInfo.reserved_qty}
														</Typography>
													</Grid>
													<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<TextField
															label={t('Stock')}
															value={stockInfo.stock}
															size='small'
															fullWidth
															onChange={(e) => {
																const {
																	target: { value: newValue },
																} = e;

																const p: any = products.map((product: any) => {
																	if (product.productId === stockInfo.productId) return { ...product, stock: newValue };
																	return product;
																});
																setProducts(p);
															}}></TextField>
														<ButtonGroup variant='outlined' aria-label='outlined button group' sx={{ marginX: 1, marginY: 0.5 }}>
															<Button size='small' color='primary' onClick={() => manageStock(stockInfo.productId, '+')}>
																+
															</Button>
															<Button size='small' color='error' onClick={() => manageStock(stockInfo.productId, '-')}>
																-
															</Button>
														</ButtonGroup>
													</Grid>
													<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
													<TextField
															label={t('Weight')}
															value={stockInfo.weight}
															size='small'
															fullWidth
															onChange={(e) => {
																const {
																	target: { value: newValue },
																} = e;

																const p: any = products.map((product: any) => {
																	if (product.productId === stockInfo.productId) return { ...product, weight: newValue };
																	return product;
																});
																setProducts(p);
															}}></TextField>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
												<LoadingButton loading={updateLoading} onClick={() => saveStock(stockInfo)} variant='outlined' sx={{ height: '100%', width: '100%' }}>
													Save
												</LoadingButton>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						);
					})}
			</Grid>
		</Paper>
	);
};
